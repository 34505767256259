module.exports = {
  "or": "Ou",

  "affiliation": {
    "tooltip-uncomplete": "Confirmation en attente, cette transaction sera confirmé au début du mois",
    "title": "Affiliations",
    "threshold": "Rang",
    "thresholds": "Rangs",
    "transactions": "Transactions",
    "companies": "Sociétés affiliées",
    "click-to-copy": "Cliquez pour copier le lien d'affiliation",
    "click-to-copy-success": "Copié",
    "code": "Code d'affiliation",
    "name": "Programme",
    "date": "Date",
    "total": "Total disponible",
    "forecast-total": "Total prévisionnel",
    "valid-date": "Est active depuis",
    "valid-date-future": "Sera valide le",
    "amount": "CA mensuel à générer",
    "percentage": "Pourcentage",
    "empty-transactions": "Pas encore de transaction…",
    "empty-companies": "Pas encore de société…",
    "monthly-ca": "CA mensuel généré",
    "ca": "CA généré",
    "percentage-apply": "Pourcentage appliqué",
    "teasing-warning": "Actuellement votre société ne possède pas de programme d'affiliation.\n" +
      "Pour activer ce programme, veuillez contacter notre service commercial par e-mail ou téléphone.",
    "teasing-detail": "Notre programme d'affiliation vous permet de vous affranchir de la facturation de vos clients, mais également de percevoir une rémunération sur l'ensemble des prestations récoltées dans le cadre de ce programme.",
    "tabs": {
      "info": "Informations",
      "earn": "Mes gains",
      "my-links": "Mes invitations",
      "generate-unique-link": "Générer un nouveau lien"
    }
  },

  "affiliation-links": {
    "title": "Mes liens d'affiliations",
    "new": "Créer un lien personnalisé",
    "code": "Code d'affiliation général",
    "company": "Société",
    "used_by_company": "Utilisé par la société ?",
    "email": "Mail",
    "actions": "Actions",
    "false": "Non",
    "true": "oui",
    "modal": {
      "title": "Supprimer ce lien d'affiliation",
      "danger": "Cette action est irréversible.",
      "message": "",
      "button": "Supprimer",
      "extra": "En etes-vous sûr ?"
    }
  },

  "help": {
    "key-rename": "Renommer la clé",
    "key-expire-edit": "Editer la date d'expiration de la clé",
    "ssh-rename": "Renommer la clé",
    "ssh-rewrite": "Modifier la clé",
    "network": "En raccordant votre serveur à votre Vswitch, une carte réseau sera rattachée automatiquement.",
    "server-password": "Vous retrouverez votre identifiant SSH au niveau du panel d’administration, depuis la section « Plus d’information ».\nPour vous connecter en root, veuillez utiliser la commande \"su\" suivie de votre mot de passe.",
    "server-ssh": "Le gestion des clés s'effectue depuis le menu de votre compte.",
    "server-create": "-",
    "server-snapshots": "Bien qu'utile, un snapshot ne remplace absolument pas une sauvegarde.",
    "image-rename": "Renommer l'image.",
    "image-os-rename": "Changer le nom de l'OS.",
    "image-os-version-rename": "Renommer la version de l'OS.",
    "server-rename": "Renommer le serveur.",
    "server-rename-reverse": "Renommer le reverse.",
    "server-open-ssh": "Ouvrir un terminal SSH.",
    "server-iso-mount": "Monter un ISO.",
    "server-iso-unmount": "Démonter un ISO.",
    "server-pin-dash": "Épingler dans votre dashboard.",
    "account-ssh": "Vos clés seront visibles lors du processus de création des serveurs.",
    "account-api": "Disponible lors de la prochaine release.",
    "account-2fa": "L'authentification à double facteur permet de renforcer la sécurité de votre compte.",
    "user-pref": "Afin de personnaliser votre expérience SmallCloud, enregistrez ici vos préférences d'affichage. Vous pouvez les modifier à tout moment.",
    "licenses": "ATTENTION : Le choix de votre licence est définitif. Une fois le serveur commandé, vous ne pourrez pas changer de licence.",
    "company-user": "Le type « Comptable » permet d’accéder aux factures et de procéder aux règlements.\nLe Type « Manager » donne accès à la gestion technique des serveurs.\nLe type « Administrateur » permet d’accéder à la totalité des fonctionnalités.",
    "company-del-user": "Supprimer l'utilisateur.",
    "company-send-inv": "Renvoyer l'invitation.",
    "company-role-edit": "Modifier l'accès.",
    "company-del-invitation": "Supprimer l'invitation.",
    "company-del-role": "Supprimer l'accès.",
    "show-user-vcard": "Voir la vCard de l'utilisateur.",
    "reinit-filter": "Réinitialiser tous les filtres",
    "tickets-prio-change": "Changer la priorité",
    "tickets-header-link": "Créer un nouveau ticket pour le support.",
    "tickets-link-to-vm": "Ouvrir le serveur dans un nouvel onglet",
    "subscription-rename": "Renommer le projet.",
    "subscription-change-freq": "Changer la fréquence.",
    "subscription-see-all": "Voir toutes les factures liées à ce projet.",
    "subscription-see-next": "Voir votre prochaine échéance.",
    "subscription-next-frequency": "La nouvelle fréquence sera prise en compte au renouvellement de la facture.",
    "network-rename": "Renommer le Vswitch",
    "company-oid": "Identifiant unique la société.",
    "ticket-indexer": "Mettre à jour les tickets depuis IMAP",
    "subscription-payment-method": "Assigner ou réinitialiser le moyen de paiement de l'abonnement",
  },

  "severity": {
    "0": "Vérifier le paramétrage",
    "1": "Information",
    "2": "Avertissement",
    "3": "Alerte",
  },

  "actions": {
    "send": "Envoyer",
    "filter": "Filtrer",
    "confirm": "Confirmer",
    "cancel": "Annuler",
    "save": "Enregistrer",
    "generate": "Générer",
    "close": "Fermer",
    "delete": "Supprimer",
    "back": "Retour",
    "next": "Suivant",
    "buy": "Acheter",
    "quit": "Quitter",
    "validate": "Valider",
    "pay": "Payer",
    "enable": "Activer",
    "disable": "Désactiver",
    "apply": "Appliquer",
    "reboot": "Redémarrer maintenant",
    "reboot-acceptation": "J'accepte que mon serveur soit immédiatement redémarré",
    "more": "Afficher plus",
    "less": "Afficher moins",
    "search-servers": "Chercher des serveurs",
    "search-networks": "Chercher des réseaux",
    "click-to-copy": "Cliquer pour copier",
    "copied": "Copié",
    "change-avatar": "Changer l'avatar",
    "remove-avatar": "Supprimer l'avatar",
    "create_notification": "Créer une notification",
    "buy_space": "Acheter du stockage",
    "create": "Créer",
    "update": "Mettre à jour",
    "refund": "Rembourser",
    "add": "Ajouter",
    "message-avatar": "Cliquez pour modifier",
    "download": "Télécharger",
    "new": "Nouveau",
    "modify": "Modifier",
    "create-list": "Créer une liste",
    "show-list": "Voir/Editer la liste",
    "open-all": "Ouvrir tout",
    "close-all": "Fermer tout",
    "re-init": "Réinitialiser",
    "new-f": "Nouvelle",
    "open-list": "Afficher la liste",
    "close-list": "Fermer la liste",
    "terminate": "Résilier",
    "cancel-terminate": "Annuler la résiliation",
    "multi-pay": "Multi paiement",
    "cancel-delete": "Annuler la suppression",
    "phone-link": "Appel direct",
    "init-drp": "Accéder au PRA",
    "reset-drp": "Sortir du PRA",
  },
  "popup": {
    "coming-soon": "Coming soon",
  },
  "modal": {
    "definitive-action": "Cette action est définitive",
    "cancel": "Annuler",
    "confirm": "Confirmer",
    "finalize": "Finaliser la commande",
    "confirm-text": "Pour confirmer cette opération tapez 'CONFIRMER' ci-dessous.",
    "confirm-placeholder": "Tapez CONFIRMER",
    "confirmation-word": "CONFIRMER",
    "server-list": {
      "attached-servers": "Serveurs déjà attachés",
      "not-attached-servers": "Serveurs non attachés",
      "all": "Tous",
      "no-server": "Aucun serveur",
    },
    "dialog-reset-network": {
      "title": "Réinitialiser l'interface réseau",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, l'interface réseau du serveur sera réinitialisée.",
      "extra": "Êtes-vous sûr de vouloir réinitialiser l'interface réseau du serveur ?",
      "message2": "En confirmant cette action, l'interface réseau des serveurs sera réinitialisée.",
      "extra2": "Êtes-vous sûr de vouloir réinitialiser l'interface réseau de ces serveurs ?"
    },
    "dialog-reset": {
      "title": "Réinitialiser le serveur",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, votre serveur sera réinitialisé. Vos données et snapshots seront définitivement supprimés.",
      "extra": "Êtes-vous sûr de vouloir réinitialiser ce serveur ?"
    },
    "dialog-terminate": {
      "title": "Résilier le serveur",
      "warning": "Cette action est dangereuse mais réversible",
      "message": "En confirmant cette action, votre serveur sera résilié. Vos données et snapshots seront supprimés.",
      "extra": "Êtes-vous sûr de vouloir résilier ce serveur ?",
      "button": "Résilier"
    },
    "dialog-cancel-terminate": {
      "title": "Annulation de la résiliation du serveur",
      "warning": "Cette action est dangereuse",
      "message": "En confirmant cette action, la résiliation de votre server sera annulée.",
      "extra": "Êtes-vous sûr de vouloir annuler la résiliation de ce serveur ?",
      "button": "Annuler la résiliation"
    },
    "dialog-snapshot-restore": {
      "title": "Restaurer le snapshot",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, votre snapshot sera restauré sur votre serveur. Attention : cette opération va écraser les données de votre disque afin de les remplacer par les données contenues dans votre snapshot.",
      "extra": "Êtes-vous sûr de vouloir restaurer ce snapshot ?"
    },
    "dialog-snapshot-delete": {
      "title": "Supprimer le snapshot",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, ce snapshot sera supprimé.",
      "extra": "Êtes-vous sûr de vouloir supprimer ce snapshot ?"
    },
    "dialog-ip-detach": {
      "title": "Détacher une IP",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, cette IP sera détachée de ce serveur.",
      "extra": "Êtes-vous sûr de vouloir détacher cette IP ?"
    },
    "dialog-payment-method-remove": {
      "title": "Supprimer ce moyen de paiement.",
      "warning": "Cette action est importante.",
      "message": "En confirmant cette action, ce moyen de paiement sera supprimé.",
      "extra": "Êtes-vous sûr de vouloir supprimer ce moyen de paiement ?",
      "extra2": "Les abonnements pour lesquels ce moyen de paiement est utilisé seront réinitialisés avec le moyen de paiement par défaut.",
    },
    "dialog-order-cancel": {
      "title": "Annuler cette commande",
      "warning": "Cette action est importante.",
      "message": "En confirmant cette action, cette commande sera annulée",
      "extra": "Êtes-vous sûr de vouloir annuler cette commande ?"
    },
    "dialog-changing-frequency": {
      "title": "Êtes-vous sûr de vouloir changer la fréquence de facturation ?",
      "warning": "Le changement sera effectif lors votre prochaine facturation",
      "message": "La nouvelle fréquence de facturation : ",
    },
    "dialog-changing-project-name": {
      "title": "Êtes-vous sûr de vouloir changer le nom du projet ?",
      "warning": "Le changement de nom sur vos factures sera effectif lors votre prochaine facturation",
      "message": "Votre nouveau nom de project sera : ",
    },
    "dialog-ConfirmRoleChange": {
      "title": "Êtes-vous sûr de changer le rôle de ",
      "warning": "Cette action est importante.",
      "message": "Son nouveau rôle sera : ",
    },
    "dialog-user-delete": {
      "title": "Supprimer cet utilisateur",
      "warning": "Cette action est définitive",
      "message": "En confirmant cette action, cet utilisateur sera supprimé.",
      "extra": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
    },
    "dialog-invitation-delete": {
      "title": "Supprimer cette invitation",
      "warning": "Cette action est définitive",
      "message": "En confirmant cette action, cette invitation sera supprimée.",
      "extra": "Êtes-vous sûr de vouloir supprimer cette invitation ?"
    },
    "dialog-ticket-level-change": {
      "title": "Changement du niveau de priorité de votre ticket",
      "warning": "Cette action est importante",
      "message": "En confirmant cette action, votre ticket changera de priorité.",
      "extra": "Êtes-vous sûr de vouloir changer la priorité de votre ticket ?"
    },
    "dialog-company-update": {
      "title": "Modification des informations de la société",
      "success": "Ces nouvelles informations modifieront votre prochaine facture.",
      "message": "En confirmant cette action, les informations de votre société seront modifiées.",
      "extra": "Êtes-vous sûr de vouloir modifier ces informations ?",
    },
    "dialog-payment-cancel": {
      "title": "Annulation d'un paiement en attente.",
      "warning": "Cette action est définitive",
      "message": "En confirmant cette action, votre paiement sera annulé.",
      "button": "Confirmer"
    },
    "dialog-payment-refund": {
      "title": "Remboursement de ce paiement",
      "warning": "Cette action est définitive",
      "message": "En confirmant cette action, vous allez effectuer ce remboursement.",
      "button": "Confirmer"
    },
    "dialog-delete-image": {
      "title": "Supprimer l'image.",
      "warning": "Cette action est définitive",
      "message": "En confirmant cette action, votre image sera définitivement supprimé.",
      "button": "Supprimer"
    },
    "dialog-role-delete": {
      "title": "Supprimer le rôle",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, ce rôle sera supprimé.",
      "extra": "Êtes-vous sûr de vouloir supprimer ce rôle ?"
    },
    "dialog-ticket-creation": {
      "title": "Création du ticket",
      "success": "Le nouveau ticket sera créé après confirmation",
      "message": "En confirmant cette action, le nouveau ticket sera créé.",
      "button": "Confirmer"
    },
    "dialog-ticket-close": {
      "title": "Clôture du ticket",
      "title2": "Clôture du ticket sans réponse",
      "warning": "Le ticket sera clôturé après confirmation",
      "message": "En confirmant cette action, le ticket sera clôturé.",
      "button": "Confirmer"
    },
    "dialog-item-pricing-update": {
      "title": "Ajout d'un nouveau prix",
      "warning": "Cette action est importante",
      "message": "Vous modifiez l'article {item} - Nouveau prix : {price}",
      "button": "Créer",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-item-pricing-remove": {
      "title": "Suppression d'un nouveau prix",
      "warning": "Cette action est irréversible",
      "message": "Vous supprimer la mise à jour de l'article {item} - Le nouveau prix était : {price}",
      "button": "Supprimer",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-item-creation": {
      "title": "Création d'un article",
      "warning": "Cette action est importante",
      "message": "Vous créez l'article {item} de type : {type}",
      "button": "Créer",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-item-update": {
      "title": "Mise à jour d'un article",
      "warning": "Cette action est importante",
      "message": "Vous mettez à jour l'article {item} de type : {type}",
      "button": "Mise à jour",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-item-account-remove": {
      "title": "Suppression d'un nouveau code comptable",
      "warning": "Cette action est irréversible",
      "message": "Vous supprimer la mise à jour de l'article {item} - Le nouveau code comptable était : {code}",
      "button": "Supprimer",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-item-account-update": {
      "title": "Ajout d'un nouveau code comptable",
      "warning": "Cette action est importante",
      "message": "Vous modifiez l'article {item} - Nouveau code comptable : {code}",
      "button": "Créer",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-multi-pay-confirm": {
      "title": "Paiement multiple",
      "warning": "Cette action va enregistrer un paiement",
      "message": "Validez-vous la création de ce paiement ?",
      "button": "Valider",
    },
    "dialog-confirm-hyperV-migration": {
      "title": "Vous allez déclencher une migration",
      "warning": "Cette action est importante",
    },
    "dialog-confirm-hyperV-change-state": {
      "title": "Changement de l'état de l'hyperviseur - { hostname } -",
      "danger": "Vous allez désactiver cet hyperviseur",
      "warning": "Vous allez activer cet hyperviseur",
      "message": "Êtes-vous sûr de vouloir changer l'état de l'hyperviseur - { hostname } - ?"
    },
    "dialog-confirm-hyperV-change-state-all": {
      "title": "Changement de l'état de tous les hyperviseurs",
      "danger": "Vous allez changer l'état de tous les hyperviseurs",
      "message": "Êtes-vous sûr de vouloir exécuter cette opération ?"
    },
    "dialog-quote-delete": {
      "title": "Supprimer un devis",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, ce devis sera supprimé",
      "extra": "Êtes-vous sûr de vouloir supprimer ce devis ?"
    },
    "dialog-quote-accept": {
      "title": "Accepter un devis",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, ce devis sera accepté",
      "extra": "Êtes-vous sûr de vouloir accepter ce devis ?"
    },
    "dialog-notification-delete": {
      "title": "Supprimer une notification",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, cette notification sera supprimée",
      "extra": "Êtes-vous sûr de vouloir supprimer cette notification ?"
    },
    "dialog-vat-update": {
      "title": "Vous allez ajouter la TVA au pays {country}.",
      "warning": "Cette action est importante",
      "message": "La nouvelle TVA du pays {country} sera de {percentage}% le {date}",
      "button": "Mettre à jour",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-vat-remove": {
      "title": "Vous allez supprimer la TVA du pays {country}.",
      "warning": "Cette action est importante",
      "message": "La TVA de {percentage}% au {date} du pays {country} sera supprimée.",
      "button": "Mettre à jour",
      "extra": "En êtes-vous sûr ?"
    },
    "dialog-start-demo": {
      "title": "Démarrer la démo",
      "warning": "Cette action va deployer un serveur non payé",
      "message": "En confirmant cette action, le serveur sera déployé sans paiement du client.",
      "button": "Démarrer",
      "extra": "En êtes-vous sûr de vouloir activer le mode démo ?"
    },
    "dialog-stop-demo": {
      "title": "Stopper la démo",
      "warning": "Cette action va deployer un serveur non payé",
      "message": "En confirmant, le serveur sera désactivé jusqu'au paiement du client. Vous pourrez toujours activer le mode démo.",
      "button": "Stopper",
      "extra": "En êtes-vous sûr de vouloir désactiver le mode démo ?"
    },
    "dialog-enable-server": {
      "title": "Activer le serveur",
      "success": "Cette action va activer le serveur",
      "message": "En confirmant cette action, le serveur sera activé même si le client n'a pas payer sa facture. Vous pourrez toujours le désactiver",
      "button": "Activer",
      "extra": "En êtes-vous sûr de vouloir activer le serveur ?"
    },
    "dialog-disable-server": {
      "title": "Désctiver le serveur",
      "danger": "Cette action va désactiver le serveur",
      "message": "En confirmant cette action, toutes les communications avec le serveur seront désactivées. Vous pourrez toujours le résactiver",
      "button": "Désactiver",
      "extra": "En êtes-vous sûr de vouloir désactiver le serveur ?"
    },
    "dialog-commercial-delete": {
      "title": "Supprimer un commercial",
      "danger": "Cette action est irréversible",
      "message": "En confirmant cette action, ce commercial sera supprimé",
      "extra": "Êtes-vous sûr de vouloir supprimer ce commercial ?"
    },
    "dialog-alert-delete": {
      "title": "Supprimer une tâche",
      "danger": "Cette action est irréversible",
      "message": "En confirmant cette action, cette tâche sera supprimée",
      "extra": "Êtes-vous sûr de vouloir supprimer cette tâche ?"
    },
    "dialog-line-delete": {
      "title": "Supprimer une ligne",
      "danger": "Cette action est irréversible",
      "message": "En confirmant cette action, cette ligne sera supprimée",
      "extra": "Êtes-vous sûr de vouloir supprimer cette ligne ?"
    },
    "dialog-changing-outsourcing": {
      "title": "Modification d'infogérance",
      "warning": "Êtes-vous sûr de vouloir changer le niveau d'infogérance ?",
      "message": "En confirmant cette action, le niveau d'infogérance sera modifié",
    },
    "dialog-delete-server": {
      "title": "Supprimer immédiatement le serveur",
      "danger": "Cette action est irréversible",
      "message": "En confirmant cette action, le serveur sera supprimé.",
      "extra": "Êtes-vous sûr de vouloir supprimer ce serveur ?",
    },
    "dialog-invoice-ip": {
      "title": "Accord Avant Résilation",
      "warning": "L'ancienne adresse IP primaire de ce serveur sera facturée",
      "message": "En confirmant cette action, l'ancienne adresse IP primaire du serveur sera facturée.",
      "extra": "Êtes-vous sûr de vouloir continuer ?",
    },
    "dialog-cancel-terminate-ip": {
      "title": "Annulation de la résiliation de l'adresse IP",
      "warning": "Cette action est réversible",
      "message": "En confirmant cette action, la résiliation de l'IP sera annulée.",
      "extra": "Êtes-vous sûr de vouloir annuler la résiliation de cette IP ?",
      "button": "Annuler la résiliation"
    },
    "dialog-changing-payment-state": {
      "title": "Êtes-vous sûr de vouloir changer le statut de payment ?",
      "warning": "Le changement sera effectif immédiatement.",
      "message": "Le nouveau statut de payment est : ",
    },
    "dialog-subscription-update": {
      "title": "Modification de l'abonnement",
      "warning": "Si vous avez supprimé des articles, ceux-ci seront perdus",
      "message": "En confirmant cette action, l'abonnement sera modifé.",
      "extra": "Êtes-vous sûr de vouloir modifier cet abonnement ?"
    },
    "dialog-add-ipv6": {
      "title": "Ajout de l'IPV6 au serveur",
      "success": "L'adresse IPV6 sera ajoutée après confirmation",
      "message": "Il vous sera toujours possible de la supprimer depuis votre poool d'adresses",
      "button": "Confirmer"
    },
    "dialog-ticket-trash-delete": {
      "title": "Supprimer un ticket 'poubelle'",
      "danger": "Cette action est irréversible",
      "message": "En confirmant cette action, ce ticket sera supprimé (il ne sera pas supprimé de la boîte email).",
      "extra": "Êtes-vous sûr de vouloir supprimer ce ticket ?"
    },
    "dialog-ticket-trash-delete-many": {
      "title": "Supprimer plusieurs tickets 'poubelle'",
      "danger": "Cette action est irréversible",
      "message": "En confirmant cette action, les tickets sélectionnés seront supprimés (ils ne seront pas supprimés de la boîte email).",
      "extra": "Êtes-vous sûr de vouloir supprimer les tickets sélectionnés ?"
    },
    "dialog-apply-sub-payment-method": {
      "title": "Êtes-vous sûr de vouloir utiliser ce moyen de payment ?",
      "warning": "Le changement sera effectif lors de votre prochaine facturation",
      "message": "Moyen de paiement à utiliser : {payment_method}",
    },
    "dialog-remove-sub-payment-method": {
      "title": "Êtes-vous sûr de vouloir revenir au moyen de paiement par défaut ?",
      "warning": "Le changement sera effectif lors de votre prochaine facturation",
      "message": "En confirmant cette action, le moyen de paiement sera réinitialisé",
    },
    "dialog-order-delete": {
      "title": "Supprimer la commande",
      "warning": "Cette action est irréversible",
      "message": "En confirmant cette action, la commande sera supprimée.",
      "extra": "Êtes-vous sûr de vouloir supprimer cette commande ?"
    },
    "scheduled-date": {
      "title-schedule-reboot": "Programmer un redémarrage",
      "title-snapshot": "Programmer un snapshot",
      "already-scheduled": "Date déjà programmée | Dates déjà programmées",
      "new-schedule": "Nouvelle programmation",
      "validation-error": "L'heure doit être supérieure de 5 minutes au moins",
      "dialog-delete-scheduled-date": {
        "title": "Supprimer la programmation",
        "warning": "La modification sera prise en compte à la validation",
        "message": "En confirmant cette action, la programmation sera supprimée.",
        "extra": "Êtes-vous sûr de vouloir supprimer cette programmation ?",
      },
    },
    "project-selection": {
      "title": "Liste des projets",
      "placeholder": "Nom d'un projet",
      "name": "Nom du projet",
      "no-project": "Aucun projet",
      "number-of-projects": "Aucun projet | 1 projet | {nb} projets",
    },
    "server-selection": {
      "title": "Liste des serveurs",
      "number-of-servers": "Aucun serveur | 1 serveur | {nb} serveurs",
    },
  },

  "ip": {
    "type": "V",
    "reverse": "Reverse IP",
    "attach": "Attacher des IPs",
    "detach": "Détacher une IP",
    "buy": "Acheter des IPs",
  },

  "cart": {
    "apply-all": "Appliquer pour tous les serveurs",
    "dialog-update-all-pass": {
      "title": "Copie de l'authentification aux serveurs",
      "info": "",
      "message": "Voulez-vous appliquer ce mot de passe et/ou ces clés SSH pour tous les serveurs du panier ?",
      "extra": "En etes-vous sûr ?"
    },
    "quote": {
      "title": "Création d'un devis",
      "create": "Générer le devis",
      "validity_period": {
        "text": "Période de validité",
        "periode": "Mois"
      },
      "payment_methode": "Moyen de paiement",
      "payment_type": {
        'PSEPA': "Prélèvement",
        'card': "CB",
      },
      "modal": {
        "title": "Ajout du devis au panier",
        "message": "Vous avez la possibilité d'écraser votre panier courrant, de les fusionner ou d'annuler l'action en cours.",
        "merge": "Fusionner",
        "erase": "Ecraser",
        "cancel": "Annuler"
      },
    },
    "debug": {
      "validate": "Valider la commande",
    },
    "no-authentication": "Aucun moyen d'authentification",
    "update": "Appliquer pour ce serveur",
    "title": "Votre panier",
    "empty": "Aucune commande dans le panier…",
    "summary": "Résumé de votre commande :",
    "quantity": "Quantité",
    "add": "Ajouter au panier",
    "continue": "Continuer la commande",
    "no_modal": "Ne plus afficher ce message",
    "pay": "Payer",
    "payment": "Paiement",
    "order": "Commander un serveur",
    "order-add": "Ajouter un serveur",
    "removed": "Ligne supprimée",
    "updated": "Commande mise à jour",
    "success": "Commande effectuée avec succès",
    "selected_ssh_keys": "Clés SSH sélectionnées :",
    "password_use": "Mot de passe utilisé :",
    "project_name": "Nom du nouveau projet",
    "project_name_create": "Choisir ou créer un projet",
    "project_name_input_placeholder": "Entrer le nom du projet",
    "project_name_feedback": "Entre 4 et 60 caractères",
    "project_name_tip": "Choisissez un projet existant ou créez-le sinon il sera automatiquement généré.",
    "select_project_name": "Nom du projet de rattachement : ",
    "project_name_placeholder": "Nom de projet",
    "project_name_selector": "Sélection du nom de projet",
    "error_redirect": "Vous allez être redirigé vers la page Documents où vous pourrez finir le paiement.",
    "license": "Licence incluse",
    "update-credential": "Informations d'identification mises à jour.",
    "project": "Projet",
    "frequency": "Fréquence",
    "unit_amount": "Montant unitaire",
    "monthly_unit_amount": "Montant unitaire mensuel",
    "first_payment": "Premier paiement",
    "period": "Période du {date1} au {date2}",
    "discount": "Réduction",
    "monthly": "Montant mensuel",
    "quarterly": "Montant trimestriel",
    "biannually": "Montant semestriel",
    "annually": "Montant annuel",
    "added_to_cart": "Serveur ajouté au panier",
    "item_to_cart": "Article(s) ajouté(s) au panier",
    "cart_updated": "Panier mis à jour",
    "server_already_in_cart": "Serveur(s) déjà dans le panier",
    "server_with_drp": "Serveur(s) avec P.R.A. déjà activé",
    "server_with_outsourcing": "Serveur(s) ayant déjà une infogérance",
    "server": "Serveur",
    "label1": "Compagnie",
    "label2": "Commercial",
    "vswitch": "VSwitch",
    "amount": "Montant :",
    "mandatory": "Champ obligatoire",
    "button_paid": [
      "Veuillez sélectionner un moyen de paiement",
    ],
    "item_type": {
      "ip": "Adresse IP",
      "drp": "P.R.A.",
      "outsourcing": "Infogérance",
      "vswitch": "VSwitch",
      "vswitch_port": "Port VSwitch",
      "vswitch_qos": "QoS VSwitch",
      "license": "Licence",
    },
    "project-modal": {
      "title": "Choix du nom du projet",
      "comment": [
        "Tous les éléments d'un même projet seront regroupés sur une seule et même facture.",
      ],
      "header": [
        "Liste des projets existants",
        "Nouveau projet",
      ],
      "divided": "OU",
    },
    "outsourcing": {
      "level": "Niveau de service",
      "standard": "Standard",
      "advanced": "Avancé",
      "full": "Complet",
    },
    "over-quota-text": "# ⚠️ Important : Dépassement des Quantités de Ressources\n" +
      "\n" +
      "**{name}**\n" +
      "\n" +
      "Nous souhaitons attirer votre attention sur un point important concernant votre contrat cadre référence : **{reference}** en date du **{date}**.\n" +
      "\n" +
      "Il semble que les quantités de ressources demandées dépassent le quota de votre contrat référence {reference}. De ce fait, nous ne pouvons pas vous allouer les ressources demandées dans l'immédiat de manière automatique.\n" +
      "\n" +
      "📞 Nous vous invitons à prendre contact avec notre service commercial dans les plus brefs délais pour discuter des solutions possibles et convenir des actions à mettre en place pour régulariser cette situation. Si toutefois vous avez une urgence, vous pouvez composer le numéro de téléphone +33 9 88 99 8888. Notre équipe d'astreinte sera à même de vous accompagner. En parallèle, nous informons votre commercial.\n" +
      "\n" +
      "🙏 Nous vous remercions sincèrement pour votre compréhension et votre collaboration précieuse. Nous restons à votre entière disposition pour toute question ou clarification.\n" +
      "\n" +
      "Avec nos salutations distinguées,\n" +
      "\n" +
      "*L'équipe SmallCloud*",
  },

  "user": {
    "name": "Nom",
    "title": "Titre",
    "phone": "Téléphone",
    "mobile": "Portable",
    "email": "Email",
    "authorization": "Type",
    "role": "Accès",
    "roles": {
      "SUPER_ADMINISTRATOR": "Super Administrateur",
      "ADMINISTRATOR": "Administrateur",
      "ACCOUNTANT": "Comptable",
      "MANAGER": "Manager",
      "TITAN_SUPER_ADMINISTRATOR": "TITAN - Super Administrateur",
      "TITAN_ADMINISTRATOR": "TITAN - Administrateur",
      "TITAN_ACCOUNTANT": "TITAN - Comptable",
      "TITAN_COMMERCIAL": "TITAN - Commercial",
      "TITAN_MANAGER": "TITAN - Manager",
      "UNKNOWN": "Inconnu",
      "undefined": "Inconnu",
      "none": "Tous",
    }
  },

  "role": {
    "name": "Nom de l'accès",
    "allowed-servers": "Serveurs autorisés",
    "allowed-networks": "Networks autorisés",
    "authorization": "Type",
    "role": "Accès",
    "type": "Type",
    "servers": "Serveurs",
    "networks": "Réseaux",
    "custom-roles": "Accès",
    "add": "Ajouter un accès",
    "number_of_users": "Utilisateurs",
    "number_of_invites": "Invitations",
  },

  "tickets": {
    "modal": {
      "title": "Confirmation du message"
    },
    "tickets": "Tickets",
    "tickets-empty": "Vous n'avez pas encore de tickets.",
    "open-ticket": "Ouvrir un ticket",
    "status": {
      "processing": "En cours",
      "in_progress": "En cours",
      "closed": "Fermé",
      "assigned": "Assigné",
      "pending": "En attente",
      "pending_admin": "En attente",
      "pending_client": "En cours",
      "new_pending_admin": "En attente",
      "new_pending_client": "En cours",
      "new": "Nouveau",
    },
    "attachments": {
      "received": {
        "title": "Liste des fichiers",
        "header": [
          "Nom",
          "Type",
        ],
        "popup": "Voir la pièce jointe | Voir les pièces jointes",
        "error_message": "Erreur de lecture",
      },
      "sent": {
        "title": "Document(s) à ajouter",
        "popup": "Ajouter des documents",
        "text": [
          "Déposez vos fichiers ici",
          "ou",
          "cliquez pour les sélectionner",
        ],
        "placeholder": "Nom du fichier",
      },
      "content_type_error": "Type de fichier non autorisé",
    },
    "message": {
      "tooltip": [
        "Voir plus",
        "Voir moins",
      ],
    },
    "trash": {
      "message": [
        "Suppression réussie",
        "Erreur lors de la suppression",
      ],
    },
  },
  "invoices": {
    "invoice": "Facture",
    "order_title": "Commande",
    "credit_title": "Avoir",
    "next_invoice_title": "Prochaine Facture :",
    "download-pdf": "Télécharger en PDF",
    "download-multi-disabled": "Sélectionner des factures pour les télécharger",
    "download-multi": "Télécharger les factures sélectionnées",
    "payment-multi-disabled": "Sélectionner des factures non encore payées",
    "payment-multi": "Payer les factures sélectionnées",
    "payment-multi-unpaid": "Veuillez sélectionner uniquement des factures non payées",
    "delete-order": "Supprimer la commande",
    "delete-success": "Suppression réussie",
    "recurring": {
      "states": {
        "pending": "En attente",
        "ongoing": "En cours",
        "canceled": "Résilié"
      },
    },
    "states": {
      "all": "Tous",
      "upcoming": "À venir",
      "ribbon_pending": "Pending",
      "pending": "Pending",
      "ribbon_unpaid": "Non payée",
      "unpaid": "Non soldé(e)",
      "ribbon_paid": "Payée",
      "paid": "Soldé(e)",
      "ribbon_canceled": "Annulée",
      "canceled": "Annulée",
      "available": "Disponible",
      "spent": "Dépensé",
      "expired": "Expiré",
    },
    "types": {
      "order": "Commande",
      "invoice": "Facture",
      "credit": "Avoir"
    }
  },

  "kvm": {
    "title": "Accès distant",
    "p1": "Votre accès distant est en cours de création.",
    "p2": "Merci de patienter quelques instants.",
    "cancel": "Annuler",
    "start": "Accès distant",
    "stop": "Stopper l'accès distant",
    "fullscreen": "Passez en plein écran",
    "fullscreen_leave": "Quittez le plein écran",
    "expand": "Agrandir",
    "compress": "Réduire",
    "pip": "Passez en mode PIP"
  },

  "menu": {
    "upgrade": {
      "text": "Une nouvelle version de l'application est disponible : ",
      "button": "Recharger la page",
      "later": "Plus tard",
    },
    "faq": "FAQ",
    "accounts": "Compagnies",
    "affiliations": "Affiliations",
    "menu": "Menu",
    "dashboard": "Dashboard",
    "ip-histo": "Historique des IPs",
    "vms-sidebar": "Serveurs / Images",
    "vms": "Serveurs",
    "image": "Images",
    "container": "Conteneurs",
    "dedicated-serveur": "Serveurs dédiés",
    "vms-add": "Commande de serveurs",
    "companies": "Sociétés",
    "contacts": "Contacts",
    "network": "Réseaux",
    "templates": "Images",
    "company": "Société",
    "tickets": "Tickets",
    "recurring": "Abonnements",
    "documents": "Documents",
    "settings": "Paramètres",
    "logout": "Déconnexion",
    "lang": "langue",
    "dark-mode-header": "Dark mode",
    "dark-mode": "Dark",
    "light-mode": "Light",
    "notifications": "Notifications serveurs",
    "intervention": "Autres achats",
    "stop_impersonate": "Redevenir utilisateur normal",
    "tools": "Outils",
    "cron": "Tâches planifiées",
    "preference": "Préférences",
    "scheduled-action": "Actions planifiées",
  },

  "filters": {
    "search": "Rechercher.",
    "search-server": "Rechercher un serveur, OS, IP, MAC...",
    "reset": "Réinitialiser",
    "os": "Système d'exploitation",
    "all": "Tous",
    "service": "Service",
    "level": "Priorité",
    "project": "Nom du projet",
    "periodicity": "Périodicité",
    "affiliationLinks-per-page": "Nombre de liens par page : ",
    "servers-per-page": "Nombre de serveurs par page : ",
    "invoices-credits-per-page": "Nombre de factures et avoirs par page : ",
    "invoices-orders-per-page": "Nombre de commandes et factures par page : ",
    "recurring-per-page": "Nombre d'abonnements par page :",
    "images-per-page": "Nombre d'images par page :",
    "tickets-per-page": "Nombre de tickets par page :",
    "payments-per-page": "Nombre de réglements par page : ",
    "clients-per-page": "Nombre de clients par page : ",
    "orders-per-page": "Nombre de bons de commande par page : ",
    "ipsHisto-per-page": "Nombre d'ip par page : ",
    "item-per-page": "Nombre d'item par page : ",
    "discount-per-page": "Nombre de coupons par page : ",
    "country-per-page": "Nombre de pays par page : ",
    "events-per-page": "Nombre d'actions par page : ",
    "elements-per-page": "Nombre d'éléments par page : ",
    "notifications-per-page": "Nombre de notifications par page : ",
    "invoices-quote-per-page": "Nombre de devis par page : ",
    "ip-per-page": "Nombre d'IP par page :",
    "license-per-page": "Nombre de licences par page :",
    "line-per-page": "Nombre de lignes par page :",
    "invoice_types": {
      "invoice": "Facture",
      "order": "Commande",
      "credit": "Avoir"
    },
    "status": {
      "started": "Démarré",
      "stopped": "Arrêté",
      "pending": "En cours",
      "waiting": "En attente",
      "new": "Nouveau",
      "closed": "Fermé",
      "deleted": "Supprimé"
    },
    "ticket": {
      "processing": "En cours",
      "in_progress": "En cours",
      "closed": "Fermé",
      "assigned": "Assigné",
      "pending": "En cours",
      "pending_admin": "A traiter",
      "pending_client": "Traité",
      "new": "Nouveau",
      "new_pending_admin": "Nouveau",
      "new_pending_client": "Nouveau",
      "number": "Numéro de ticket",
      "object": "Objet",
      "company": "Société",
      "read": "Lu",
      "unread": "Non lu",
      "admin": {
        "pending_admin": "A traiter",
        "pending_client": "Traité",
        "closed": "Fermé",
      },
      "client": {
        "pending_admin": "Traité",
        "pending_client": "A traiter",
        "closed": "Fermé",
      },
    },
  },

  "ticket": {
    "server": "Serveur | Serveurs",
    "status": "Répondu",
    "number": "Nº",
    "user": "Créateur",
    "object": "Objet",
    "service": "Service",
    "product": "Produit",
    "created_at": "Date de création",
    "messages": "Messages",
    "company": "Société",
    "lock": "Vérouiller",
    "unlock": "Dévérouiller",
    "company_info": "Information clients",
    "last_reboot": "Dernier redémarrage",
    "indexer": "Réindexer",
    "state_date": "Dernière maj",
    "outsourcing_level": "Niveau de service",
    "level": "Priorité",
    "sort-by": "Trier par",
    "sort-button-tip-apply": "Appliquer le tri",
    "sort-button-tip-cancel": "Annuler",
    "state-date": "Dernier statut",
    "ticket-new": "Nouveau ticket",
    "ticket-response": "Ticket",
    "read": "Lu",
    "toggle-unread": "Marquer le ticket comme non lu",
    "toggle-read": "Marquer le ticket comme lu",
    "reply-sent": "Réponse envoyée",
  },
  "admin": {
    "client": {
      "actif": {
        "true": "Inactif",
        "false": "Actif"
      },
      "filter": "Filtre clients",
    },
    "discount": {
      "actif": {
        "true": "Actif",
        "false": "Inactif"
      },
      "type": {
        "FIX": "Fixe",
        "PERCENTAGE": "Pourcentage"
      },
    },
    "item": {
      "title": "Articles",
      "button": "Nouveau",
      "sort": {
        "sort1": {
          "title": "Éligible aux réductions",
          "state": {
            "true": "Actif",
            "false": "Inactif"
          },
        },
        "sort2": {
          "title": "Éligible aux abonnements",
          "state": {
            "true": "Oui",
            "false": "Non"
          },
        },
        "sort3": {
          "title": "Actif",
          "state": {
            "true": "Oui",
            "false": "Non"
          },
        },
      },
      "header": {
        "name": "Nom",
        "plan": "Plan",
        "category": "Catégorie",
        "enabled": "Actif",
        "type": "Type",
        "description": "Description",
        "price": "Prix actuel HT",
        "discount_enabled": "Réductions",
        "status": "Statut",
        "subtype": "Sous-type",
        "unit": "Unité",
      },
      "type": {
        "CPU": "CPU",
        "DISK": "Disk",
        "RAM": "RAM",
        "HARDWARE": "Hardware",
        "SERVICE": "Service",
        "LICENSE": "License",
        "QOS": "QOS",
        "MAC": "MAC",
        "IP": "IP",
        "SNAPSHOT": "Snapshot",
        "STORAGE": "Pool Storage",
        "CREDIT": "Crédit",
        "REBOOT": "Redémarrage serveur",
        "SMS": "SMS",
        "VSWITCH": "VSwitch",
        "rent": "Location",
        "buy": "Achat"
      },
      "creation": {
        "creation_title": "Création d'un nouvel article",
        "update_title": "Mise à jour d'un article",
        "modal": {
          "label1": "Nom",
          "placeholder1": "",
          "label2": "Description",
          "placeholder2": "Description",
          "label3": "Type d'article",
          "placeholder3": "",
          "label4": "Mode de facturation",
          "placeholder4": "",
          "label5": "Réduction",
          "placeholder5": "",
          "label6": "Actif",
          "placeholder6": "",
          "label7": "Prix HT",
          "placeholder7": "Prix HT",
          "label8": "Code comptable",
          "placeholder8": "Code comptable",
          "label9": "Code Plesk",
          "placeholder9": "",
          "label10":"Sous-type",
          "label11":"Nombre de crédits",
          "edit_price": {
            "label1": "Prix HT",
            "placeholder1": "Prix HT",
            "label2": "Date d'application",
            "placeholder2": "Date d'application",
          },
          "edit_code": {
            "label1": "Code comptable",
            "placeholder1": "Code comptable",
            "label2": "Date d'application",
            "placeholder2": "Date d'application",
          },
          "label12": "Unité",
          "edit_unit": {
            "label1": "Valeur",
            "label2": "Type",
          },
        },
        "message": {
          "create_success": "Création réussie",
          "update_success": "Mise à jour réussie",
          "remove_success": "Suppression réussie",
        },
      },
      "add_item": {
        "title": "Ajouter un item à un abonnement",
        "header1": "Item",
        "header2": "Numéro de série",
        "header3": "OID du serveur",
        "header4": "Qte",
        "checkbox1": "Créer une facture partielle",
        "checkbox2": "L'item est deployé",
        "start_date": "Date du début de la facture",
      },
      "manage_item": {
        "title": "Gestion des items de l'abonnement",
        "title-list": "Liste des items de l'abonnement",
        "delete-item": "Supprimer l'item",
        "delete-all": "Supprimer l'abonnement",
      },
    },
    "accounting": {
      "tabs": {
        "title": {
          "invoices": "Documents",
          "taxes": "Taxes",
          "payment": "Règlements",
          "orderForm": "Bons de commandes",
          "csv": "Export CSV",
          "dispute": "Litiges",
        },
      },
      "menu": [
        "Clients",
        "Affiliations",
        "Documents",
        "Abonnements",
        "Articles",
        "Coupons",
        "Taxes",
        "Délais de paiement",
        "Tâches journalières",
        "Infogérance",
        "Divers",
        "Licences",
      ],
    },
    "company": {
      "contact": {
        "title": "Contacts de la compagnie",
        "user": "Utilisateur",
        "email": "Adresse email",
        "role": "Rôle",
        "phone": "Téléphone",
        "role_from": "Vous allez change le rôle de {name}",
        "role_to": "Son nouveau rôle sera : {name}",
        "tooltip": [
          "Voir comme cet utilisateur",
          "Générer une vCard",
          "Informations détaillées",
        ],
      },
      "tabs": [
        "Clients",
        "Devis",
        "Commerciaux",
      ],
      "tab_title": "Commerciaux",
      "modal": {
        "title": "Assigner un commercial",
      },
      "popup": [
        "Modification en cours",
        "Cliquer pour modifier",
      ],
      "detail": {
        "tab": [
          "Info",
          "TVA",
          "Contacts",
          "Commercial",
          "Affiliations",
          "Quotas et limitations",
          "Transfert inter-sociétés",
          "Paramètres complémentaires",
        ],
        "info": {
          "label": [
            "Nom de la société",
            "Email",
            "Niveau de la réduction",
            "Compagnie désactivée",
            "Moyen de paiement actif",
            "Commercial",
            "Délai de paiement",
            "Encours autorisé",
            "Aucun",
            "Déploiement automatique des ressources",
            "Envoi automatique de la facture par email",
            "Paiement unique pour plusieurs factures",
            "URL pour le PRA",
            "Numéro de téléphone",
            "Nombre de crédits",
            "Autorisation d'envoi de SMS",
            "Regrouper les services sur une seule facture",
          ],
          "option": [
            "Aucun",
            "Immédiat | {days} jour | {days} jours",
            "Fin de mois | {days} jour fin de mois | {days} jours fin de mois",
          ],
          "help": [
            "Pour ajouter une nouvelle réduction, veuillez cliquer sur ce bouton en mode modification",
          ],
        },
        "vat": {
          "label": [
            "N° de TVA intracommunautaire",
            "Valide",
            "Vérifié",
          ],
          "information": [
            "Adresse",
            "Code pays",
            "Nom",
            "Date de requête",
            "N° de TVA",
            "Statut",
          ],
          "status": {
            "true": "Valide",
            "false": "Invalide",
          },
          "popup": [
            "Vérifier et voir les informations",
            "Vérifier",
            "Annuler la modification"
          ],
        },
        "commercial": {
          "message": [
            "Aucun commercial n'est assigné à cette companie.",
          ],
          "title": "Commercial de la compagnie",
          "button": [
            "Assigner un commercial",
            "Changer de commercial",
          ],
          "header": [
            "Nom",
            "Prénom",
            "Email",
          ],
          "modal": {
            "title": "Assigner un  commercial",
          },
        },
        "quota": {
          "label": [
            "Quotas",
            "Limitations",
            "Vswitchs",
          ],
          "header": [
            "Réseaux",
            "Serveurs",
            "CPU",
            "Mémoire",
            "Disque",
            "Snapshots",
            "Storage",
            "Interfaces",
            "Nombre de ports",
            "Qos",
          ],
        },
        "transfer": {
          "header": [
            "Type de transfert",
            "Société destinataire",
            "Abonnement(s) à transférer",
            "Serveur(s) à transférer",
            "Abonnement de destination",
            "Mise à zéro des prix",
          ],
          "type": {
            "1": "Transfert complet vers une autre société",
            "2": "Transfert d'abonnement(s) vers une autre société",
            "3": "Transfert de serveur(s) vers une autre société",
            "4": "Transfert de serveur(s) entre abonnements d'une même société",
          },
          "help": {
            "1": "Transfert des abonnements, serveurs, IPs et Vswitchs",
            "2": "Transfert des abonnements et des serveurs et IPs associées",
            "3": "Transfert des serveurs et leurs IPs associées",
            "4": "Transfert de serveurs d'un abonnement vers un autre sur la même société",
            "5": "Par défaut l'abonnement de destination portera le même nom que celui d'origine",
          },
        },
      },
    },
    "coupon": {
      "title": "Coupons de réduction",
      "button_new": "Nouveau",
      "button_close": "Fermer",
      "button_add": "Ajouter",
      "status": {
        "title": "État",
        "enable": "Actif",
        "disable": "Inactif",
      },
      "code": "Code",
      "use": "Utilisation",
      "description": "Description",
      "expiry_date": "Date d'expiration",
      "never": "Jamais",
      "no_coupon": "No coupon found",
      "popover": {
        "modify": "Modifier",
        "enable": "Activer",
        "duplicate": "Dupliquer",
        "disable": "Désactiver",
        "suppress": "Supprimer",
        "at_start_date": "Effectif dès sa création",
        "always": "Permanent",
        "unlimited": "0 = illimité",
        "need": "Vous devez sélectionner au moins un item et entrer un code",
        "need_member": "Vous devez entrer un code et saisir une remise globale ou sélectionner au moins un item",
      },
      "modal": {
        "title": "Détail du coupon",
        "code": "Code :",
        "description": "Description :",
        "expiry_date": "Date d'expiration : ",
        "max_use": "Nombre maximal d'utilisation : illimité | Nombre maximal d'utilisation : {count}",
        "first_order": "Uniquement à la 1ère commande d'une entreprise",
        "clients": "Client cible : | Clients cibles :",
        "items": "Aucun item | Item cible : {count} | Items cibles : {count}",
        "number": "N°",
        "invoice_date": "Date facture",
        "client": "Client",
        "total": "Total HT",
        "discount_amount": "Montant de la remise"
      },
      "creation": {
        "title": "Création/Mise à jour d'un coupon",
        "tab1": {
          "title": "Paramètres du coupon",
          "label": "",
          "code": "Code du coupon",
          "first_order": "Uniquement à la 1ère commande d'une entreprise",
          "clients": "Cilbler un client en particulier",
          "description": "Description",
          "items": "Nombre d'items sélectionnés",
          "start_date": "Date de début",
          "expiry_date": "Date d'expiration",
          "max_use": "Nombre maximal d'utilisation total",
          "max_use_client": "Nombre maximal d'utilisation par client",
          "amount": "Remise globale",
          "help": "Saisir soit une remise globale, soit une liste d'items",
        },
        "tab2": {
          "title": "Gestion des items",
          "label1": "Item sélectionné | Item selectionné | Items sélectionnés",
          "label2": "Ajouter un ou plusieurs items",
          "label3": "Quantité maximum par coupon",
          "header": [
            "Item",
            "Type de réduction",
            "Valeur de la réduction",
            "Qte max",
          ],
          "discount": {
            "type": {
              "FIX": "Montant fixe",
              "PERCENTAGE": "Pourcentage"
            },
            "type_sign": {
              "FIX": "€",
              "PERCENTAGE": "%"
            },
            "help": "Vide signifie aucune limite",
          },
        }
      },
      "alert": [
        "Coupon activé",
        "Coupon désactivé"
      ],
      "switch": {
        "coupon": "Général",
        "member": "Membre",
        "affiliate": "Affiliation",
      },
    },
    "multi_payment": {
      "title": "Paiement multiple",
      "amount_received": "Montant reçu",
      "total_amount_received": "Montant total reçu ({total} €)",
      "payment_date": "Date de paiement",
      "payment_method": "Mode de paiement",
      "reference_number": "N° de référence",
      "unpaid_invoices": {
        "title": "Factures impayées",
        "header1": "Date",
        "deadline": "date d'échéance",
        "header2": "Numéro de facture",
        "header3": "Montant",
        "header4": "Montant dû",
        "header5": "Paiement",
      },
      "non_chargeable_amount": "Montant non imputable {amount}€",
    },
    "payment": {
      "modal": {
        "invoice_date": "Date de facture",
        "payment_amount": "Montant du paiement",
        "refund_amount": "Montant remboursé",
        "reference_number": "Référence",
        "client": "Client",
        "invoice_number": "Facture",
        "status": "Statut",
        "bank_status_code": "Statut de la banque",
        "bank_status_message": "Message de la banque",
        "dispute": "Litige",
        "bank_payment_id": "Référence banque",
      },
      "tab": {
        "title": "Ajouter un paiement",
        "header1": "type",
        "header2": "Id de la transaction",
        "header3": "Montant",
        "header4": "Date d'exécution",
        "modal_question": "Êtes-vous sur de vouloir créer ce paiement ?",
      },
    },
    "refund": {
      "refund_number": "Référence {reference}",
      "initial_amount": "- Montant du règlement TTC : ",
      "message_no_invoice": "Aucune facture n'a été payée par ce règlement",
      "checkbox1": "Ne pas créer d'avoir",
      "checkbox2": "Remboursement partiel",
      "help": "Choisir ou saisir une raison",
      "related_invoice": "Facture de rattachée | Factures de rattachés",
      "header1": "Nom",
      "header2": "Qté",
      "header3": "Prix HT",
      "credit": "Crédit restant HT",
      "amount_excluding_vat": "Montant HT",
      "amount_including_vat": "Montant TTC",
      "total_excluding_vat": "Total HT",
      "total_including_vat": "Total TTC",
      "prepend": "Montant du remboursement TTC",
      "total_credit": "Total crédit restant HT",
      "total_refund": "Total remboursement",
      "reasons": [
        "Raison du remboursement",
        "Paiement double",
        "Frauduleux",
        "Demandé par le client",
      ],
      "placeholder": [
        "Référence",
        "Banque",
        "Date création",
        "Raison de l'avoir",
        "Qté",
        "Prix",
        "Montant du remboursement sans crédit",
      ],
      "excluding_vat": "HT",
      "including_vat": "TTC",
    },
    "migration": {
      "title": "Migration d'hyperviseur",
      "label1": "Mode de la migration",
      "label2": "Sélectionner un hyperviseur source",
      "label3": "Serveur UUID source",
      "label4": "Sélectionner un hyperviseur cible",
      "label5": "Hyperviseur",
      "no_hypervisor": "Aucun hyperviseur disponible",
      "success_message1": "L'hyperviseur { hostname } sera bien { action }.",
      "success_message2": "Migration démarrée",
      "title-list": "Liste des hyperviseurs",
      "state": {
        "disable": "Désactivé",
        "enable": "Activé",
      },
      "success_message": "Les changements ont été effectués avec succès",
    },
    "notification": {
      "button_migration": "Migration d'hyperV",
      "button_management": "Gestion d'hyperV",
      "button_assignment": "Assigner une notification",
      "header": [
        "Sévérité",
        "Titre",
        "Message",
        "Redémarrage",
        "Serveurs",
        "Consentement",
      ],
      "modal": {
        "title": "Assigner une notification",
        "title_add": "Notification",
        "label": [
          "Sévérité",
          "Titre",
          "Message",
          "Forcer le redémarrage",
          "OID serveurs",
          "Consentement pour effacement notification",
        ],
      },
      "reboot": [
        "Oui",
        "Non",
      ],
      "agreement": [
        "Oui",
        "Non",
      ],
    },
    "vat": {
      "title": "Modification du taux de TVA d'un pays",
      "filter": "TVA > 0",
      "header": [
        "Pays",
        "Code pays",
        "Pourcentage",
      ],
      "label": [
        "Date d'application",
        "Poucentage"
      ],
      "message": [
        "Mise à jour réussie",
        "Date choisie < date du jour"
      ],
    },
    "export": {
      "label": [
        "Filtre de date",
        "Ecritures",
        "Compagnies",
        "Nouvelles compagnies",
        "Compagnies mises à jour",
        "Télécharger CSV",
        "Téléchargement en cours ...",
        "Contacts",
        "Pour CRM",
        "Nouveaux contacts",
        "Contacts mis à jour",
      ],
    },
    "payment_delay": {
      "title": "Délais de paiement",
      "button": "Nouveau",
      "sort": {
        "state": {
          "true": "Actif",
          "false": "Inactif"
        },
      },
      "header": [
        "Nb de jours",
        "Fin de mois",
        "Actif",
      ],
      "operation": {
        "title": [
          "Création d'un délai de paiement",
          "Mise à jour d'un délai de paiement",
        ],
        "header": [
          "Nb de jours",
          "Fin de mois",
          "Actif",
        ],
        "message": [
          "Création réussie",
          "Erreur lors de la création",
          "Mise à jour réussie",
          "Erreur lors de la mise à jour",
        ],
      },
    },
    "alert": {
      "title": "Tâches journalières",
      "button": "Nouveau",
      "sort": {
        "state": {
          "true": "Active",
          "false": "Inactive"
        },
      },
      "header": [
        "Type de tâche",
        "Catégorie du template",
        "Nom du template",
        "Extension du template",
        "Type d'envoi",
        "Nb d'envois max par jour",
        "Echéance",
        "Type d'échéance",
        "Email Cci",
        "Active",
        "Désactiver serveur",
      ],
      "operation": {
        "title": [
          "Création d'une tâche",
          "Mise à jour d'une tâche",
        ],
        "header": [
          "Type de tâche",
          "Catégorie du template",
          "Nom du template",
          "Extension du template",
          "Type d'envoi",
          "Nb d'envois max par jour",
          "Echéance",
          "Type d'échéance",
          "Email Cci",
          "Active",
          "Désactiver serveur",
        ],
        "message": [
          "Création réussie",
          "Erreur lors de la création",
          "Mise à jour réussie",
          "Erreur lors de la mise à jour",
        ],
      },
      "type": {
        'before_server_deletion': 'Avant suppression serveur',
        'delete_server': 'Suppression serveur',
        'delete_ip': 'Suppression IP',
        "delete_outsourcing": "Résiliation infogérance",
        'invoice_unpaid': 'Facture impayée',
        'payment_method_expired': 'Moyen de paiement expiré',
        'invoice_renew': 'Facturation',
        'payment_error': 'Erreur de paiement',
        'cart_is_paid': 'Panier payé',
        'ticket_before_closing': 'Avant clôture du ticket',
        'ticket_closing': 'Clôture du ticket',
      },
      "template_category": {
        'server': 'Serveur',
        'renewal': 'Renouvellement',
        'payment': 'Paiement',
        'invoice': 'Factures',
        'ticket': 'Ticket',
        'ip': 'Adresse IP',
        'outsourcing': 'Infogérance',
      },
      "send_type": {
        'email': 'Email',
      },
      "time_diff_type": {
        'day': 'Jour',
        'month': 'Mois',
      },
      "help": [
        "Nombre de fois que l'email est envoyée dans la même journée ou le même mois (-1: Toujours, 0: 1 fois)",
        "Nombre de jours/mois avant (valeur négative) ou après (valeur positive) la date de contrôle",
        "Adresse email recevant le message en plus de celle du client (optionelle)",
        "Active/Désactive la tâche",
        "Uniquement pour les factures impayées. Exécute la désactivation du serveur si coché.",
      ],
    },
    "miscellaneous": {
      "title": "Divers",
      "none_option": "Aucun",
      "param": {
        "title": "Paramètres",
        "button": "Nouveau",
        "header": [
          "Nom",
          "Type",
          "Valeur",
          "Libellé",
          "Date",
          "Valeur min",
          "Valeur max",
          "Clé dictionnaire",
          "Valeur dictionnaire",
        ],
        "operation": {
          "title": [
            "Création",
            "Mise à jour",
          ],
          "header": [
            "Nom",
            "Type",
            "Valeur",
            "Libellé",
            "Date",
            "Valeur min",
            "Valeur max",
            "Clé dictionnaire",
          ],
          "message": [
            "Création réussie",
            "Erreur lors de la création",
            "Mise à jour réussie",
            "Erreur lors de la mise à jour",
            "Suppression réussie",
            "Erreur lors de la suppression",
          ],
        },
      },
      "function": {
        "title": "Fonctions",
        "all": "Tous",
        "help": [
          "Choisir une compagnie ou laisser blanc pour toutes les compagnies.",
          "Saisir l'IPv4 primaire puis cliquer sur le bouton. Le champ 'redirection' sera mis à blanc.",
          "Saisir une adresse IPv4 et choisir un ou plusieurs contrôles à effectuer. Appuyer ensuite sur le bouton.",
          "Entrer la date limite de suppression et cliquer sur le bouton.",
        ],
        "function": [
          "Recalcul du solde",
          "Reset redirection IPv4 primaire",
          "Contrôles sur adresse IP",
          "Créer un abonnement",
          "Suppression des 'Provisioning VM'",
        ],
        "message": {
          "successful": "Mise à jour réussie",
          "unsuccessful": "Echec de mise à jour",
        },
        "api-result": {
          "title": "Résultat des contrôles sur IP {ip}",
          "more": "Afficher le resultat brut",
          "less": "Ne plus afficher le résultat brut",
          "header": [
            "PING",
            "NMAP",
            "TRACEROUTE",
            "ABUSE",
          ],
          "show-result": "Cliquer sur le contrôle à effectuer",
          "result-ping": [
            "Nombre de paquets transmis : ",
            "Nombre de paquets reçus : ",
            "Pourcentage de paquets perdus : "
          ],
          "result-nmap": [
            "Liste des ports ouverts : ",
            "Aucun ports ouverts",
          ],
          "result-traceroute": [
            "Saut {hop} : ",
            "Aucun résultat"
          ],
          "result-abuse": [
            "Fait partie de la liste blanche",
            "Adresse IP blacklistée",
            "Adresse IP non blacklistée",
            "Aucun résultat"
          ],
        },
        "api-buttons": {
          "button-ping": "Lancer un Ping sur cette adresse IP",
          "button-nmap": "Lancer un Nmap sur cette adresse IP",
          "button-traceroute": "Lancer un Traceroute sur cette adresse IP",
          "button-abuse": "Lancer un Abuse sur cette adresse IP",
        },
        "outsourcing": {
          "header": [
            "Compagnie à facturer",
            "Serveur(s)",
            "Article",
            "Fréquence",
            "Nom du projet",
            "Compagnie propriétaire des serveurs",
            "Type d'abonnement",
          ],
          "number-of-servers": "Aucun serveur | 1 serveur | {nb} serveurs",
        },
      },
      "ticket-trash": {
        "title": "Tickets poubelle",
      },
    },
    "outsourcing": {
      "title": "Infogérance",
      "button": "Nouveau",
      "no_param": "Veuillez créer tous les paramètres nécessaires à la création des niveaux de service.",
      "month": "mois",
      "header": [
        "Niveau",
        "Libellé",
        "Description",
        "Criticité",
        "Infogérance",
        "Priorité",
        "Intervention",
        "Prix HT",
        "Au",
        "Article",
        "Durée",
        "Préavis",
      ],
      "operation": {
        "title": [
          "Création",
          "Mise à jour",
          "Edition de la description",
        ],
        "header": [
          "Niveau d'infogérance",
          "Libellé",
          "Description",
          "Criticité",
          "Infogérance",
          "Priorité",
          "Temps d'intervention",
          "Prix HT",
          "Date d'application",
          "Article",
          "Durée d'engagement (en mois)",
          "Préavis (en mois)",
        ],
        "message": [
          "Création réussie",
          "Erreur lors de la création",
          "Mise à jour réussie",
          "Erreur lors de la mise à jour",
          "Suppression réussie",
          "Erreur lors de la suppression",
          "Veuillez saisir les champs obligatoires",
          "Champs obligatoires",
        ],
      },
    },
    "param": {
      "outsourcing": {
        "outsourcing": "Niveau de service",
        "standard": "Infogérance standard",
        "advanced": "Infogérance avancée",
        "full": "Infogérance complète",
      },
      "outsourcing_criticality": {
        "outsourcing_criticality": "Criticité",
        "low": "Faible",
        "medium": "Modérée",
        "high": "Elevée",
      },
      "outsourcing_priority": {
        "outsourcing_priority": "Priorité",
        "medium": "Moyenne",
        "high": "Elevée",
        "priority": "Prioritaire",
      },
      "outsourcing_level": {
        "outsourcing_level": "Infogérance",
        "none": "Aucune",
        "medium": "Modérée",
        "full": "Complète",
      },
      "intervention_type": {
        "intervention_type": "Type d'intervention",
        "day": "Jour(s)",
        "hour": "Heure(s)",
        "minute": "Minutes",
      },
    },
    "ticket-indexer": {
      "title": "Réindexation des tickets",
      "header": [
        "Nombre maximum d'emails à traiter",
        "Date la plus ancienne à traiter",
      ],
      "help": "Laisser ces valeurs vides pour réindexer tous les emails",
      "message": [
        "Tâche démarrée"
      ],
    },
    "subscription": {
      "type": {
        "0": "Standard",
        "1": "Infogérance",
        "2": "P.R.A.",
        "3": "IP",
        "4": "Stockage",
        "5": "Crédit",
        "6": "VSwitch",
        "7": "Port",
        "8": "QoS",
        "100": "Contrat cadre",
      },
      "title": "Détail de l'abonnement",
      "return": "Retour",
      "return-text": "Retour à la liste des abonnements",
      "tab": [
          "Général",
          "Factures",
      ],
      "overview": {
        "header": [
          "Nom du projet",
          "Périodicité",
          "Date de début",
          "Date de fin",
          "Date de mise à jour",
          "Statut",
          "Paiement désactivé pour renouvellement des factures",
          "Saisir un suffixe",
        ],
        "footer": [
          "Total HT",
          "TVA",
          "Total TTC",
          "Remise",
          "Total Net HT",
        ],
        "update": {
          "success": "Mise à jour de l'abonnement réussie",
          "nothing": "Aucune information à mettre à jour",
          "commercial-success": "Mise à jour du commercial réussie",
          "date-warning": "Veuillez saisir une date de fin",
          "partial-billing": "Créer une facture partielle",
        },
        "detail": {
          "title": "Articles de l'abonnement",
          "header": [
            "Article",
            "Désignation",
            "Serveur",
            "Quantité",
            "Prix unitaire",
            "Remise",
            "Montant HT",
            "Déployé",
          ],
        },
        "button": {
          "modify": "Modifier l'abonnement",
          "modify-header": "Modifier l'entête de l'abonnement",
          "add-item": "Ajouter un item",
          "validate": "Valider les modifications",
          "cancel": "Annuler les modifications",
          "create-invoice": "Créer une facture",
          "generate-invoice": "Générer la facture",
        },
        "messages": [
          "Champs requis",
          "Validation des informations et création de l'abonnement",
        ],
      },
    },
    "demo": {
      "enable": {
        "title": "Activation du mode démo",
        "server-name": "Nom du serveur",
        "transfer": "Transférer le serveur vers un client ?",
        "message-success": "Mode Démo activé avec succès",
        "message-error": "Erreur lors de l'activation du mode Démo",
      },
      "disable": {
        "title": "Désactivation du mode démo",
        "server-name": "Nom du serveur",
        "return":"Retour du serveur dans le pool Titan",
        "true": "Oui",
        "false": "Non",
        "project": "Abonnement de destination",
        "frequency": "Périodicité",
        "billing":"A facturer ?",
        "billing-date":"Facturation depuis le",
        "message-success": "Mode Démo désactivé avec succès",
        "message-error": "Erreur lors de la désactivation du mode Démo",
      },
    },
    "license": {
      "title": "Licences",
      "plesk": {
        "title": "Plesk",
        "list-empty": "Liste vide",
        "generate": "Générer la liste",
        "tooltip": [
          "Générer ou régénérer la liste",
          "Télécharger un CSV",
        ],
        "waiting": "Le temps du traitement en cours peut dépasser les 2mn. Merci de patienter.",
        "search":"Recherche par identifiant, item, ip, client, serveur",
        "goto-server": "Voir le serveur",
        "goto-subscription": "Voir l'abonnement",
        "goto-invoice": "Voir la facture",
        "header": [
          "Identifiant",
          "Statut",
          "Item",
          "Création",
          "Binding",
          "Client",
          "Server",
          "Etat serveur",
          "Abonnement",
          "Dernière facture",
          "Key ID",
          "Expiration",
          "Code d'activation",
          "Description",
          "IP rapportée(s)"
        ],
        "sort": {
          "status": {
            "all": "Tous",
            "ACTIVE": "Active",
            "TERMINATED": "Inactive",
          },
        },
        "errors": {
          "title": "Erreurs",
          "legend": "Légende",
          "error_legend": [
            "Société inconnue",
            "Serveur supprimé ou inconnu",
            "Abonnement résilié",
            "Mauvaise affectation serveur",
          ],
          "error_code": {
            "0": "Sans",
            "1": "Société",
            "2": "Server",
            "3": "Abonnement",
            "4": "Faux",
          },
        },
        "modal": {
          "disable": {
            "title": "Désactivation de la licence",
            "message": "En confirmant cette action, la licensce sera désactivée.",
            "warning": "Vous pourrez toujours réactiver cette license",
            "extra": "Êtes-vous sûr de vouloir désactiver la licence ?",
          },
        },
        "message": [
          "La licence à été désactivée",
          "Erreur lors de la désactivation",
        ],
        "info": {
          "title": "Details de la licence",
          "button": {
            "modify": "Modifier la licence",
            "stop-modify": "Arrêter la modification",
          },
        },
      },
    },
    "quote": {
      "overview": {
        "title": [
          "Devis N°",
          "Nouveau devis",
        ],
        "header": [
          "Société",
          "Commercial",
          "Date de création",
          "Valable jusqu'au",
          "Statut",
          "Accepté le"
        ],
        "footer": [
          "Total HT",
          "TVA",
          "Total TTC",
          "Remise",
          "Total Net HT",
        ],
        "state": {
          "ongoing": "En cours",
          "accepted": "Accepté",
          "canceled": "Invalidé",
        },
        "messages": {
          "create-success": "Création du devis réussie",
          "create-error": "Erreur lors de la création du devis",
          "update-success": "Mise à jour du devis réussie",
          "update-error": "Erreur lors de la mise à jour du devis",
          "nothing": "Aucune information à mettre à jour",
          "already-used": "Nom déjà utilisé",
        },
        "detail": {
          "title": "Détail du devis",
          "header": [
            "Article",
            "Désignation",
            "Serveur",
            "Quantité",
            "Prix unitaire",
            "Remise",
            "Montant HT",
          ],
        },
        "button": {
          "modify": "Modifier le devis",
          "open-menu": "Ouvrir le menu",
          "close-menu": "Fermer le menu",
          "delete-item": "Supprimer l'item",
          "add-item": "Ajouter un item",
          "add-comment": "Ajouter un commentaire",
          "validate": "Valider les modifications",
          "cancel": "Sortir et annuler les modifications",
          "create": "Créer le devis",
          "show-quote": "Afficher le devis",
          "show": "Afficher",
          "exit": "Sortir",
          "item-up": "Insérer un article au-dessus",
          "item-down": "Insérer un article au-dessous",
          "comment-up": "Insérer un commentaire au-dessus",
          "comment-down": "Insérer un commentaire au-dessus",
        },
      },
    },
    "dispute": {
      "modal": {
        "document_number": "N° litige",
        "created": "Création",
        "amount": "Montant du paiement",
        "fees": "Frais bancaires",
        "total_amount": "Total dû",
        "client": "Client",
        "invoices": "Factures",
        "status": "Statut",
        "bank_dispute_id": "Référence du litige",
        "bank_payment_id": "Référence du paiement",
        "reason": "Motif",
        "paid": "Réglé",
        "authorize_SEPA": "Autoriser le client à payer par SEPA",
        "invoice_unlocked": "Débloquer les factures pour un paiement séparé",
        "cancel-deactivation": "Annuler les déactivations serveurs planifiées",
        "dialog-cancel-deactivation": {
          "title": "Annulation des déactivations serveurs planifiées",
          "warning": "Cette action est sensible",
          "message": "En confirmant cette action, les désactivations seront annulées.",
          "extra": "Êtes-vous sûr de vouloir annuler les désactivations ?",
          "button": "Annuler les désactivation"
        },
      },
    },
  },
  "invoice": {
    "server": "Serveur",
    "status": "Etat",
    "number": "Nº",
    "client_code": "Code client",
    "client_name": "Client",
    "balance": "Solde",
    "final_date": "Échéance",
    "product": "Produit",
    "created_at": "Date de création",
    "create": "Créé le",
    "start": "Débuté le",
    "update": "Mis à jour le ",
    "not_found": "Aucune facture trouvée.",
    "not_found_search": "Aucune facture ne correspond à votre recherche.",
    "type": "Type",
    "due_date": "Date de fin",
    "amount_ht": "Montant HT",
    "amount_ttc": "Montant TTC",
    "changing_project_name": {
      "title": "Êtes-vous sûr de vouloir changer le nom du projet ?",
      "description": "Le nouveau nom du projet sera : ",
      "success": "Le changement de nom a été effectué avec succès.",
    },
    "frequency": {
      "title": "Fréquence",
      "detailed_title": "Période de facturation",
      "changing_title": "Êtes-vous sûr de vouloir changer la fréquence de facturation ?",
      "success": "Le changement de la fréquence de facturation a été effectué avec succès.",
      "MONTHLY": "Mensuelle",
      "QUARTERLY": "Trimestrielle",
      "BIANNUALLY": "Semestrielle ",
      "ANNUALLY": "Annuelle",
    },
    "last_invoice": "Dernière Facture",
    "next_invoice": "Prochaine Facture",
    "see_all_invoice": "Voir les factures",
    "company": "Société",
    "price": "Montant",
    "project_name": "Projet",
    "add_item": "Ajouter un item",
    "manage_item": "Gérer les item",
    "generate_invoice": "Générer une facture",
    "payment_method": "Moyen de paiement",
    "details": {
      "status": "État de la facture",
      "billing_address": "Facturé à :",
      "shipping_address": "Livré à :",
      "same_shipping_billing": "Livré et facturé à :",
      "description": "Description",
      "initial_amount": "Montant HT",
      "discount_rate": "Remise",
      "discount_amount": "Montant de la remise",
      "net_amount": "Total HT net",
      "vat_rate": "Taux TVA",
      "vat_amount": "Total TVA",
      "total_amount": "Total TTC",
      "remaining_amount": "Montant dû",
      "download": "Télécharger",
      "legal_notice_text": "Taux de pénalités : En application de la loi N° 921442 du 31 décembre 1992, cette facture est payable à l'échéance indiquée ci-dessus sans escompte pour paiement anticipé. Toute facture émise et non payée à son échéance portera, de plein droit, intérêts à un taux égal à trois fois le taux légal tel que défini aux termes de l'article L.441-6 du Code de Commerce, jusqu'à parfait paiement.Indemnité forfaitaire pour frais de recouvrement de 40 euros (Décret 2012-1115 du 2 octobre 2012).",
      "tva_notice_in_eu": "Exonération de TVA - article 196 de la Directive 2006/112/CE",
      "tva_notice_out_eu": "TVA non applicable - article 44 de la Directive 2006/112/CE",
      "facturation_date": "Date de facture :",
      "credit_date": "Date de l'avoir :",
      "payment": "Règlement : À réception",
      "due_date": "Date d'échéance :",
      "billing_period": "Période de facturation :",
      "billing_period_sm": "Période",
      "project": "Projet :",
      "page": "page",
      "of": "sur",
      "subtotal": "Sous-total HT",
      "subtotalTag": "HT",
      "cancel": "Annuler",
      "paid_by": "Payée par :",
      "promotion_code": "Code promotionnel",
      "item": {
        "name": "Produit",
        "quantity": "Qte",
        "unit_price": "P.U",
        "total_price": "Total HT"
      },
      "billing_date": "Début de facturation le",
    },
    "modal": {
      "button": {
        "pay": "Payer",
        "cancel": "Fermer",
        "return": "Retour",
        "next": "Suivant",
        "generate_credit": "Générer un avoir",
        "clear": "Solder",
        "deploy": "Déployer",
      },
      "payment": {
        "invoices_page": "Page factures",
        "servers_page": "Page serveurs",
      },
      "clear": {
        "title": "Solder une facture ou un avoir",
        "header": [
          "Nouveau montant",
        ],
        "success": "Opération effectuée avec succès",
      },
      "deploy": {
        "ask_deploy": "Êtes-vous sur de vouloir déployer les ressources ?",
        "success": "Le déploiement est en cours",
        "failed": "La demande a échoué",
      },
    },
    "recurring": {
      "not_found": "Aucun abonnement trouvé.",
      "not_found_search": "Aucun abonnement ne correspond à votre recherche.",
      "payment_method": {
        "text": [
          "Choisir le moyen de paiement à utiliser pour l'abonnement et cliquer sur appliquer.",
          "Pour utiliser le moyen de paiement par défaut, cliquer sur réinitialiser.",
          "En cas d'échec de paiement, le moyen de paiement par défaut sera utiliser."
        ],
      },
    },
    "next_frequency": "Prochaine fréquence",
    "account_balance": "Solde de votre compte : {amount}€"
  },

  "credit": {
    "created_at": "Date de création :",
    "start_date": "Date de début :",
    "expiration_date": "Date de fin :",
    "credit_date": "Date de l'avoir :",
    "reference_period": "Période de référence :",
    "referring_invoice": "Facture de référence :",
    "number": "Avoir N° ",
    "remaining_amount": "Montant restant",
    "modal": {
      "title": "Création d'un avoir ({invoice})",
      "period": "Période concernée",
      "header1": "Item",
      "header2": "Quantité",
      "header3": "Montant",
      "header4": "Action",
      "placeholder1": "Prix",
      "placeholder2": "Quantité",
      "footer": {
        "amount_allowed": "Montant disponible HT :",
        "amount_excluding_vat": "Total HT :",
        "amount_including_vat": "Total TTC :",
        "amount_vat": "TVA :",
        "amount": "Montant HT :",
        "keep_old_values": "Avoir global avec montants d'origine",
      },
    },
  },

  "payment_method": {
    "waiting": {
      "message_less_than_hour": "Votre paiement est en attente merci de patienter pendant ",
      "message": "Votre paiement est toujours en attente, vous pouvez l'annuler si vous le souhaitez.",
      "link": "Créer un ticket"
    },
    "card": {
      "empty": "Aucun moyens de paiement, ajoutez en un pour continuer.",
      "existing": "Moyens de paiement existants ",
      "numbers": "Numéro de la carte",
      "expiration_date": "Date d'expiration (MM/AAAA)",
      "full_name": "Nom complet",
      "reference": "Référence",
      "default": "Par défaut",
      "default_ribbon": "Défaut",
      "remove": "Supprimer",
      "expired": "Expirée",
      "iban": "IBAN : ",
      "default_method": 'Ce moyen de paiement est celui que vous utilisez par défaut. Il est utilisé pour tous vos abonnements.',
      "creation": {
        "title_sepa": "Information du nouvel IBAN",
        "add_sepa": "Ajouter un nouvel IBAN",
        "title": "Information de la nouvelle carte",
        "valid_card": "Ajouter",
        "valid_sepa": "Accepter et ajouter",
        "name_placeholder": "Nom complet",
        "email_placeholder": "Email",
        "add_card": "Ajouter une nouvelle carte",
        "cancel": "Annuler"
      },
      "invalid": "Invalide",
      "validate": "Valider",
      "expired_message": "Ce moyen de paiement est arrivé à expiration, vous pouvez le supprimer.",
      "invalid_message": "Ce moyen de paiement n'a pas été validé par la banque. Vous pouvez procéder à sa validation ou le supprimer.",
    },
    "sepa_mandate": "Par la transmission de ces informations bancaire et la confirmation du paiement, " +
      "vous autorisez (A) Titan datacenters France et Stripe, notre prestataire de services de paiement, " +
      "à envoyer des instructions à votre banque pour débiter votre compte, et (B) votre banque à " +
      "débiter votre compte conformément à ces instructions. Vous bénéficiez d’un droit à remboursement " +
      "par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. " +
      "Toute demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de " +
      "votre compte. Vos droits concernant le mandat ci-dessus sont expliqués dans un document que vous " +
      "pouvez obtenir auprès de votre banque.",
    "details": {
      "payment_period": "Pour la période du",
      "to": "au",
      "month": "mois",
      "monthly": "Mois",
      "quarterly": "Trimestre",
      "biannually": "Semestre",
      "annually": "ns",
      "initial_amount": "Montant HT :",
      "initial_amount_monthly": "Montant HT/Mois :",
      "discount": "Remise :",
      "net_amount": "Total HT :",
      "vat": "TVA :",
      "total_amount": "Total TTC :",
      "total_amount_monthly": "Total TTC/Mois :",
      "amount_due": "Montant dû :",
      "amount_paid": "Montant payé :",
      "remaining_amount": "Montant restant TTC :",
      "amount_too_low": "Cette somme est trop faible pour être prélevée directement, la facture sera réglée automatiquement lors du renouvellement de votre abonnement.",
      "amount_too_low_accept": "J'accepte le prélèvement différé",
      "non_recurring_amount": "Montant HT non récurrent :",
      "recurring_amount": "Montant HT récurrent :"
    },
    "credit": {
      "list": "Liste des avoirs",
      "id": "ID de l'avoir",
      "credit": "Avoir",
      "allocated_amount": "Total TTC",
      "available_amount": "Solde",
      "provisional_amount": "Solde provisoire *",
      "provision_tips": "S'actualise lorsque vous le sélectionnez.",
      "use": "Avoir utilisé :",
      "expiration_date": "Expiration",
      "created_at": "Date de création"
    },
    "modal": {
      "select_preferred": {
        "title": "Changement du moyen de paiement par défaut",
        "warning": "Ce moyen de paiement sera utilisé pour tous vos abonnements.",
      },
      "confirm_payment": {
        "title": "Résumé de paiement :",
        "amount": "Montant TTC de la commande :",
        "method": "Moyen de paiement utilisé :",
        "cgv_confirm": "En validant ma commande, je déclare avoir pris connaissance et accepté sans réserve : les CGV et la politique de confidentialité",
        "credit": "Avoir utilisé :",
        "cgv_see": "Voir les CGV",
        "sepa_info_title": "Informations SEPA",
        "sepa_info": "Le délai de validation d'un virement SEPA se situe généralement entre 7 et 15 jours ouvrés. " +
          "Votre prestation ne sera livrée qu'après la validation complète du virement. " +
          "Si vous le souhaitez, vous pouvez effectuer ce règlement par carte afin d'obtenir une livraison immédiate de votre prestation.",
        "offline": "Paiement hors session",
      }
    },
    "state": {
      "failed": "Le paiement a échoué",
      "action": "Paiement en attente d'action.",
      "action-detail": "Cliquez sur confirmer pour finaliser votre paiement.",
      "pending": "Paiement en cours de traitement",
      "success": "Le paiement a réussi, merci.",
      "processing": "Le paiement est en cours de traitement par la banque.",
      "failed-comment": [
        'Votre commande a été sauvegardée.',
        'Vous pouvez la retrouver dans la rubrique "Société" onglet "Documents"',
      ],
    },
    "validation": {
      "failed": "La validation de votre moyen de paiement a échoué",
      "action": "Action requise de votre part.",
      "action-detail": "Cliquez sur confirmer pour finaliser la validation.",
      "pending": "Validation du moyen de paiement en cours de traitement",
      "success": "Votre moyen de paiement est validé",
      "processing": "La validation est en cours de traitement par la banque.",
      "failed-comment": [
        'Votre moyen de paiement est toujours présent.',
        'Vous pouvez le retrouver dans la rubrique "Société" onglet "Moyens de paiement"',
      ],
    },
    "payment_state": {
      "success": "Réussi",
      "error": "Erreur",
      "waiting": "En attente",
      "canceled": "Annulé",
      "processing": "En cours de traitement"
    },
    "list": {
      "id": "#",
      "exec_date": "Date",
      "state": "État",
      "type": "Type",
      "methodID": "ID",
      "card": "Carte",
      "credit": "Avoir",
      "amount": "Montant",
      "psepa": "PSEPA",
      "vsepa": "VSEPA",
      "cancel_success": "Votre paiement a été annulé avec succès."
    },
    "promotional_code": {
      "invalid": "Code promotionnel invalide.",
      "valid": "Code promotionnel appliqué",
      "valid_cant_apply": "Code promotionnel valide mais il ne peut pas être appliqué sur votre commande",
      "amount": "Code promotionnel:",
    }
  },
  "image": {
    "modal": {
      "title": "Modification de l'espace de stockage",
      "description": "Vous pouvez ici, augmenter ou diminuer votre espace total de stockage. Vous utilisez actuellement : ",
      "downgrade": "Vous êtes en train de retirer du stockage, la diminution de votre espace de stockage sera réalisée immédiatement après validation.",
      "warning": "Cette action est importante",
      "current": "Espace de stockage actuel",
      "future": "Espace de stockage après modification",
      "update": "Modification",
      "unit-disk": "Go",
      "price-recuring-final": "* Ce montant s'ajoutera à la facturation périodique de votre espace de stockage"
    },
    "edit": "Editer le quotas",
    "quotas": "Utilisation du stockage",
    "template-origin": "Template d'origine: ",
    "empty": "Aucune image…",
    "diskSize": "Taille du disque",
    "state": "Etat",
    "name": "Nom",
    "date": "Date de création",
    "more-info": "Plus d'informations",
    "less-info": "Moins d'informations",
    "states": {
      "created": "Créée",
      "creating": "En création",
      "error": "Erreur",
      "deleted": "Supprimée",
      "deleting": "Suppression en cours"
    }
  },
  "server": {
    "select": "Sélectionnez un ou plusieurs serveurs",
    "user-name": "Nom d'utilisateur ",
    "from": "Depuis ",
    "has-notification": "Ce serveur requiert votre attention",
    "server-empty": "Aucun serveur…",
    "more-info": "Plus d'informations",
    "less-info": "Moins d'informations",
    "_": "Serveur",
    "plan": "Plan",
    "name": "Nom",
    "state": "État",
    "uuid": "UUID",
    "oid": "OID",
    "ram": "RAM",
    "ip-protocol": "Protocole",
    "ipv4": "IPV4",
    "ipv6": "IPV6",
    "ip": "IP",
    "os": "OS",
    "mac": "MAC",
    "cpu": "CPU",
    "vcpu": "vCPU",
    "disk": "Disque",
    "network": "Réseau",
    "bandwidth": "Bande passante",
    "disk-qos": "QoS disque",
    "delete-network": "Supprimer ce Vswitch",
    "reverse": "Reverse",
    "hypervisor": "Hyperviseur",
    "ssh": "SSH",
    "license": "Licence",
    "kvmip": "KVM IP",
    "deleted_at": "Date de suppression",
    "gateway": "Gateway",
    "private-network": "Réseau privé",
    "ssh-keys": "Clés SSH",
    "hostname": "Nom de l'hôte",
    "company": "Société",
    "termination": "Résiliation planifiée le",
    "created_at": "Création",
    "ctrl-alt-del": "Ctrl-Alt-Supp",
    "demo": "Démo",
    "disabled": "Serveur désactivé",
    "upgrade": "Ajouter des addons",
    "downgrade": "Supprimer des addons",
    "pay": "Paiement",
    "processor": "Processeur : ",
    "ram-capacity": "RAM : ",
    "disk-space": "Espace disque : ",
    "metric_not_available": "Statistiques indisponible.",
    "outsourcing": "Infogérance",
    "no-outsourcing": "Aucune infogérance",
    "drp": "P.R.A.",
    "drp-pending": "P.R.A. en cours d'activation",
    "default-reverse": "Default reverse",
    "forced-hypervisor": "Hyp. forcé",
    "no-drp": "Pas de P.R.A",
    "drp-activated": "P.R.A. activé",
    "drp-syncing": "Synchronisation",
    "drp-fail": "P.R.A. en erreur",
    "drp-info": {
      "title": "Informations P.R.A.",
      "last-sync": "Mise à jour",
    },
    "actions": {
      "start": "Démarrer",
      "stop": "Arrêter",
      "reboot": "Redémarrer",
      "hardstop": "Arrêt forcé",
      "resume": "Reprendre",
      "reset": "Réinitialiser",
      "terminate": "Résilier",
      "reset-network": "Reset Network",
      "cancel-terminate": "Annuler résiliation",
      "activate-drp": "Activer le PRA",
      "deactivate-drp": "Désactiver le PRA",
      "schedule-reboot": "Redémarrage programmé",
    },
    "events": {
      "snapshot": "Snapshot",
      "network": "Réseau privé",
      "ipkvm": "KVM",
      "changestate": "Serveur",
      "servercreation": "Création",
      "serverdeletion": "Suppression",
      "serverupdate": "Mise à jour du champ",
      "serveraddons": "Addon(s)",
      "migration": "Migration d'hyperviseur",
      "serveroperations": "Opération serveur :",
      "storageupdate": "Mise à jour"
    },
    "states": {
      "unknow": "Provisionnement",
      "createserverimage": "Image créée",
      "starting": "Démarrage",
      "started": "Démarré",
      "suspending": "Suspension",
      "suspended": "Suspendu",
      "stopping": "En cours d'arrêt",
      "stopped": "Arrêté",
      "reseting": "Réinitialisation en cours",
      "resuming": "Reprise",
      "resumed": "Repris",
      "upgrading": "Mise à jour",
      "disabling": "Désactivation",
      "disabled": "Désactivé",
      "enabling": "Activation",
      "enabled": "Activé",
      "creating": "Création",
      "created": "Créé",
      "deleting": "Suppression",
      "deleted": "Supprimé",
      "rebooting": "Redémarrage",
      "restoring": "Restauration",
      "restored": "Restauré",
      "terminated": "Terminé",
      "diskconfigured": "Disque configuré",
      "diskconfiguration": "Configuration du disque",
      "diskcreated": "Disque créé",
      "diskcreation": "Création du disque",
      "diskdeleted": "Disque supprimé",
      "diskdeletion": "Suppression du disque",
      "configurationcreation": "Création de la configuration",
      "configurationcreated": "Configuration créée",
      "configurationdeletion": "Suppression de la configuration",
      "configurationdeleted": "Configuration supprimée",
      "attach-ip": "Attacher l'ip",
      "detach-ip": "Détacher l'ip",
      "attach": "Attacher",
      "attached": "Attaché",
      "detach": "Détacher",
      "detached": "Détaché",
      "install": "Terminer",
      "installed": "Installé(s)",
      "uninstalled": "Désinstallé(s)",
      "unpaid": "En attente de paiement",
      "load-iso": "Montage ISO",
      "loadiso": "Montage ISO",
      "unload-iso": "Démontage ISO",
      "unloadiso": "Démontage ISO",
      "up": "Up",
    },
    "requests": {
      "title": "Action demandée ",
      "action": "Action ",
      "user": "Utilisateur ",
      "ip": "IP ",
      "create": "Création",
      "delete": "Suppression",
      "stop": "Arrêt",
      "start": "Démarrage",
      "reboot": "Redémarrage",
      "restore": "Restauration",
      "refresh": "Actualiser ",
      "hardstop": "Arrêt forcé",
      "reset": "Réinitialisation",
      "terminate": "Résiliation",
      "attach": "Attacher",
      "attached": "Attaché",
      "detach": "Détacher",
      "detached": "Détaché",
      "install": "Installer",
      "installed": "Installé",
      "rename": "Renommage",
      "decrease": "Diminution",
      "increase": "Augmentation",
      "update": "Mise à jour"
    },
    "status": {
      "title": "État :",
      "success": "succès",
      "error": "échec",
    },
    "entities": {
      "changestate": "Serveur",
      "serverupdate": "Serveur",
      "serverdeletion": "Serveur",
      "servercreation": "Création",
      "serveraddons": "Addons",
      "serverreset": "Serveur",
      "ipkvm": "KVM IP",
      "snapshot": "Snapshot",
      "network": "Réseau privé",
      "storageupdate": "Storage pool"
    },
    "pending-actions": {
      "key": "Action(s) en attente d'un redémarrage : ",
      "info": "Vous devez redémarrer obligatoirement via l'interface web",
      "addons": "installation d'addon(s)",
      "load_iso": "montage ISO",
      "unload_iso": "démontage ISO",
      "notification": "mise à jour",
    },
    "notifications": {
      "addons": "Veuillez effectuer un redémarrage de votre serveur depuis l'interface pour que les addons soient pris en compte.",
      "mount_iso": "Veuillez effectuer un redémarrage de votre serveur depuis l'interface et lancer votre accès distant.",
      "plesk": "Votre licence Plesk a été modifiée. Vous trouverez le nouveau code d'activation dans les informations détaillées de la licence.",
      "user_agreement": "Ne plus afficher ce message",
    },
    "server-page": "Page serveur",
    "no-addons": "Action impossible. La facture de ce serveur est impayée.",
    "gauge": {
      "menu": {
        "title": "Modèles",
        "model": {
          "bar": "Barre",
          "arc": "Arc",
          "circle": "Cercle",
          "stroke": "Traits",
        },
        "change-model": "Changer de modèle",
      },
      "save": {
        "button": {
          "save-server": "Préférences serveur",
          "save-user": "Préférences utilisateur",
        },
        "tooltip": {
          "save-server": "Garder en tant que préférences pour ce serveur",
          "save-user": "Garder en tant que préférences par défaut",
        },
        "message": {
          "successful": "Sauvegarde réussie",
          "unsuccessful": "Echec de sauvegarde",
        },
      },
    },
    "chart": {
      "activity": "Activité {type} ({unit})",
      "tooltip": [
        "Zoom avant",
        "Zoom arrière",
        "Raffraîchir",
        "Ne pas remplir la zone graphique",
        "Remplir la zone graphique",
      ],
    },
    "tooltips": {
      "delete": "Suppression immédiate du serveur",
      "show-company": "Voir les informations de la compagnie",
    },
    "drp-text": [
      "En raison de la grande popularité de notre Plan de Reprise d'Activité (PRA), nous regrettons de vous informer que son activation est momentanément indisponible.",
      "Nous vous prions de bien vouloir réessayer ultérieurement ou de contacter notre service de support technique pour obtenir une assistance immédiate.",
      "Nous nous excusons pour tout inconvénient que cela pourrait causer et vous remercions de votre compréhension.",
      "Cordialement,",
      "L'équipe Titan SmallCloud",
      "+33(0)9 88 99 88 20",
    ],
    "alert-text": [
      "Cette fonctionnalité est momentanément hors service.",
      "Nous travaillons activement à sa remise en fonction dans les plus brefs délais.",
      "Nous nous excusons pour tout inconvénient que cela pourrait causer et vous remercions de votre compréhension.",
      "Cordialement,",
      "L'équipe Titan SmallCloud",
      "+33(0)9 88 99 88 20",
    ],
    "uptime": {
      "title": "Disponibilité",
      "down": "Off",
      "up": "On",
      "pending": "En attente",
      "checkEverySecond": "Contrôle toutes les {0} secondes",
      "days": "j",
      "hours": "h",
      "latency": "Temps de réponse",
    },
    "managed-service": {
      "title": "Niveaux d'infogérance",
      "add": "Ajouter une infogérance",
      "update": "Changer le niveau d'infogérance",
      "terminate": "Résilier l'infogérance",
      "goto-server": "Voir le serveur",
    },
    "drp-modal": {
      "header": [
        "Serveur(s)",
        "Prix",
      ],
      "total": "Coût total de votre P.R.A.",
      "prefix": "PRA_",
    },
    "license-info": {
      "button-info": "Informations détaillées",
      "button-change": "Changer",
      "title-change": "Changer de licence",
      "id": "Licence ID",
      "code": "Item",
      "key": "Code d'activation",
      "in-cart": "Licence déjà dans le panier",
      "empty": "Aucune licence",
      "plesk": {
        "description": "Description",
        "code": "Code",
        "server": "Serveur",
      },
    },
    "os-modification": {
      "popup": "Modifier l'OS",
    },
  },
  "event_log": {
    "requests": {
      "title": "Action demandée ",
      "action": "Action ",
      "user": "Utilisateur ",
      "server": "Serveur ",
      "old_event": "Ancien événement"
    },
    "type": {
      "network": "Réseau",
      "ip": "Adresse IP",
      "iso": "ISO",
      "configuration": "Configuration",
      "snapshot": "Snapshot",
      "note": "Note",
      "image": "Image",
      "server": "Serveur",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disque",
      "vswitch": "Vswitch",
      "addons": "Complément",
      "state": "Etat",
      "ipkvm": "KVM",
      "changestate": "Serveur - Changements",
      "servercreation": "Serveur - Création",
      "createserver": "Serveur - Création",
      "serverdeletion": "Serveur - Suppression",
      "serverupdate": "Serveur - Mise à jour",
      "serveraddons": "Serveur - Addon(s)",
      "migration": "Migration d'hyperviseur",
      "serveroperations": "Serveur - Opérations",
      "storageupdate": "Disque - Mise à jour",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Authentification",
      "material": "Matériel",
      "hypervisor": "Hyperviseur",
      "payment_method": "Méthode Paiement",
      "company": "Société",
      "user": "Utilisateur",
      "invitation": "Invitation",
      "role": "Rôle",
      "cron": "Tâche",
      "accounting_code": "Numéro de compte",
      "outsourcing": "Infogérance",
      "order": "Commande",
    },
    "subtype": {
      "network": "Réseau",
      "ip": "Adresse IP",
      "iso": "ISO",
      "configuration": "Configuration",
      "snapshot": "Snapshot",
      "note": "Note",
      "image": "Image",
      "server": "Serveur",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disque",
      "vswitch": "Vswitch",
      "addons": "Complément",
      "state": "Etat",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Authentification",
      "material": "Matériel",
      "hypervisor": "Hyperviseur",
      "payment_method": "Méthode Paiement",
      "company": "Société",
      "user": "Utilisateur",
      "invitation": "Invitation",
      "role": "Rôle",
      "cron": "Tâche",
      "accounting_code": "Numéro de compte"
    },
    "action": {
      "load": "Chargé",
      "unload": "Déchargé",
      "mount": "Monté",
      "unmount": "Démonté",
      "create": "Création",
      "creation": "Création",
      "created": "Créé",
      "attach": "Attachement",
      "attached": "Attaché",
      "detach": "Détachement",
      "detached": "Détaché",
      "start": "Démarrage",
      "started": "Démarré",
      "stop": "Arrêt",
      "stopping": "En cours d'arrêt",
      "stopped": "Arrêté",
      "resume": "Reprise",
      "resumed": "Relancé",
      "reset": "Réinitialisation",
      "increase": "Augmentation",
      "decrease": "Diminution",
      "update": "Mise à jour",
      "updated": "Mise à jour",
      "cancel": "Abandon",
      "delete": "Suppression",
      "deletion": "Suppression",
      "deleted": "Supprimé",
      "upgrade": "Upgrade",
      "upgraded": "Upgradé",
      "downgrade": "Downgrade",
      "downgraded": "Downgradé",
      "install": "Installation",
      "installed": "Installé",
      "uninstall": "Désinstallation",
      "uninstalled": "Désinstallé",
      "restore": "Restauration",
      "restored": "Restauré",
      "rename": "Renommage",
      "renamed": "Renommé",
      "configuration": "Configuration",
      "configured": "Configuré",
      "disable": "Désactivation",
      "disabled": "Désactivé",
      "enable": "Activation",
      "enabled": "Activé",
      "hardstop": "Arrêt forcé",
      "reboot": "Redémarage",
      "suspend": "Suspension",
      "revoked": "Révoqué",
      "activate": "Activation",
      "activated": "Activé",
      "deactivate": "Désactivation",
      "deactivated": "Désactivé",
      "termination": "Résiliation",
    },
    "status": {
      "title": "État :",
      "success": "succès",
      "error": "échec",
    },
    "messages": {
      "material_hypervisor_enable": "Hyperviseur activé",
      "material_hypervisor_disable": "Hyperviseur désactivé",
      "authentication_two_fa_enabled": "Authentification 2FA activée",
      "authentication_two_fa_disabled": "Authentification 2FA désactivée",
      "authentication_ssh_create": "Authentification SSH créée",
      "authentication_ssh_created": "Authentification SSH créée",
      "authentication_ssh_delete": "Authentification SSH supprimée",
      "authentication_ssh_deleted": "Authentification SSH supprimée",
      "authentication_ssh_update": "Authentification SSH mise à jour",
      "authentication_ssh_updated": "Authentification SSH mise à jour",
      "ip_attach": "Demande d'attachement IP",
      "ip_detach": "Demande de détachement IP",
      "ip_attached": "Adresse IP attachée",
      "ip_detached": "Adresse IP détachée",
      "ip_updated": "Adresse IP mise à jour",
      "ip_termination": "Demande de résiliation IP",
      "ip_cancel_termination": "Demande de résiliation IP annulée",
      "server_iso_mount": "Montage d'image",
      "server_iso_unmount": "Démontage d'image",
      "server_iso_mounted": "Image ISO serveur montée",
      "server_iso_unmounted": "Image ISO serveur démontée",
      "server_iso_create": "Image ISO serveur créée",
      "server_iso_deletion": "Image ISO serveur supprimée",
      "server_addons_upgrade": "Upgrade serveur",
      "server_addons_downgrade": "Downgrade serveur",
      "server_network_enabled": "Réseau serveur activé",
      "server_network_disabled": "Réseau serveur désactivé",
      "server_configuration_creation": "Création de la configuration serveur",
      "server_configuration_created": "Configuration serveur créée",
      "server_configuration_deletion": "Suppression de la configuration serveur",
      "server_configuration_deleted": "Configuration serveur supprimée",
      "server_storage_creation": "Création de l'espace disque",
      "server_storage_created": "Espace disque créé",
      "server_storage_configured": "Espace disque configuré",
      "server_storage_deletion": "Suppression de l'espace disque",
      "server_storage_deleted": "Espace disque supprimé",
      "server_termination": "Demande de résiliation",
      "server_cancel_termination": "Demande de résiliation annulée",
      "server_state_started": "Serveur démarré",
      "server_state_stopped": "Serveur arrêté",
      "server_state_resumed": "Serveur relancé",
      "server_state_stopping": "Serveur en cours d'arrêt",
      "server_start": "Démarrage du serveur",
      "server_stop": "Arrêt du serveur",
      "server_update": "Serveur mis à jour",
      "server_updated": "Serveur mis à jour",
      "server_renamed": "Serveur renommé",
      "server_create": "Serveur créé",
      "server_started": "Serveur démarré",
      "server_stopped": "Serveur arrêté",
      "server_resumed": "Serveur relancé",
      "server_stopping": "Serveur en cours d'arrêt",
      "server_hardstop": "Arrêt forcé du serveur",
      "server_reboot": "Redémarrage du serveur",
      "storage_snapshot_create": "Création d'un snapshot",
      "storage_snapshot_restore": "Restauration d'un snapshot",
      "storage_snapshot_deletion": "Suppression d'un snapshot",
      "storage_snapshot_created": "Snapshot créé",
      "storage_snapshot_restored": "Snapshot restauré",
      "storage_snapshot_deleted": "Snapshot supprimé",
      "storage_snapshot_updated": "Snapshot mis à jour",
      "storage_image_create": "Création d'une image",
      "storage_image_delete": "Suppression d'une image",
      "storage_image_created": "Image créée",
      "storage_image_deleted": "Image supprimée",
      "storage_image_updated": "Image mise à jour",
      "storage_decreased": "Espace disque diminué",
      "storage_increased": "Espace disque augmenté",
      "note_updated": "Note mise à jour",
      "console_kvmip_start": "Démarrage de la console IpKvm",
      "console_kvmip_stop": "Arrêt de la console IpKvm",
      "console_kvmip_update": "Console IpKvm mise à jour",
      "console_kvmip_started": "Console IpKvm démarrée",
      "console_kvmip_stopped": "Console IpKvm arrêtée",
      "console_kvmip_stopping": "Console IpKvm en cours d'arrêt",
      "network_vswitch_attach": "Attachement du Vswitch au réseau",
      "network_vswitch_detach": "Détachement du Vswitch du réseau",
      "network_vswitch_attached": "Vswitch attaché au réseau",
      "network_vswitch_detached": "Vswitch détaché du réseau",
      "network_vswitch_create": "Création d'un Vswitch",
      "network_vswitch_created": "Vswitch créé",
      "network_vswitch_delete": "Suppression d'un Vswitch",
      "network_vswitch_deleted": "Vswitch supprimé",
      "network_vswitch_update": "Mise à jour du Vswitch",
      "network_vswitch_updated": "Vswitch mis à jour",
      "payment_method_created": "Méthode de paiement ajoutée",
      "createserver": "Création du serveur",
      "metric_cpu_alert": "Activité CPU",
      "metric_ram_alert": "Activité RAM",
      "metric_disk_alert": "Activité Disque",
      "metric_net_alert": "Activité Réseau",
      "company_created": "Création de la société",
      "company_update": "Mise à jour des informations société",
      "company_updated": "Informations société mises à jour",
      "company_invitation_create": "Création d'une invitation",
      "company_invitation_created": "Envoi d'une invitation",
      "company_invitation_delete": "Suppression d'une invitation",
      "company_invitation_deleted": "Invitation supprimée",
      "company_user_revoke": "Révocation d'un utilisateur",
      "company_user_revoked": "Utilisateur révoqué",
      "company_user_delete": "Suppression d'un utilisateur",
      "company_user_deleted": "Utilisateur supprimé",
      "company_user_join": "Essai de rejoindre la société",
      "company_user_joined": "L'utilisateur a rejoint la société",
      "company_role_create": "Création d'un rôle",
      "company_role_created": "Rôle créé",
      "company_role_delete": "Suppression d'un rôle",
      "company_role_deleted": "Rôle supprimé",
      "company_role_update": "Mise à jour d'un rôle",
      "company_role_updated": "Rôle mis à jour",
      "cron_reboot_create": "Programmation d'une tâche de redémarrage",
      "cron_reboot_created": "Tâche de redémarrage programmée",
      "cron_reboot_delete": "Suppression d'une tâche de redémarrage",
      "cron_reboot_deleted": "Tâche de redémarrage supprimée",
      "cron_reboot_update": "Mise à jour d'une tâche de redémarrage",
      "cron_reboot_updated": "Tâche de redémarrage mise à jour",
      "cron_reboot_activate": "Activation d'une tâche de redémarrage",
      "cron_reboot_activated": "Tâche de redémarrage activée",
      "cron_reboot_deactivate": "Désactivation d'une tâche de redémarrage",
      "cron_reboot_deactivated": "Tâche de redémarrage désactivée",
      "cron_snapshot_create": "Programmation d'une tâche snapshot",
      "cron_snapshot_created": "Tâche snapshot programmée",
      "cron_snapshot_delete": "Suppression d'une tâche snapshot",
      "cron_snapshot_deleted": "Tâche snapshot supprimée",
      "cron_snapshot_update": "Mise à jour d'une tâche snapshot",
      "cron_snapshot_updated": "Tâche snapshot mise à jour",
      "cron_snapshot_activate": "Activation d'une tâche snapshot",
      "cron_snapshot_activated": "Tâche snapshot activée",
      "cron_snapshot_deactivate": "Désactivation d'une tâche snapshot",
      "cron_snapshot_deactivated": "Tâche snapshot désactivée",
      "accounting_code_create": "Création numéro de compte",
      "accounting_code_created": "Numéro de compte créé",
      "accounting_code_delete": "Suppression numéro de compte",
      "accounting_code_deleted": "Numéro de compte supprimé",
      "api_token_create": "Création d'une clé API",
      "api_token_created": "Clé API créée",
      "api_token_delete": "Suppression d'une clé API",
      "api_token_deleted": "Clé API supprimée",
      "api_token_update": "Mise à jour d'une clé API",
      "api_token_updated": "Clé API mise à jour",
      "outsourcing_termination": "Demande de résiliation de l'infogérance",
      "outsourcing_cancel_termination": "Demande de résiliation de l'infogérance supprimée",
      "outsourcing_deleted_termination": "Infogérance résiliée",
      "vswitch_ports_increase": "Ports ajoutés au VSwitch",
      "order_deletion": "Suppression de la commande",
    },
  },
  "intervention": {
    "title": "Prestations"
  },
  "pages": {
    "devis": {
      "title": "Devis",
      "number": "Numéro",
      "commercial": "Commercial ",
      "created_at": "Créé le",
      "expire_at": "Expire le",
      "amount": "Montant TTC",
      "tooltip-download": "Télécharger le pdf",
      "tooltip-cart": "Ajouter au panier",
      "empty": "Aucun devis pour le moment.",
      "tooltip-accept": "Accepter le devis",
      "validate_at": "Accepté le ",
      "accept": "Accepter",
      "invoice_number": "Document ",
      "details": "N° de devis ",
      "quote_date": "Date du devis ",
      "quote_validity": "Valable jusqu'au ",
      "create_quote": "Créer un devis",
      "company": "Société",
      "tooltip-delete": "Supprimer le devis",
    },
    "lang": "fr",
    "ip-histo": {
      "empty": "L'historique est vide.",
      "company_uuid": "Compagnie",
      "server_uuid": "Serveur",
      "start_date": "Date de début",
      "end_date": "Date de fin"
    },
    "login": {
      "title": "Se connecter",
      "email-label": "E-mail",
      "password-label": "Mot de passe",
      "login-action": "Se connecter",
      "email-not-validated": "Merci de valider votre e-mail avant de vous connecter",
      "email-confirmation-sent": "Un email de confirmation vous a été envoyé (merci de vérifier également dans vos spams).",
      "promotional_code_saved": "Le code promotionnel a été sauvegardé."
    },
    "login2fa": {
      "title": "Authentification à double facteur",
      "tfa-label": "Code de sécurité"
    },
    "register": {
      "company_invite_title": "Bienvenue sur Titan SmallCloud ! Afin de finaliser votre compte, merci de nous fournir quelques informations vous concernant. Ces informations resteront confidentielles et vous pouvez nous solliciter à tout moment pour demander leur suppression.",
      "title": "Pour déployer vos instances et vous fournir le meilleur service, nous avons besoin d'informations vous concernant. Ces informations resteront confidentielles et vous pouvez nous solliciter à tout moment pour demander leur suppression.",
      "action": "Créer un compte",
      "billing-address": "Adresse de facturation",
      "modal": {
        "title": "Vous avez modifié les informations préalablement remplies.",
        "danger": "",
        "message": "",
        "button": "Confirmer",
        "extra": "Confirmez vous ces modifications ?",
      },
    },
    "dashboard": {
      "status-map": "Status map TITAN",
      "status-page": "Statuts",
      "favorite": "Vos favoris",
      "notifications": "Notifications",
      "overview-server": "Mes serveurs",
      "overview-resource": "Mes ressources",
      "status": {
        "bandwidth": "Bande passante",
        "v4-peers-visibility": "Visibilité des pairs v4",
        "v6-peers-visibility": "Visibilité des pairs v6",
        "ping-latency": "Latence"
      },
      "weather-cpu-ram": "CPU / RAM",
      "weather-public-network": "Réseau public (WAN)",
      "weather-private-network": "Réseau privé (LAN)",
      "weather-storage": "Stockage",
      "pending_servers": "Serveurs en attente de configuration",
      "last_op": "Dernières opérations",
      "title-tickets": "Vos derniers tickets",
      "title-history": "Vos dernières actions",
      "weather-ras": "OK",
      "weather-degraded": "Dégradé",
      "weather-failure": "Critique",
      "started-server": "Serveurs démarrés : ",
      "stopped-server": "Serveurs arrêtés : ",
      "other-server": "Serveurs en attente : ",
      "ipv4-active": "IPv4 actives : ",
      "ipv4-free": "IPv4 disponibles : ",
      "ipv6-active": "IPv6 actives : ",
      "ipv6-free": "IPv6 disponibles : ",
      "disk-space": "Stockage disponible : ",
      "disk-used": "Stockage utilisé : ",
      "modal": {
        "you_will_be_redirected_to": "Vous allez être redirigé vers",
        "status_link": "https://titandc.statuspage.io/",
      },
      "popup": [
        "Statut Titan Datacacenter"
      ],
    },
    "servers": {
      "mac": "adresse MAC",
      "plan": {
        "SC1": "SC1",
        "SC2": "SC2",
        "SC3": "SC3"
      }
    },
    "server-new": {
      "devis-mode": {
        "false": "Activer le mode génération d'un devis",
        "true": "Désactiver le mode génération d'un devis",
      },
      "title": "Configurez votre serveur",
      "choose-managed": "Choisissez votre mode de gestion réseau",
      "choose-plan": "Choisissez vos ressources",
      "choose-os": "Choisissez votre système d'exploitation",
      "setup-ssh": "Configurer la connexion de votre serveur",
      "licenses": "Licences",
      "bandwidth": "Bande passante",
      "managed-true": "Réseau managé",
      "managed-false": "Réseau dédié",
      "managed-true-text": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.",
      "managed-false-text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "iops": "QoS disque : ",
      "ssh-password": "Connexion par mot de passe",
      "ssh-password-input": "Entrez un mot de passe",
      "ssh-key": "Connexion par clé SSH",
      "ssh-key-input": "Entrez votre clé afin de vous connecter en ",
      "ssh-key-public-msg": "Vous devez être connecté pour utiliser des clés SSH.",
      "ssh-key-hint": "Entrer votre clé publique SSH.",
      "ssh-key-placeholder": "Clé publique",
      "confirm": "Confirmation",
      "finalize": "Finaliser la commande",
      "order-plan": "Commande d'un serveur ",
      "order-with": "avec le système d'exploitation",
      "order-preinstalled": "préinstallé",
      "order-button": "Commander pour ",
      "month": "mois HT",
      "duty-free": "HT*",
      "recurring_description": "* Ce montant s’ajoutera à la facturation périodique de votre serveur",
      "tooltip": "Entrez un mot de passe ou clé SSH",
      "tooltip_password": "Entrez un mot de passe",
      "tooltip_windows": "Windows est disponible à partir des SC2.",
      "tooltip_windows_to_windows": "Windows peut migrer uniquement vers Window.",
      "tooltip_license": "Vous ne pouvez changer de type de license.",
      "tooltip_add": "Ajouter au panier",
      "tooltip_packetfence": "PacketFence n'est disponible que sur une SC3.",
      "order-count": "Vous n‘avez aucune commande… | Vous avez une commande dans le panier… | Vous avez {nb} commandes dans le panier…",
      "order-added": "Commande ajoutée…",
      "order-cart": "Voir le panier",
      "order-reset": "Vider le panier",
      "version-placeholder": "Version",
      "plesk-title": "Choix des licences plesk",
      "plesk-selection": "Sélectionnez votre licence :",
      "unit-price": "Prix unitaire :",
      "os": "Système d'exploitation",
      "qty": "QTé. :",
      "pwd_hint": "Choisissez un mot de passe et / ou une clé SSH",
      "pwd_and_ssh": "Choisissez une clé SSH et / ou un mot de passe",
      "cpu-desc": " ",
      "ram-desc": " ",
      "disk-desc": " ",
      "next-step-btn": "Prochaine étape",
      "promo_code": "Code de réduction",
      "unit": {
        "from_to_gb": "{ from } Go à { to } Go",
        "from_to_cpu": "{ from } vCPU à { to } vCPU",
      },
      "update-os": "Nouvel OS",
    },
    "server-detail": {
      "ips": "Adresses IP",
      "note": "Note",
      "note-edit": "Éditer la note",
      "note-save": "Enregistrer la note",
      "note-help": "Vous pouvez éditer la note avec Markdown (aide en cliquant ici)",
      "note-default": `Ce serveur a été installé avec **{template}** le {creation_date}`,
      "note-empty": "Aucune note",
      "activity": "Activité",
      "activity-empty": "Pas encore d'activité.",
      "graph-reset": "Réinitialiser le graphique",
      "network-activity": "Activité réseau",
      "ram-activity": "Activité RAM",
      "cpu-activity": "Activité CPU",
      "disk-activity": "Espace disque",
      "Vswitch-activity": "Activité Vswitch",
      "history": "Historique",
      "last-activity": "Dernières activités récentes",
      "server-history": "Historique du serveur",
      "no_ip": "Aucune adresse IP",
      "snapshots": "Snapshots",
      "snapshot-create": "Créer un snapshot",
      "snapshot-restore": "Restaurer",
      "snapshot-delete": "Supprimer",
      "snapshot-to-template": "Image",
      "snapshot-to-template-modal": {
        "title": "Génération d'une image depuis ce snapshot",
        "name": "Nom de l'image"
      },
      "snapshot-empty": "Aucun snapshot…",
      "snapshot-error": "Le snapshot a échoué",
      "snapshot-schedule": "Programmer un snapshot",
      "terminate-success": "Résiliation du serveur en cours.",
      "terminate-canceled": "Résiliation du serveur annulée.",
      "modal-server-downgrade-nothing": "Aucun addon à downgrade.",
      "modal-need-stop-title": "Réinitialisation du serveur",
      "modal-need-stop-text": "Arrêt du serveur requis.",
      "modal-need-stop-description": "Vous devez arrêter votre serveur pour effectuer cette action.",
      "modal-need-stopped-description": "Merci d'attendre l'arrêt de votre serveur pour effectuer cette action.",
      "modal-server-upgrade-description": "L'ajout d'addons à votre machine ne sera réalisé qu'après un redémarrage du serveur depuis votre dashboard.",
      "modal-mount-cdrom-title": "Montage CD-ROM",
      "modal-mount-cdrom-text": "Un CD-ROM peut être chargé sur le serveur sous la forme d'un fichier ISO distant. Le serveur démarrera alors en priorité sur le CD-ROM jusqu'à que ce dernier soit démonté (redémarrage requis). La configuration de votre ISO se réalise à partir du KVM.",
      "modal-mount-cdrom-label": "URL de l‘ISO bootable à charger (https uniquement) :",
      "modal-mount-cdrom-list-label": "Liste des ISOs prédéfinis",
      "modal-mount-cdrom-list-placeholder": "— Sélectionner un ISO —",
      "modal-unmount-cdrom-title": "Démontage CD-ROM",
      "modal-unmount-cdrom-text": "Cette action démontera le CD-ROM précédemment chargé. Si la machine est actuellement démarrée à partir du CD-ROM alors un arrêt forcé de la machine devra être effectué pour que le démontage soit effectif.",
      "modal-server-downgrade-description": "La suppression d'addons sera réalisée immédiatement après validation.",
      "see-all-server": "Afficher les serveurs",
      "terminate-descriptions-title": "Quelles est la raison de cette résiliation ?",
      "terminate-descriptions-placeholder": "— Raisons —",
      "terminate-descriptions": {
        "over": "Le projet associé à ce produit est terminé.",
        "unsuitable": "Le produit est inadapté à mes besoins.",
        "quality": "La qualité de service (pannes, incidents, etc.) ne répond pas à mes attentes.",
        "assistance": "L'assistance en ligne (service client, FAQ, support, etc.) ne me permet pas de gérer convenablement mes services.",
        "expensive": "Les prix sont trop élevés.",
        "loyalty": "Le manque de reconnaissance (programme de fidélité, prix dégressifs, etc.).",
        "mistake": "Erreur lors de ma commande (mauvaises ressources, double commande, etc.).",
      },
      "goto_images": "Mes images",
      "modal-addon-exists": "Un upgrade est déjà ajouté au panier",
      "modal-actual-addon": "(Actuel { quantity } { type })",
      "modal-none": "Aucun",
      "addon-added": "Addons ajoutés au panier",
      "modal-no-ip": [
        "Résiliation du serveur impossible",
        "La résiliation n'est possible que si le serveur a au moins une adresse IPv4 attachée.",
        "Cette IPv4 doit avoir une IPv6 correspondante non attachée à un autre serveur et faisant partie de votre pool d'adresses.",
        "Veuillez aller dans votre interface réseau et attacher une adresse IPv4 à ce serveur.",
        "Merci.",
      ],
    },
    "faq": {
      "title": "FAQ",
      "updated": "Mise à jour"
    },
    "payment-list": {
      "create-payment": "Créer un paiement",
      "date": "Date",
      "number": "N° du paiement",
      "ref": "Référence",
      "facture": "Factures rattachées",
      "mode": "Réglé par",
      "total-amount": "Montant",
      "charged-amount": "Montant imputé",
      "unused-amount": "Montant inutilisé",
      "client_code": "Code client",
      "client": "Client",
      "no_payment": "Aucun règlement trouvé",
      "modes": {
        'VSEPA': "Virement",
        'PSEPA': "Prélèvement",
        'CB': "CB",
        'CREDIT': "Crédit",
        'UNPAID': "Impayé",
      },
      "states": {
        'title': "Statut",
        'waiting': "En attente",
        'processing': "En cours",
        'succeeded': "Réussi",
        'success': "Réussi",
        'error': "Erreur",
        'canceled': "Annulé",
        'requires_action': "Action",
      },
      "date-range": {
        'month': 'Mois',
        'all': 'Tous',
        'last-month': 'Mois dernier',
        'last-quarter': 'Trimestre dernier',
        'format': 'dd/MM/yyyy',
        'apply': 'Appliquer',
        'cancel': 'Annuler',
        'weekLabel': 'S',
        'customRange': 'Personnalisé',
        'daysOfWeek': ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        'monthNames': ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec'],
        'monthNamesFull': ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      }
    },
    "account": {
      "vat": "N° de TVA",
      "title": "Paramètres du compte",
      "personal_infos": "Informations personnelles",
      "civility": "Civilité",
      "civility-mr": "Monsieur",
      "civility-mme": "Madame",
      "civility-mx": "Mx",
      "first-name": "Prénom",
      "last-name": "Nom",
      "name": "Nom",
      "email": "Email",
      "phone": "Téléphone",
      "phone-country": "Code Pays",
      "phone-country-error": "Choisir un pays",
      "phone-ex": "Exemple :",
      "mobile": "Mobile",
      "add-phone": "Ajouter un numéro de téléphone",
      "save": "Enregistrer",
      "languages": "Langue",
      "interface-mode": "Couleur d'interface",
      "current-password": "Mot de passe actuel",
      "new-password": "Nouveau mot de passe",
      "confirm-new-password": "Confirmer le nouveau mot de passe",
      "password": "Mot de passe",
      "confirm-password": "Confirmer le mot de passe",
      "settings": "Préférences",
      "company_infos": "Informations société",
      "company-name": "Nom de la société",
      "company-position": "Titre",
      "company-id": "D-U-N-S",
      "tva-number": "N° de TVA intracommunautaire",
      "address": "Adresse",
      "address-name": "Nom",
      "address-street": "Rue",
      "address-postal-code": "Code postal",
      "address-city": "Ville",
      "address-country": "Pays",
      "add-address": "Ajouter une adresse",
      "password-mismatch": "Le mot de passe et sa confirmation ne correspondent pas",
      "recaptcha-error": "Vous devez valider le recaptcha.",
      "password-hint": "Votre mot de passe doit comporter 8 caractères (majuscule, minuscule, chiffre).",
      "legal-info": "En créant votre compte vous allez bénéficier des services Titan Datacenters France.\nLes données communiquées via ce formulaire sont collectées avec votre consentement et sont destinées à Titan Datacenters France en tant que responsables de traitement.\n\nCes informations et celles de vos commandes sont traitées principalement pour créer et gérer votre compte, gérer vos commandes et leur suivi, personnaliser vos services, la prévention de la fraude et analyses statistiques ainsi qu’à des fins de marketing et publicité ciblée.\nCes traitements sont fondés sur différentes bases légales en fonction des finalités concernées.\n\nÀ tout moment vous gardez la possibilité de retirer votre consentement.",
      "legal-notice": "Mentions légales",
      "privacy-policy": "Politique de confidentialité",
      "discount": "Type d'abonnement",
      "auto_debit": "Prélèvement en fin de mois :",
      "disabled": "Compagnie désactivée :",
      "renew_with_credits": "Utiliser les crédits lors des renouvellements d'abonnements :",
      "reset": "Remettre à zéro",
      "affiliate_infos": "Programme d'affiliation (optionnel)",
      "affiliate-code": "Code d'affiliation (optionnel)",
      "code": "Code",
      "status": "Statut",
      "balance": "Solde",
      "vatConfirmed": "N° TVA confirmé",
      "vatNotConfirmed": "N° TVA non encore confirmé",
      "modal": {
        "title": "Contact",
        "message_invalid": "Données invalides",
        "message_error": "Erreur lors de l'enregistrement",
        "message_success": "Contact mise à jour avec succès",
      },
      "send_invoice": "Envoi automatique des factures par email (Super Admin + Comptables)",
      "creation_date": "Créé le",
      "update_date": "Mis à jour le",
      "oid": "OID",
      "affiliation_code": "Code affiliation",
      "server-detail-closed": "Informations serveur fermées",
      "server-detail-help": "Dans la fiche serveur, laisse ouvert ou pas le panneau des informations détaillées",
      "create-new-company": "Créer une nouvelle société",
      "invoice_payment_grouped": "Paiement unique pour plusieurs factures",
      "sms_alert": "Autorisation d'envoi de SMS",
      "single_invoice": "Regrouper les abonnement sur une seule facture",
    },
    "account-ssh": {
      "title": "Clés SSH",
      "management": "Gérer mes clés SSH",
      "ssh-key-name": "Nom",
      "ssh-key-usage": "Nombre d'utilisations",
      "ssh-key-value": "Clé SSH",
      "ssh-key-add": "Ajouter une clé SSH"
    },
    "account-api": {
      "title": "Clés API",
      "api-keys": "Clés API",
      "api-key-name": "Nom",
      "api-key-token": "Clé API",
      "api-key-expire": "Date d'expiration",
      "api-key-add": "Générer une clé API"
    },
    "account-2fa": {
      "title": "Authentification à double facteur (2FA)",
      "actions": {
        "enable": "Activer la 2FA",
        "disable": "Désactiver la 2FA",
      },
      "enable": {
        "step1": {
          "title": "Sauvegardez la clé secrète de récupération",
          "text": "Veuillez noter soigneusement votre clé de récupération ci-dessous. Elle vous sera utile en cas de perte de votre téléphone par exemple."
        },
        "step2": {
          "title": "Scannez le QR code à l'aide d'une application de TOTP",
          "text": "Il vous suffit de scanner le QR code ci-dessous à l'aide de votre application de TOTP favorite (Authy, Google Authenticator, etc.)"
        },
        "step3": {
          "title": "Entrez le code d'autorisation à 6 chiffres",
          "text": "Veuillez renseigner ci-dessous le code à 6 chiffres qui apparaît sur votre application de TOTP (faites vite, les codes ont une durée de vie limitée). "
        },
        "step4": {
          "title": "Scannez le QR code à l'aide d'une application de TOTP",
          "text": "Il vous suffit de scanner le QR code ci-dessous à l'aide de votre application de TOTP favorite (Authy, Google Authenticator, etc.)"
        }
      },
      "disable": {
        "title": "Désactiver l'authentification à double facteur",
        "text": "L'authentification à double facteur (2FA) est actuellement activée sur votre compte. Pour la désactiver, veuillez saisir votre mot de passe ainsi que le code d'authentification à 6 chiffres affiché sur votre application de TOTP.",
        "warning": "Votre code de récupération actuel deviendra obsolète. Si vous souhaitiez ré-activer la 2FA plus tard, un nouveau code de récupération vous sera communiqué."
      }
    },
    "account-preferences": {
      "title": "Préférences du compte",
      "menu-1": "Vos préférences enregistrées",
      "cartModal": "Résumé de commande :",
      "show": "Afficher",
      "hide": "Masquer",
      "sorting-col": "Colonne de tri :",
      "sorting-dir": "Sens du tri :",
      "invoices": "Affichage des factures :",
      "recurrings": "Affichage des abonnements :",
      "servers": "Affichage des serveurs :",
      "tooltips-hide": "Pour afficher de nouveau le résumé de votre commande, veuillez vous rendre dans la section \" Préférences \" des paramètres de votre compte.",
      "sms-alert": "Recevoir les notifications par SMS",
      "sms-help": "Pour recevoir des SMS, l'autorisation d'envoi doit être activée dans l'onglet 'Notifications' de la page société. Assurez-vous d'avoir saisi un numéro de téléphone valide.",
    },
    "network": {
      "title": "Réseaux",
      "title-switch": "Vswitch",
      "title-ips": "Pool d'IPs",
      "no_ip": "Aucune adresse IP",
      "no_vswitch": "Aucun Vswitch",
      "help": "-",
      "name": "Nom",
      "ports": "Ports",
      "speed": "Vitesse",
      "speed-with-unit": "Vitesse en {unit}",
      "mtu-max": "MTU max",
      "hostname": "Nom de l'hôte",
      "comment": "Commentaire",
      "plan": "Abonnement",
      "mac": "Adresse MAC",
      "cidr": "CIDR",
      "gateway": "Passerelle",
      "connected-servers": "Serveurs connectés",
      "connect-button": "Attacher un serveur",
      "add-network": "Ajouter un Vswitch",
      "add-vm": "Connecter un serveur",
      "add-success": "Vswitch créé avec succès",
      "delete-network": "Supprimer ce Vswitch",
      "cant-delete-network": "Impossible de supprimer ce vswitch. Des serveurs y sont attachés",
      "delete-vm": "Détacher ce serveur",
      "delete-success": "Vswitch supprimé avec succès",
      "title-add-server": "Connecter un serveur",
      "server-attach-success": "Serveur ajouté avec succès",
      "server-detach-success": "Serveur retiré avec succès",
      "order-count": "Vous n‘avez aucune commande… | Vous avez une IP dans le panier… | Vous avez {nb} IPs dans le panier…",
      "order-added": "Commande ajoutée…",
      "order-cart": "Voir le panier",
      "order-reset": "Vider le panier",
      "modal-network-delete-title": "Supprimer ce réseau privé",
      "modal-network-delete-description": "En confirmant cette action, ce Vswitch sera supprimé.",
      "modal-network-delete-confirm": "Êtes-vous sûr de vouloir supprimer ce Vswitch ?",
      "modal-server-detach-title": "Détacher ce serveur",
      "modal-server-detach-description": "En confirmant cette action, ce serveur sera détaché du Vswitch.",
      "modal-server-detach-confirm": "Êtes-vous sûr de vouloir détacher ce serveur ?",
      "modal-server-detach-ip-title": "Détacher l'IP {ip}",
      "modal-server-detach-ip-description": "En confirmant cette action, cette IP sera détachée du serveur.",
      "modal-server-detach-ip-confirm": "Êtes-vous sûr de vouloir détacher cette IP ?",
      "modal-server-detach-ip-warning": "Vous pourrez attacher cette IP à un autre serveur.",
      "modal-server-delete-ip-title": "Résilier l'IP {ip}",
      "modal-server-delete-ip-description": "En confirmant cette action, cette IP sera résiliée.",
      "modal-server-delete-ip-confirm": "Êtes-vous sûr de vouloir résilier cette IP ?",
      "modal-server-delete-ip-warning": "Cette action est réversible.",
      "modal-network-managed-title": "Réseau managé",
      "modal-network-managed-label-name": "Nom du réseau",
      "modal-network-managed-label-gateway": "Gateway",
      "modal-network-managed-label-cidr": "CIDR",
      "reset-network": "Réinitialiser l'interface réseau des serveurs",
      "update": "Modification Vswitch",
      "reset": "Réinitialiser",
      "add-ip": "Ajouter une IP",
      "goto-server": "Aller au serveur",
      "detach-ip": "Détacher cette IP",
      "attach-ip": "Attacher cette IP",
      "server-ip-attach-success": "Ip attachée avec succès",
      "server-ip-detach-success": "Ip détachée avec succès",
      "server-search": "Rechercher un serveur. Double-clic pour afficher la liste.",
      "update-reverse": "Modification du reverse",
      "update-ip": "Modification de l'IP",
      "open-port-list": "Afficher la liste des ports",
      "close-port-list": "Fermer la liste des ports",
      "ip-delete-success": "Résiliation de l'IP prise en compte",
      "ip-delete-failed": "Résiliation de l'IP non prise en compte",
      "ip-cancel-delete-success": "Annulation de la résiliation IP effectuée",
      "ip-added-to-cart": "IP ajoutée au panier",
      "ip-already-in-cart": "IP déjà dans le panier",
      "ip-check": "Lancer les contrôles sur cette adresse",
      "ip-summary": {
        "title": "Résumé",
        "header": [
          "IP attachées",
          "IP v4",
          "IP v6",
          "IP libres",
        ],
      },
      "title-choose-subscription": "Choisir l'abonnement",
      "subtitle-choose-subscription": "Choisir l'abonnement sur lequel sera rélisée l'IP",
      "subscription-search": "Rechercher un abonnement. Double-clic pour afficher la liste.",
      "drp": "PRA",
      "show-chart": "Afficher l'activité",
      "hide-chart": "Cacher l'activité",
      "add-ports": "Ajouter des ports",
      "number-of-ports": "Nombre de ports à ajouter",
      "qos-value": "Nouvelle QoS en Gbps",
      "company": "Société",
      "vswitch-search": "Rechercher par nom ou par société",
      "ip-search": "Rechercher par adresse, société, reverse, serveur, MAC",
      "new-vswitch": {
        "title": "Ajout d'un VSwitch",
        "number-of-ports": "Nombre de ports souhaités",
        "qos-value": "QoS souhaitée en Gbps",
      },
    },
    "company": {
      "tabs": {
        "info": "Informations",
        "users": "Utilisateurs",
        "payments": "Moyens de paiement",
        "reglements": "Règlements",
        "historic": "Historique",
        "notifications": "Notifications",
        "piggy": "Crédits",
        "tag": "Tags",
        "billing": "Facturation",
      },
      "title": "Société",
      "title-info": "Information société",
      "title-users": "Utilisateurs",
      "title-user-invite": "Inviter un utilisateur",
      "title-role-create": "Créer un accès",
      "title-role-update": "Modifier l'accès'",
      "title-authorization-update": "Modifier les autorisations",
      "title-notifications": "Gestion des notifications",
      "title-piggy": "Utilisation des crédits",
      "title-tag": "Liste des tags",
      "title-managed-service": "Serveurs en infogérance",
      "address-billing": "Adresse de facturation",
      "address-shipping": "Adresse de livraison",
      "address-copy": "Utiliser cette adresse en tant qu'adresse de livraison.",
      "company": "Société",
      "save-success": "Informations enregistrées avec succès",
      "user-add": "Inviter un utilisateur",
      "user-add-success": "Invitation envoyée à l'utilisateur avec succès",
      "user-revoke-success": "Utilisateur révoqué avec succès",
      "invitation-delete-success": "Invitation révoquée avec succès",
      "user-empty": "Veuillez inviter un utilisateur.",
      "user-role-change-success": "Le rôle de l'utilisateur a bien été modifié",
      "user-filter-place-holder": "Rechercher un utilisateur...",
      "complete-company-error": "Veuillez d'abord compléter vos informations de société (nom, adresse, et numéro de TVA).",
      "same_address": "L'adresse de livraison et de facturation sont identiques",
      "item-added": "Article(s) ajouté(s) au panier",
      "piggy": {
        "amount": "Nombre de crédits restants",
        "provisioning": "Provision automatique",
        "add-pack": "Ajouter un pack",
        "order": "Commande",
        "header": [
          "Création",
          "Utilisation",
          "Société",
          "Utilisateur",
          "Nombre de crédits",
          "Envoyé vers",
        ],
      },
      "tag": {
        "filter-place-holder": "Rechercher un tag",
        "add-tag": "Ajouter un tag",
        "list-empty": "Veuillez saisir untag",
        "create": "Création d'un tag",
        "update": "Modification du tag",
        "create-success": "Création effectuée",
        "create-error": "Erreur lors de la création",
        "update-success": "Modification effectuée",
        "update-error": "Erreur lors de la modification",
        "delete-success": "Suppression effectuée",
        "delete-error": "Erreur lors de la suppression",
        "select-all": "Tous",
        "server-list": "Liste des serveurs",
        "help": {
          "del-tag": "Supprimer le tag",
          "edit-tag": "Modifier le tag",
          "edit-color-off": "Cliquer pour modifier la couleur",
          "edit-color-on": "Cliquer pour garder cette couleur",
          "edit-servers": "Cliquer pour modifier la liste des serveurs",
        },
        "header": [
          "Nom",
          "Couleur",
          "Serveurs",
        ],
        "dialog-delete-tag": {
          "title": "Supprimer ce tag",
          "warning": "Cette action est définitive",
          "message": "En confirmant cette action, ce tag sera supprimé.",
          "extra": "Êtes-vous sûr de vouloir supprimer ce tag ?"
        },
      }
    },
    "tickets": {
      "title": "Tickets",
      "title-new": "Ouvrir un nouveau ticket",
      "service": "Service concerné",
      "server": "Serveur concerné",
      "object": "Objet du ticket",
      "object-placeholder": "Entrez votre objet ici.",
      "message": "Votre message",
      "message-placeholder": "Entrez votre message ici.",
      "add-ticket": "Nouveau ticket",
      "send-ticket": "Envoyer",
      "sending-ticket": "Envoi en cours",
      "close-ticket": "Clôturer le ticket",
      "close-ticket-force": "Clôturer sans réponse",
      "cancel": "Retour à la liste",
      "close-text": "Avons-nous répondu à l'ensemble de vos questions ?",
      "ticket-added": "Ticket ajouté…",
      "ticket-added-text": "Nos équipes ont bien pris en compte votre demande.",
      "chat": "Dialogue",
      "level": "Priorité",
      "levels": {
        "low": "Faible",
        "medium": "Normal",
        "high": "Urgent"
      },
      "categories": {
        "billing": "Comptabilité",
        "technical": "Technique",
        "sales": "Commercial",
        "other": "Autre"
      },
      "total": "Total",
      "company": "Client concerné",
      "pending_client": "Attente de la réponse client",
      "pending_admin": "Attente de réponse d'un technicien",
      "pending_you": "Attente d'une réponse de votre part",
    },
    "invoices": {
      "title": {
        "invoice": "Factures",
        "documents": "Documents",
        "recurring": "Abonnements",
        "recurrings_invoices": "Factures de l'abonnement :",
        "admin_title": "Factures, Avoirs et Bons de commande",
      },
      "differed": "Cette facture sera automatiquement payée lors du renouvellement de votre abonnement.",
      "pay": "Payer la facture",
      "auto_debit_part_title": "Vos prestations sont actuellement payées par prélèvement automatique.",
      "auto_debit_part_one": "Le règlement de votre commande sera effectué en fin de mois.",
      "auto_debit_part_two": "Pour toute question merci de contacter notre support :",
      "auto_debit_btn": "Créer un ticket",
      "last-invoice": "Facture récente",
      "details": "N° de facture ",
      "order_details": "N° de la commande ",
      "pending": "en Attente",
      "multi-pay": "Payment multi factures",
      "order_cancel": {
        "title": "Annuler la commande",
        "msg": "Êtes-vous sur de vouloir annuler cette commande ?",
        "btn": "Annuler la commande"
      },
      "tabs": {
        "invoice_tab": "Facture",
        "order_tab": "Commande",
        "credit_tab": "Avoir",
        "payment_tab": "Paiement",
        "payment_list_tab": "Liste des paiements",
        "expenses_list_tab": "Liste des dépenses",
        "payment_tab_header": "Détails",
        "dispute_list_tab": "Liste des litiges",
        "payment_detail_header": [
          "N° Facture",
          "HT",
          "Remise",
          "Net HT",
          "TVA",
          "TTC",
          "Payé",
          "Solde",
        ],
      },
      "no-payments": "Aucun paiements.",
    },
    "cgv": {
      "title": "Conditions Générales",
      "action": "Accepter les CGV",
      "next": "Suivant",
      "previous": "Précédent",
      "download": "Télécharger",
      "accept": "J'accepte les Condtions Générale de Ventes.",
      "signed-at": "Vous avez signé nos CGV le {date} depuis l'adresse IP {ip}.",
      "up-to-date": "Les CGV n'ont pas été modifiées depuis votre signature.",
      "not-up-to-date": "Les CGV ont été modifiées depuis votre signature.",
      "current-version": "La version actuelle est la V{version}.",
      "read": "Consulter les Conditions Générales",
    },
    "email-validation": {
      "title": "Validation E-mail",
      "send-action": "Renvoyez-moi le lien de confirmation"
    },
    "forget-password": {
      "title": "Mot de passe oublié",
      "send-action": "Envoyez-moi les instructions de réinitialisation",
      "confirm-success": "Un e-mail vous a été envoyé avec les instructions de réinitialisation (merci de vérifier également dans vos spams)."
    },
    "forget-password-change": {
      "title": "Nouveau mot de passe",
      "action": "Confirmer"
    },
    "commercial": {
      "title": "Commercial",
      "last_name": "Nom",
      "first_name": "Prénom",
      "email": "Email",
      "phone": "Téléphone",
      "status": "Statut",
      "disabled": "Désactivé",
      "create_commercial": "Créer un commercial",
    },
    "cron": {
      "add-cron": "Ajouter une tâche",
      "header": [
        "Tâche",
        "Nom",
        "Société",
        "Utilisateur",
        "Serveur",
        "Programmation",
        "Récurrente",
        "Statut",
        "Activée",
      ],
      "job": {
        "type": {
          "schedule-reboot": "Redémarrage",
          "snapshot": "Snapshot",
          "undefined": "",
        },
        "message": {
          "create-success": "Tâche créée",
          "delete-success": "Tâche supprimée",
          "update-success": "Tâche modifiée",
          "no-job": "Aucune tâche programmée",
        },
        "creation": {
          "title": "Création d'une tâche",
          "label": [
            "Société",
            "Serveur",
            "Type",
            "Tâche récurrente",
            "Programmation",
          ],
          "no-credit": [
            "Aucun crédit disponible.",
            "Sans crédits, les tâches récurrentes programmées sur un serveur ne pourront pas être exécutées.",
            "Nous vous invitons à acquérir un pack de crédits pour permettre l'exécution de multiples tâches par serveur.",
            "Veuillez noter que ces crédits autorisent également la réception d'alertes par SMS.",
            "Nous vous remercions de votre compréhension.",
          ],
        },
        "toggle": {
          "button-tip-true": "Cliquer pour activer la tâche",
          "button-tip-false": "Cliquer pour désactiver la tâche",
          "true": "Tâche désactivée",
          "false": "Tâche activée",
        },
      },
      "modal": {
        "delete-job-title": "Supprimer la tâche {job}",
        "delete-job-description": "En confirmant cette action, cette tâche sera supprimée.",
        "delete-job-confirm": "Êtes-vous sûr de vouloir supprimer cette tâche ?",
        "delete-job-warning": "Cette action est réversible.",
      },
    },
    "managed-service": {
      "title": "Infogérance",
      "terminate-canceled": "Résiliation de l'infogérance annulée",
      "help": {
        "terminate": "Résilier l'infogérance",
        "cancel-terminate": "Annuler résiliation",
      },
      "dialog-terminate": {
        "title": "Résilier l'infogérance du serveur {server}",
        "warning": "Cette action est réversible",
        "message": "En confirmant cette action, l'infogérance sera résiliée.",
        "extra": "Êtes-vous sûr de vouloir résilier cette infogérance ?",
        "button": "Résilier"
      },
      "dialog-cancel-terminate": {
        "title": "Annulation de la résiliation de l'infogérance",
        "warning": "Cette action est sensible",
        "message": "En confirmant cette action, la résiliation de votre infogérance sera annulée.",
        "extra": "Êtes-vous sûr de vouloir annuler la résiliation de cette infogérance ?",
        "button": "Annuler la résiliation"
      },
    },
    "scheduled-action": {
      "add-action": "Créer une action",
      "goto-server": "Aller au serveur",
        "header": [
        "Action",
        "Serveur",
        "IP",
        "Société",
        "Création",
        "Programmation",
        "En attente",
        "Terminée",  
      ],
      "type": {
        "delete_server": "Résiliation de serveur",
        "delete_ip": "Résiliation d'IP",
        "delete_outsourcing": "Résiliation d'infogérance",
        "deactivate_server": "Désactivation de serveur",
      },
      "states": {
        "all": "Toutes",
        "pending": "En attente",
        "terminated": "Terminée"
      },
      "dialog-action-delete": {
        "title": "Supprimer une action planifiée",
        "danger": "Cette suppression est irréversible",
        "message": "En confirmant cette demande, l'action planifiée sera supprimée",
        "extra": "Êtes-vous sûr de vouloir supprimer cette action planifiée ?"
      },
      "operation": [
        "Création d'une action planifiée",
        "Modification d'une action planifiée",
      ],
    },
    "dispute-list": {
      "title": "Litiges",
      "create-dispute": "Créer un litige",
      "date": "Date",
      "number": "N° du litige",
      "ref": "Référence",
      "facture": "Factures rattachées",
      "total-amount": "Montant",
      "fees": "Frais",
      "client_code": "Code client",
      "client": "Client",
      "no_dispute": "Aucun litige trouvé",
      "paid": "Réglé",
      "status": "Statut",
      "states": {
        'title': "Statut",
        'lost': "Perdu",
        'needs_response': "En attente",
        'under_review': "En cours",
        'warning_closed': "Warning Fermé",
        'warning_needs_response': "Warning en attente",
        'warning_under_review': "Warning en cours",
        'won': "Gagné",
      },
      "reason": {
        "title": "Motif",
        "bank_cannot_process": "Traitement banque impossible",
        "credit_not_processed": "Non traité par la banque",
        "customer_initiated": "Demande du client",
        "debit_not_authorized": "Débit non autorisé",
        "duplicate": "Doublon de facturation",
        "fraudulent": "Frauduleux",
        "general": "Raisons diverses",
        "incorrect_account_details": "Détails du compte incorrects",
        "insufficient_funds": "Fonds insuffisants",
        "product_not_received": "Articles non reçus",
        "product_unacceptable": "Articles inacceptables",
        "subscription_canceled": "Abonnement résilié",
        "unrecognized": "Paiement non reconnu",
      },
      "date-range": {
        'month': 'Mois',
        'all': 'Tous',
        'last-month': 'Mois dernier',
        'last-quarter': 'Trimestre dernier',
        'format': 'dd/MM/yyyy',
        'apply': 'Appliquer',
        'cancel': 'Annuler',
        'weekLabel': 'S',
        'customRange': 'Personnalisé',
        'daysOfWeek': ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        'monthNames': ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec'],
        'monthNamesFull': ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      },
      "help": {
        "pay": "Régler les factures",
      },
    },
  },
  "unit": {
    "GB": "Go",
    "vcpu": "vCores",
    "mbps": "mbps",
    "Cores": "vCores",
  },
  "form": {
    "required-field": "Champs requis",
    "missing-fields": "Merci de remplir les champs manquants",
    "password-constraints": "Mot de passe minimum 6 caractères",
    "email-invalid": "Adresse e-mail non valide",
    "affiliate-code-invalid": "Code d'affiliation non valide",
    "country-invalid": "Pays non valide",
    "password-condition": {
      "characters": "caractères min.",
      "contains-number": "1 chiffre",
      "contains-uppercase": "1 majuscule",
      "contains-special": "1 caractère spécial",
    },
  },
  "beta-functionnality-modal": {
    "title": "Fonctionnalité en accès limité.",
    "content": "Pour activer cette fonctionnalité, vous devez prendre contact avec notre service commercial par téléphone ou ticket."
  },
  "tour": {
    "next": "Suivant",
    "previous": "Précédent",
    "end": "Terminer",
    "skip": "Passer le tour",
    "global": {
      "play": "Rejouer le tour",
      "dashboard": {
        "header": {
          "title": "Dashboard"
        },
        "content": "Retrouvez ici un résumé de vos informations tickets, actions et statistiques des consommations.",
      },
      "servers": {
        "header": {
          "title": "Ici pilotez vos serveurs avec de nombreuses fonctionnalités :"
        },
        "content": "- Accès distant (dépannage par kvm)<br />" +
          "- Upgrade / Downgrade de ressources CPU, RAM, disque<br />" +
          "- Montage ISO et ISO de recovery<br />" +
          "- Stop / Start des serveurs<br />" +
          "- Activation ipv6 et création des reverses<br />" +
          "- Remise à zéro, snapshot et création des templates<br />" +
          "- Statistiques des ressources CPU, RAM, disque, réseau",
      },
      "networks": {
        "header": {
          "title": "Réseau"
        },
        "content": "Créez et gérez vos réseaux privés pour la sécurisation et la mise en place de la plateforme de vos serveurs."
      },
      "company": {
        "header": {
          "title": "Société"
        },
        "content": "Ici gérez les informations de votre société ainsi que vos modes de règlement ainsi que la gestion des rôles.",
      },
      "tickets": {
        "header": {
          "title": "Ticket"
        },
        "content": "Ici communiquez avec nos différents services : technique, commercial et comptabilité.",
      },
      "affiliations": {
        "header": {
          "title": "Affiliations"
        },
        "content": "Ici gérez votre compte affiliation ainsi que les sommes créditées<br />" +
          "« Contactez notre service commercial afin de vérifier votre éligibilité »",
      },
      "recurring": {
        "header": {
          "title": "Abonnement"
        },
        "content": "Gérez la liste de vos abonnements et l’intitulé des noms de vos projets qui figure sur vos factures.",
      },
      "invoice": {
        "header": {
          "title": "Document"
        },
        "content": "Ici retrouvez l’intégralité de vos pièces comptables (commandes, factures et avoirs)",
      },
      "vms": {
        "header": {
          "title": "Caddie"
        },
        "content": "Ici commandez vos serveurs et faites vous livrer en moins d’une minute.",
      },
      "header-user": {
        "header": {
          "title": "Paramètres du compte"
        },
        "content": "- Ici gérez vos différents paramètres de votre compte<br />" +
          "- Basculez d’interface entre vos différents comptes<br />" +
          "- Gérez votre magasin de clés <strong>SSH</strong> et <strong>API</strong><br />" +
          "- Activez votre authentification <strong>2FA</strong><br />" +
          "- Achetez vos prestations (intervention ponctuelle et licence Microsoft)<br />" +
          "- Accédez à la documention <strong>API</strong>"
      }
    }
  },
  "object_search": {
    "empty": "Entrez au minimum {length} caractères",
    "selected": "La valeur sélectionnée est {value}",
    "match": "Le nom doit exister dans la liste",
  },
  "company_search": {
    "placeholder": "Rechercher un client",
    "empty": "Chercher puis sélectionner une compagnie",
    "selected": "La compagnie sélectionnée est {value}",
  },
  "cron-date": {
    "minute": "Minute",
    "hour": "Heure",
    "month": "Month",
    "day-of-month": "Jour du mois",
    "day-of-week": "Jour de la semaine",
    "help": "L'astérisque (*) représente n'importe quelle valeur possible pour le champ concerné.",
    "hours": "Heures",
    "at": "A",
    "all": "Toutes les",
  },
  "days-of-week": ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  "months-of-year": ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  "Forbidden": "Permission refusée.",
  "errors": {
    "CREATE_SUCCESSFUL": "Creation réussie",
    "UPDATE_SUCCESSFUL": "Mise à jour réussie",
    "DELETE_SUCCESSFUL": "Suppression réussie",
    "NO_DOCUMENT_FOUND": "Objet inexistant !",
    "PAGE_NOT_FOUND": "Page introuvable.",
    "ALREADY_EXISTS": "Existe déjà",
    "TASK_ALREADY_RUNNING": "Tâche déjà en cours d'exécution",
    "INVALID_RECAPTCHA": "Recaptcha invalide.",
    "UPDATE_UNSUCCESSFUL": "Echec de la mise à jour",
    "ERR_ORDER_DELETION": "Erreur lors de la suppression de la commande",

    "TOO_MANY_KVM": "Vous avez trop de KVM ouverte",

    "PERMISSION_DENIED": "Vous n'avez pas les permissions pour cette action.",
    "2FA_INVALID_SECRET": "Secret invalide",
    "USER_UPDATE_SSH_KEY_SUCCESS": "Mise à jour avec succès.",
    "USER_UPDATED_SUCCESSFULLY": "Mise à jour avec succès.",
    "TOKEN_UPDATE_SUCCESS": "Mise à jour avec succès.",

    "SERVER_RESET_FAIL_NOT_LICENSED_OS_TO_LICENSED_OS": "Cette fonctionnalité n'est pas encore disponible pour votre utilisateur. Contactez votre support.",

    "IMAGES_EMPTY": "Vous avez aucune image, vous pouvez en créer depuis le snapshot d'une machine.",

    "APP_BETA_FUNCTIONNALITY": "Prochainement, une application mobile titan vous permettra de piloter toutes les fonctionnalités.",

    "BETA_FUNCTIONNALITY": "Pour activer cette fonctionnalité, vous devez prendre contact avec notre service commercial par telephone ou ticket.",

    "INVALID_EMAIL_OR_PASSWORD": "Adresse e-mail ou mot de passe invalide.",
    "MISSING_EMAIL_OR_PASSWORD": "Adresse e-mail ou mot de passe manquant.",
    "ACCOUNT_NOT_VALIDATED": "Votre compte n'est pas validé, merci de vérifier vos emails.",
    "USER_OR_COMPANY_MISSING_NAME_OR_ADDRESS_INFORMATION": "Vous devez au préalable compléter vos informations personnelles et professionnelles.",

    "INTERNAL_ERROR": "Erreur interne.",
    "BAD_PERMISSION": "Permission refusée.",
    "BAD_REQUEST": "Mauvaise requête.",
    "SERVER_NOT_FOUND": "Serveur introuvable.",
    "COMPANY_NOT_FOUND": "Compagnie introuvable.",
    "INVOICE_NOT_FOUND": "Facture non trouvée.",
    "INVOICE_ALREADY_PAID": "Facture déjà payée.",
    "PAYMENT_METHOD_NOT_FOUND": "Méthode de paiement non trouvée.",
    "PLAN_NOT_FOUND": "Modèle non trouvé",
    "CREDIT_NOT_FOUND": "Avoir non trouvé.",
    "CREDIT_EXPIRED": "L'avoir a expiré.",
    "CREDIT_SPENT": "Avoir non valable, veuillez contacter l'assistance.",
    "CREDIT_INVALID": "Crédit non valide, veuillez conctacter l'assitance.",
    "PAYMENT_FAIL": "Le paiement a échoué.",
    "PROJECT_NAME_INVALID": "Le nom du projet est invalide, sa longueur doit être comprise entre 4 et 60 caractères.",
    "PROJECT_NAME_CONFLICT": "Le nom du projet est déjà pris, veuillez en choisir un autre.",
    "ROLE_NAME_INVALID": "Le nom du rôle est invalide, sa longueur doit être comprise entre 2 et 50 caractères.",
    "REVERSE_INVALID": "Reverse invalide : veuillez vous assurer d'avoir défini l'adresse IP adéquate dans la zone DNS du reverse.",

    "AVATAR_INVALID_TYPE": "L'avatar choisis n'est pas au bon format (jpg ou png).",
    "AVATAR_INVALID_SIZE": "L'avatar choisis ne respecte pas les conditions.",
    "AVATAR_UPDATED_SUCCESSFULLY": "Avatar mis à jour avec succès",
    "PHONE_NUMBER_INVALID": "Le numéro de téléphone est invalide.",
    
    // License
    "LICENSE_NOT_FOUND": "License introuvable",

    // Snapshot
    "SNAPSHOT_NOT_FOUND": "Snapshot introuvable.",
    "SNAPSHOT_RESTORE_STARTED": "Restauration du snapshot en cours.",
    "SNAPSHOT_RENAME_SUCCESS": "Snapshot renommé avec succès.",
    "SNAPSHOT_DELETE_SUCCESS": "Snapshot supprimé avec succès.",
    "SNAPSHOT_CREATE_FAIL_LIMIT_EXCEEDED": "La limite de snapshots a été atteinte.",
    "SNAPSHOT_DELETE_INVALID_STATE": "Impossible de supprimer le snapshot : état invalide.",
    "SNAPSHOT_RESTORE_INVALID_SNAPSHOT_STATE": "Impossible de restaurer le snapshot : état du snapshot invalide.",
    "SNAPSHOT_CREATE_FAIL_TOO_FAST": "Impossible de créer un nouveau snapshot: au moins 30 minutes doivent séparer la création de nouveaux snapshots.",

    // Image
    "IMAGE_CREATE_FAIL_INSUFFICIENT_SPACE": "Votre espace de stockage est insuffisant.",
    "IMAGE_CREATE_FAIL_QUOTA_EXCEEDED": "Le quotas de création d'image a été dépassé.",
    "SERVER_CREATE_FAIL_SERVER_DISK_IMAGE_AND_TEMPLATE_CONFLICT": "Conflit entre l'image disque et le template.",
    "SERVER_CREATE_FAIL_INVALID_DISK_SIZE": "Taille du disque invalide.",
    "SERVER_CREATE_FAIL_INVALID_IMAGE_STATE": "Etat de l'image invalide.",
    "SERVER_CREATE_FAIL_DISK_SOURCE_INVALID": "Source disque invalide.",
    "SERVER_CREATE_FAIL_IMAGE_UUID_MISSING": "Identifiant unique manquant.",
    "SERVER_CREATE_FAIL_TEMPLATE_UUID_MISSING": "Identifiant unique manquant.",
    "IMAGE_DELETE_SUCCESS": "Image supprimé avec succès.",
    "IMAGE_UPDATE_SUCCESS": "Image mis à jours avec succès.",
    "IMAGE_CREATE_SUCCESS": "Image créée avec succès.",
    "NO_IMAGE": "Vous n'avez aucune image, vous pouvez en créer une depuis un snapshot.",
    "IMAGE_NOT_FOUND": "Image introuvable.",
    "IMAGE_DELETE_FAIL_IS_ACTUALLY_USED": "Cette image est utilisé, vous ne pouvez la supprimer.",
    "IMAGE_CREATE_FAIL_PLESK_DISABLED": "Il est impossible de creer des template PLESK.",
    "IMAGE_CREATE_FAIL_NAME_ALREADY_USED": "Ce nom est déjà utilisé.",
    "IMAGE_INVALID_NAME_SIZE": "La taille du nom est incorrect.",
    "IMAGE_INVALID_OS_NAME_SIZE": "La taille du nom d'OS est incorrect.",
    "IMAGE_INVALID_OS_VERSION_SIZE": "La taille de version de l'OS est incorrect.",
    "IMAGE_CREATE_FAIL_SNAPSHOT_INVALID_STATE": "Le snapshot est dans un état invalide.",
    "IMAGE_FAIL_TO_RETRIEVE_ORIGINAL_TEMPLATE": "Impossible de retrouver le template d'origine.",
    "INVALID_TARGET_STATE": "Cible inexistante ou dans un état non conforme avec la demande.",

    // Addon
    "ADDON_INSTALL_FAIL_INVALID_PLAN": "Plan invalide.",
    "ADDON_INSTALL_FAIL_INVALID_QUANTITY": "Quantité d'addons invalide.",
    "ADDON_INSTALL_FAIL_INVALID_ITEM": "Addon invalide.",
    "ADDON_INSTALL_FAIL_CPU_LIMIT_EXCEEDED": "Impossible d'installer le(s) addon(s) : le quota de CPUs a été dépassé, veuillez contacter le support.",
    "ADDON_INSTALL_FAIL_RAM_LIMIT_EXCEEDED": "Impossible d'installer le(s) addon(s) : le quota de RAM a été dépassé, veuillez contacter le support.",
    "ADDON_INSTALL_FAIL_DISK_LIMIT_EXCEEDED": "Impossible d'installer le(s) addon(s) : le quota de disque a été dépassé, veuillez contacter le support.",
    "ADDON_INSTALL_FAIL_SRV_ON_TERMINATION": "Impossible d'installer le(s) addon(s) : la suppression du serveur est planifiée.",
    "ADDON_UNINSTALL_FAIL_SERVER_BAD_STATE": "Impossible de désinstaller le(s) addon(s) : le serveur doit être arrêté.",
    "ADDON_UNINSTALL_FAIL_NOT_FOUND": "Impossible de désinstaller le(s) addon(s) : addon(s) introuvable(s).",
    "ADDON_UNINSTALL_FAIL_SERVER_DO_NOT_BELONG_TO_SERVER": "Impossible de désinstaller le(s) addon(s) : les addon(s) ne sont pas installé(s).",
    "ADDON_UNINSTALL_FAIL_ITEM_TYPE_DISK_INVALID": "Impossible de désinstaller le(s) addon(s) : les addons disque ne peuvent pas être désinstallés.",
    "ADDON_UNINSTALL_FAIL_INVALID_QUANTITY": "Impossible de désinstaller le(s) addon(s) : quantité invalide.",
    "ADDON_UNINSTALL_FAIL_SRV_ON_TERMINATION": "Impossible de désinstaller le(s) addon(s) : la suppression du serveur est planifiée.",
    "ADDON_UNINSTALL_SUCCESS": "Addon(s) désinstallé(s) avec succès.",
    "ADDON_LIST_FAIL": "Impossible de lister les addons.",
    "ADDON_ERR_INVOICE_NOT_PAID": "Ajout d'addon impossible. Facture(s) impayée(s).",

    // Invoice
    "INVOICE_PAYMENT_NOT_FOUND": "Impossible de trouver le paiement de la facture.",
    "INVOICE_CONTACT_NOT_RECOGNIZED": "Le contact pour la facture est introuvable.",
    "INVOICE_CREATE_FAIL": "Impossible de créer une nouvelle facture.",
    "INVOICE_CREATE_SUCCESS": "Nouvelle facture créée.",
    "INVOICE_CREATE_NOT_REQUIRED": "La création d'une nouvelle facture n'est pas encore requise.",
    "INVOICE_ITEM_BUILD_FAIL": "Impossible de construire les articles de la facture.",
    "UNPAID_INVOICES_EXIST": "Désolé, votre compte fait apparaître des factures impayées",

    // Order
    "ORDER_CANCEL_SUCCESS": "Commande annulée avec succès.",
    "ORDER_ALREADY_INVOICE": "La commande a déjà été passée en facture.",
    "ORDER_ALREADY_CANCELED": "La commande est déjà annulée.",
    "ORDER_ALREADY_PAID": "La commande est déjà payée.",
    "ORDER_HAS_SUCCESS_PAYMENT": "La commande a déjà été enregistrée avec un paiement réussi. Veuillez contacter le service d'assistance.",
    "ORDER_HAS_WAITING_PAYMENT": "Un paiement en attente est déjà enregistré sur la commande. Veuillez patienter.",
    "ORDER_HAS_DEPLOYED_ITEM": "La commande comporte déjà un article déployé. Veuillez contacter le support.",
    "ORDER_NOT_CANCELABLE": "La commande ne peut pas être annulée. Veuillez contacter le support.",

    // Recurring invoices
    "RECURRING_INVOICE_NOT_FOUND": "Abonnement introuvable.",
    "RECURRING_INVOICE_UPDATE_FAIL": "Impossible de mettre à jour l'abonnement.",
    "RECURRING_INVOICE_CANCEL_SUCCESS": "Abonnement arrêté avec succès.",
    "RECURRING_INVOICE_UPDATE_SUCCESS": "Abonnement mis à jour avec succès.",
    "RECURRING_INVOICE_CREATE_FAIL": "Impossible de créer un nouvel abonnement.",
    "RECURRING_INVOICE_CREATE_SUCCESS": "Nouvel abonnement créé avec succès.",
    "RECURRING_INVOICE_INVALID_FREQ": "La fréquence de l'abonnement n'est pas valide.",
    "RECURRING_INVOICE_ITEM_BUILD_FAIL": "Impossible d'ajouter les articles à votre abonnement.",
    "RECURRING_INVOICE_NOT_ONGOING": "L'abonnement n'est pas en cours.",
    "RECURRING_INVOICE_START_TOO_EARLY": "La date de début de l'abonnement est trop tôt, supprimez-la pour démarrer de manière dynamique l'abonnement après un paiement réussi.",
    "RECURRING_INVOICE_CANCELED": "L'abonnement a été annulé.",
    "RECURRING_INVOICE_PENDING": "L'abonnement est en attente.",
    "RECURRING_INVOICE_ONGOING": "Suppression impossible. Un abonnement actif dans ce compte exige au moins un mode de paiement enregistré.",
    "RECURRING_ITEM_NOT_LICENSE_TYPE": "L'item n'est pas de type license",
    "RECURRING_INVOICE_NAME_CANCELED": "Abonnement résilé. Veuillez changer le nom du projet.",
    "SUBSCRIPTION_ALREADY_EXISTS": "L'abonnement existe déjà",
    "OVER_FRAMEWORK_AGREEMENT_QUOTA": "Vous avez dépassé les quantités de ressources du contrat cadre",

    // Payment methods
    "PAYMENT_METHOD_ADD_FAIL_EMPTY_NAME": "Vous devez spécifier le nom complet figurant sur la carte.",
    "PAYMENT_METHOD_UPDATE_SUCCESS": "Le moyen de paiement a été mis à jour avec succès.",
    "PAYMENT_METHOD_REMOVE_SUCCESS": "Le moyen de paiement a été supprimé avec succès.",
    "PAYMENT_METHOD_ADD_SUCCESS": "Le moyen de paiement a été ajouté avec succès.",
    "PAYMENT_METHOD_ALREADY_REGISTERED": "Le moyen de paiement est déjà enregistré.",
    "PAYMENT_METHOD_ADD_FAIL": "Impossible d'ajouter le nouveau moyen de paiement.",
    "PAYMENT_EXCEED_AMOUNT": "Le montant du paiement dépasse le montant à payer.",
    "PAYMENT_FAILED": "Le paiement à échoué.",
    "PAYMENT_METHOD_IS_DEFAULT": "Suppression du moyen de paiement par défaut interdite.",
    "Credit card number is not long enough": "Le numéro de carte n'est pas assez long",
    "Invalid credit card number": "Numéro de carte non valide",
    "Test numbers are not allowed": "Cartes de test non autorisées",
    "Invalid year": "Année non valide",
    "Invalid month": "Mois non valide",
    "Credit card has expired": "Carte de crédit expirée",
    "Invalid CVV": "Numéro CVC invalide",
    "Unknown credit card method": "Type de carte inconnu",

    // Payment delay
    "PAYMENT_DELAY_ALREADY_EXISTS": "Ce délai de paiement existe déjà.",

    // Credit
    "CREDIT_CREATE_SUCCESS": "L'avoir a été créé avec succès.",
    "CREDIT_FUNDING_EXCEEDED": "Le financement de l'avoir pour une facture ciblée a été dépassé.",
    "CREDIT_FUNDING_INVOICE_NOT_PAID": "Une facture de financement ciblée n'est pas payée.",
    "CREDIT_FUNDING_ITEM_NOT_FOUND": "Le financement d'un article de la facture ciblée n'a pas été trouvé.",
    "CLEAR_AMOUNT_EXCEED": "Le montant est supérieur au solde du document.",

    // Migration
    "MIGRATE_FAIL_ITEM_NOT_DEPLOYED": "Incapable de migrer, un ou plusieurs articles ne sont pas encore déployés.",

    // Server
    "BAD_OID": "OID invalide",

    // Delete
    "SERVER_DELETE_FAIL_ALREADY_ON_TERMINATION": "La suppression du serveur est déjà planifiée.",
    "SERVER_DELETE_FAIL_PLAN_NOT_FOUND": "Impossible de supprimer le serveur : plan introuvable.",
    "SERVER_DELETION_PENDING_SUCCESS": "Suppression du/des serveur(s) en cours.",
    "SERVER_DELETION_PENDING": "Action non autorisée. La résiliation du serveur a été demandée.",
    "SERVER_DELETE_SUCCESS": "Serveur supprimé avec succès.",
    "SERVER_DELETE_FAIL_RECURRING_NOT_FOUND": "Impossible de supprimer le serveur : abonnement introuvable.",
    "SERVER_DELETE_FAIL_WAN_CONFIG_ERROR": "Impossible de supprimer le serveur : erreur de configuration WAN.",
    "SERVER_DELETE_FAIL_UPDATE_RECURRING_ERROR": "Impossible de supprimer le serveur : erreur lors de la mise à jour de l'abonnement.",
    "SERVER_CANCEL_DELETE_FAIL_ALREADY_DELETED": "Impossible d'annuler la résiliation du serveur : le serveur est déjà résilié.",
    "SERVER_DELETE_FAIL_ACTION_NOT_SCHEDULED": "La suppression de ce serveur n'est pas planifiée.",
    // Create
    "SERVER_CREATE_FAIL": "Impossible de trouver la facture correspondante.",
    "SERVER_CREATE_FAIL_FREE_WAN_NOT_FOUND": "Impossible de créer le(s) serveur(s) : plus d'addresse IP disponible, veuillez contacter le support.",
    "SERVER_CREATE_FAIL_SERVER_LIMIT_EXCEEDED": "Impossible de créer le(s) serveur(s) : le quota de serveurs a été dépassé, veuillez contacter le support.",
    "SERVER_CREATE_FAIL_CPUS_LIMIT_EXCEEDED": "Impossible de créer le(s) serveur(s): le quota de CPUs a été dépassé, veuillez contacter le support.",
    "SERVER_CREATE_FAIL_RAM_LIMIT_EXCEEDED": "Impossible de créer le(s) serveur(s): le quota de RAM a été dépassé, veuillez contacter le support.",
    "SERVER_CREATE_FAIL_DISK_LIMIT_EXCEEDED": "Impossible de créer le(s) serveur(s): le quota de disque a été dépassé, veuillez contacter le support.",
    "SERVER_CREATE_FAIL_IDENT_MISSING": "Impossible de créer le(s) serveur(s) : mot de passe et/ou clef SSH manquante.",
    "SERVER_CREATE_FAIL_ADDON_QUANTITY_EXCEEDED": "Impossible de créer le(s) serveur(s) : la limite d'addons a été dépassée, veuillez contacter le support.",
    "SERVER_CREATE_FAIL_INVALID_ADDON": "Impossible de créer le(s) serveur(s) : addon(s) introuvable ou invalide.",
    "SERVER_CREATE_FAIL_INVALID_QUANTITY": "Impossible de créer le(s) serveur(s) : mauvaise quantité.",
    "SERVER_CREATE_FAIL_INVALID_PLAN": "Impossible de créer le(s) serveur(s) : plan introuvable ou invalide.",
    "SERVER_CREATE_FAIL_INVALID_CPU_QUANTITY": "Impossible de créer le(s) serveur(s) : quantité de CPU(s) invalide.",
    "SERVER_CREATE_FAIL_INVALID_TEMPLATE": "Impossible de créer le(s) serveur(s) : template introuvable ou invalide.",
    "SERVER_CREATE_FAIL_INVALID_PASSWORD": "Impossible de créer le(s) serveur(s) : le mot de passe du/des serveur(s) est invalide.",
    "SERVER_CREATE_FAIL_FREE_MAC_NOT_FOUND": "Impossible de créer le(s) serveur(s) : aucune addresse MAC disponible",
    "SERVER_CREATE_FAIL_FREE_IP_NOT_FOUND": "Impossible de créer le(s) serveur(s) : aucune addresse IP disponible",
    "SERVER_CREATE_FAIL_MISSING_LICENSE": "Impossible de créer le(s) serveur(s) : licence manquante.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_INVALID": "Impossible de créer le(s) serveur(s) : nom de projet invalide.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_DUPLICATE": "Impossible de créer le(s) serveur(s) : le nom du projet existe déjà.",
    // Reset
    "SERVER_RESET_SUCCESS": "Réinitialisation du serveur en cours.",
    "SERVER_RESET_FAIL_MISSING_PASSWORD_OR_KEY": "Impossible de réinitialiser le serveur : mot de passe et/ou clef SSH manquante.",
    "SERVER_RESET_FAIL_INVALID_PLAN": "Impossible de réinitialiser le serveur : plan introuvable ou invalide.",
    "SERVER_RESET_FAIL_INVALID_TEMPLATE": "Impossible de réinitialiser le serveur : template introuvable ou invalide.",
    "SERVER_RESET_FAIL_WAN_NOT_FOUND": "Impossible de réinitialiser le serveur : entrée WAN introuvable.",
    // Update
    "SERVER_UPDATE_SUCCESS": "Serveur mis à jour avec succès.",
    "SERVER_UPDATE_FAIL_INVALID_NAME": "Impossible de mettre à jour le serveur : nouveau nom invalide.",
    "SERVER_UPDATE_FAIL_MISSING_PARAMETER": "Impossible de mettre à jour le serveur : paramètre manquant.",
    "SERVER_UPDATE_FAIL_UPDATE_REVERSE": "Impossible de mettre à jour le reverse du serveur, veuillez contacter le support.",
    "SERVER_UPDATE_FAIL_INVALID_REVERSE": "Impossible de mettre à jour le serveur : reverse invalide.",
    "SERVER_UPDATE_FAIL_WAN_NOT_FOUND": "Impossible de mettre à jour le serveur : entrée WAN introuvable.",
    "SERVER_UPDATE_FAIL_NOTES_TOO_LONG": "Impossible de mettre à jour le serveur : le champ notes est trop long.",
    "SERVER_UPDATE_FAIL_NO_PRIMARY_IP": "Impossible de mettre à jour le serveur : aucune IP principale trouvée.",
    // Actions
    "SERVER_ACTION_FAIL_UNKNOWN_ACTION": "Action inconnue.",
    "SERVER_ACTION_FAIL_CONFLICT": "L'action n'est pas compatible avec l'état actuel du serveur.",
    "SERVER_ACTION_SUCCESS": "Action sur le serveur en cours de traitement.",
    // ISO
    "SERVER_LOAD_ISO_BAD_PATH": "Impossible de charger l'ISO: le chemin est invalide.",
    "SERVER_LOAD_ISO_BAD_PROTOCOL": "Impossible de charger l'ISO: le protocole est invalide",
    "SERVER_LOAD_ISO_SUCCESS": "ISO chargé avec succès.",
    "SERVER_UNLOAD_ISO_SUCCESS": "ISO démonté avec succès.",
    // OUTSOURCING - MANAGED SERVICES
    "SERVER_OUTSOURCING_SET": "Niveau de service actif sur le serveur. Veuillez vous rapprocher du service comptabilité.",
    "SERVER_OUTSOURCING_ALREADY_SET": "Niveau de service déjà actif pour le(s) serveur(s)",
    "SERVER_OUTSOURCING_TERMINATION_EXISTS": "Une demande de résiliation existe déjà.",
    "SERVER_OUTSOURCING_TERMINATION_TOO_LATE": "La résiliation doit être demandée au moins deux mois avant la fin du contrat.",
    "SERVER_OUTSOURCING_TERMINATION_NOT_EXISTS": "Il n'existe aucune demande de résiliation.",
    // DRP
    "SERVER_DRP_ALREADY_SET": "P.R.A. déjà activé pour le(s) serveur(s)",
    // DEACTIVATION
    "SERVER_DEACTIVATION_ALREADY_SCHEDULED": "Déssactivation du serveur déjà planifiée.",
    "SERVER_DEACTIVATION_DATE_INVALID": "La date de désactivation est invalide.",

    // Template
    "TEMPLATE_DELETE_SUCCESS": "Template supprimé avec succès.",
    "TEMPLATE_GET_FAIL_INVALID_OS": "Impossible de récupérer les templates.",
    "TEMPLATE_DELETE_NOT_FOUND": "Impossible de supprimer le template : template introuvable.",

    // IP KVM
    "IPKVM_FAIL_ACTION_UNKNOWN": "Action inconnue sur le KVM IP.",
    "IPKVM_SEND_ACTION_SUCCESS": "Action sur le KVM IP en cours de traitement.",
    "IPKVM_GET_URI_FAIL_KVM_NOT_STARTED": "Le KVM IP n'est pas démarré sur le serveur.",
    "IPKVM_FAIL_INVALID_DEADLINE": "Impossible d'effectuer l'action sur le KVM IP : le timeout est invalide.",
    "IPKVM_FAIL_TOO_LONG_DEADLINE": "Impossible d'effectuer l'action sur le KVM IP : le timeout ne doit pas excéder 5 heures.",
    "IPKVM_FAIL_NOT_STOPPED": "Action impossible : le KVM n'est pas stoppé",

    // Migrate
    "MIGRATE_FAIL_MISSING_SRC": "Migration impossible : source manquante (hyperviseur ou serveur).",
    "MIGRATE_FAIL_CONFLICT_SRC": "Migration impossible : conflit à la source (hyperviseur ou serveur).",
    "MIGRATE_FAIL_HYPERV_SRC_EQUAL_DST": "Migration impossible : les hyperviseurs (source et destination) sont les mêmes.",
    "MIGRATE_FAIL_HYPERV_SRC_NOT_FOUND": "Migration impossible : hyperviseur source introuvable.",
    "MIGRATE_FAIL_HYPERV_DST_NOT_FOUND": "Migration impossible : hyperviseur de destination introuvable.",
    "MIGRATE_SUCCESS": "Demande de migration envoyée avec succès.",

    // Event / History
    "SERVER_EVENT_FAIL_INVALID_INTEGER": "Impossible de récupérer les événements du serveur : nombre entier invalide.",
    "SERVER_EVENT_FAIL_INTEGER_LIMIT_EXCEEDED": "Impossible de récupérer les événements du serveur : le nombre d'événements doit être compris entre 1 et 50.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_START": "Impossible de récupérer l'historique du serveur: timestamp de début invalide (start).",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_END": "Impossible de récupérer l'historique du serveur : timestamp de fin invalide (end).",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_TIMER": "Impossible de récupérer l'historique du serveur : l'intervalle ne doit pas dépasser 12 heures.",

    // Network
    "NETWORK_NOT_FOUND": "Réseau introuvable.",
    "NETWORK_DELETE_FAIL_NOT_FOUND": "Impossible de supprimer le réseau : réseau introuvable.",
    "NETWORK_DELETE_FAIL_SERVER_ATTACHED": "Impossible de supprimer le réseau : un serveur est encore attaché.",
    "NETWORK_CREATE_FAIL_COUNT_EXCEEDED": "Impossible de créer le réseau : quota dépassé, veuillez contacter le support.",
    "NETWORK_CREATE_FAIL_INVALID_CIDR": "Impossible de créer le réseau : CIDR invalide.",
    "NETWORK_CREATE_FAIL_CIDR_CONFLICT": "Impossible de créer le réseau : le CIDR est en conflit avec un autre réseau managé.",
    "NETWORK_CREATE_FAIL_CIDR_TOO_LARGE": "Impossible de créer le réseau : le CIDR est trop grand, un réseau ne peut pas dépasser 65536 IPs (/16).",
    "NETWORK_CREATE_FAIL_MANAGED_SERVICES_DISABLED": "Impossible de créer le réseau managé : les services managés sont désactivés pour votre société.",
    "NETWORK_CREATE_FAIL_INVALID_NAME": "Impossible de créer le réseau : format du nom invalide.",
    "NETWORK_CREATE_FAIL_GATEWAY_NOT_FOUND": "Impossible de créer le réseau : passerelle introuvable.",
    "NETWORK_ATTACH_FAIL_SERVER_FULL": "Impossible d'attacher le serveur : tous les ports du serveur sont utilisés.",
    "NETWORK_ATTACH_FAIL_MAX_SERVER_EXCEEDED": "Impossible d'attacher le serveur : tous les ports sont utilisés.",
    "NETWORK_ATTACH_FAIL_NETWORK_DOWN": "Impossible d'attacher le serveur : le réseau est down.",
    "NETWORK_ATTACH_FAIL_SERVER_DISABLED": "Impossible d'attacher le serveur : le serveur est désactivé, veuillez contacter le support.",
    "NETWORK_ATTACH_FAIL_MANAGED_SERVICE_CONFLICT": "Impossible d'attacher le serveur : conflit de services managés détecté.",
    "NETWORK_ATTACH_FAIL_MULTIPLE_MANAGED_NETWORKS": "Impossible d'attacher le serveur : un serveur managé ne peut être attaché qu'à un seul réseau.",
    "NETWORK_ATTACH_FAIL_ALREADY_ATTACHED_TO_THIS_SERVER": "Le serveur est déjà attaché à ce réseau.",
    "NETWORK_DETACH_FAIL_SERVER_DISABLED": "Impossible de détacher le serveur : le serveur est désactivé, veuillez contacter le support.",
    "NETWORK_DETACH_FAIL_SERVER_NOT_ATTACHED": "Impossible de détacher le serveur : le serveur n'est pas attaché au réseau.",
    "NETWORK_DETACH_FAIL_MANAGED_SERVER": "Impossible de détacher le serveur : les serveurs managés ne peuvent pas être détaché manuellement d'un réseau.",
    "NETWORK_DETACH_FAIL_NETWORK_DOWN": "Impossible de détacher le serveur : le réseau est down.",
    "NETWORK_UPDATE_FAIL_INVALID_NAME": "Impossible de mettre à jour le réseau : format du nom invalide.",
    "NETWORK_SET_GATEWAY_FAIL_NOT_MANAGED": "Impossible de définir la passerelle : le réseau n'est pas managé.",
    "NETWORK_SET_GATEWAY_FAIL_IP_NOT_FOUND": "Impossible de définir la passerelle : l'addresse IP est introuvable.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_MANAGED": "Impossible de supprimer la passerelle : le réseau n'est pas managé.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_FOUND": "Impossible de supprimer la passerelle : passerelle introuvable.",
    "NETWORK_ADD_FW_RULE_FAIL_NOT_MANAGED": "Impossible d'ajouter la règle de pare-feu : le réseau n'est pas managé.",
    "NETWORK_ADD_FW_RULE_FAIL_INTERFACE_NOT_FOUND": "Impossible d'ajouter la règle de pare-feu : interface introuvable.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_SOURCE": "Impossible d'ajouter la règle de pare-feu : source invalide.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PORT": "Impossible d'ajouter la règle de pare-feu : port invalide.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PROTOCOL": "Impossible d'ajouter la règle de pare-feu : protocole invalide.",
    "NETWORK_REMOVE_FW_RULE_FAIL_NOT_FOUND": "Impossible de supprimer la règle de pare-feu : règle introuvable.",
    "NETWORK_ADD_FW_RULE_SUCCESS": "Règle de pare-feu ajoutée avec succès.",
    "NETWORK_REMOVE_FW_RULE_SUCCESS": "Règle de pare-feu supprimée avec succès.",
    "NETWORK_SET_GATEWAY_SUCCESS": "Passerelle définie avec succès.",
    "NETWORK_DELETE_GATEWAY_SUCCESS": "Passerelle supprimée avec succès.",
    "NETWORK_ATTACH_SERVER_SUCCESS": "Serveur attaché avec succès.",
    "NETWORK_DETACH_SERVER_SUCCESS": "Serveur détaché avec succès.",
    "NETWORK_DELETION_SUCCESS": "Suppression du réseau en cours.",
    "NETWORK_UPDATE_SUCCESS": "Réseau mis à jour avec succès.",

    // Metric
    "METRIC_EMPTY_NET_IN": "Données réseau entrantes non disponibles.",
    "METRIC_EMPTY_NET_OUT": "Données réseau sortantes non disponibles.",
    "METRIC_UNAVAILABLE": "Données non disponibles.",

    // Ticket
    "TICKET_NOT_FOUND": "Ticket introuvable.",
    "TICKET_CREATE_FAIL": "Impossible de créer le ticket : l'UUID du serveur est manquant.",
    "TICKET_CREATE_FAIL_INVALID_OBJECT": "Impossible de créer le ticket : objet invalide.",
    "TICKET_CREATE_FAIL_INVALID_CATEGORY": "Impossible de créer le ticket : catégorie invalide.",
    "TICKET_CREATE_FAIL_INVALID_MESSAGE": "Impossible de créer le ticket : message invalide.",
    "TICKET_CREATE_FAIL_INVALID_LEVEL": "Impossible de créer le ticket : niveau de priorité invalide.",
    "TICKET_CREATE_FAIL_UNKNOWN_SERVER": "Impossible de créer le ticket : serveur introuvable.",
    "TICKET_ADD_MESSAGE_INVALID_TEXT": "Impossible d'ajouter le message au ticket : format du message invalide.",
    "TICKET_ADD_MESSAGE_FAIL_MISSING_TICKET_UUID": "Impossible d'ajouter le message au ticket : UUID de ticket manquant.",
    "TICKET_ADD_MESSAGE_FAIL_TICKET_LOCKED": "Impossible d'ajouter le message au ticket : le ticket est verrouillé par un autre utilisateur.",
    "TICKET_DELETE_FAIL_MISSING_UUID": "Impossible de supprimer le ticket : UUID manquant.",
    "TICKET_GET_FAIL_MISSING_TICKET_UUID": "Impossible de récupérer le ticket : UUID manquant.",
    "TICKET_LOCK_FAIL_ALREADY_LOCKED": "Le ticket est déjà verrouillé.",
    "TICKET_UNLOCK_FAIL_ALREADY_UNLOCKED": "Le ticket n'est pas verrouillé",
    "TICKET_UNLOCK_FAIL_LOCKED_BY_OTHER_USER": "Impossible de déverrouiller le ticket : le ticket est vérrouillé par un autre utilisateur.",
    "TICKET_LOCK_SUCCESS": "Ticket verrouillé avec succès.",
    "TICKET_UNLOCK_SUCCESS": "Ticket déverrouillé avec succès.",
    "TICKET_CREATE_SUCCESS": "Ticket créé avec succès.",
    "TICKET_CLOSE_SUCCESS": "Ticket fermé avec succès.",
    "TICKET_DELETE_SUCCESS": "Ticket supprimé avec succès.",
    "TICKET_ADD_MESSAGE_SUCCESS": "Message ajouté au ticket avec succès.",
    "TICKET_DELETE_MESSAGE_SUCCESS": "Message de ticket supprimé avec succès.",
    "TICKET_UPDATE_LEVEL_SUCCESS": "Priorité du ticket mise à jour avec succès.",
    "TICKET_UPDATE_LEVEL_FAIL_CLOSED": "Impossible de mettre à jour la priorité du ticket : le ticket est fermé.",
    "TICKET_INVALID_MESSAGE": "La longueur du message du ticket doit être compris entre 3 et 5000 caractères.",
    "ERR_TICKET_ADMIN_CANNOT_CREATE_FOR_HIMSELF": "Vous ne pouvez pas créer un ticket pour vous-même.",
    "TICKET_CLOSED": "le ticket est fermé",
    "TICKET_CREATE_FAIL_OTHER": "Impossible de créer le ticket",
    "TICKET_REPLY_ALREADY_OPENED": "Une fenêtre de réponse est déjà ouverte",

    // Contact
    "CONTACT_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha invalide.",
    "CONTACT_REQUEST_FAIL_INVALID_EMAIL": "Format d'email invalide.",
    "CONTACT_REQUEST_SUCCESS": "Email envoyé avec succès.",

    // Job
    "JOB_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha invalide.",
    "JOB_REQUEST_FAIL_INVALID_EMAIL": "Format d'email invalide.",
    "JOB_REQUEST_SUCCESS": "Email envoyé avec succès.",

    // User
    "USER_2FA_ENABLE_FAIL_WRONG_CODE": "Code de validation incorect",
    "USER_UPDATE_INVALID_FIRSTNAME": "Impossible de mettre à jour l'utilisateur : prénom non valide.",
    "USER_LIST_FAIL": "Impossible de récupérer la liste des utilisateurs.",
    "USER_NOT_FOUND": "Utilisateur introuvable.",
    "USER_DELETE_FAIL_NOT_FOUND": "Impossible de supprimer l'utilisateur : utilisateur introuvable.",
    "USER_DELETE_SUCCESS": "Utilisateur supprimé avec succès.",
    "USER_RESET_PASSWORD_FAIL_INVALID_TOKEN": "Impossible de réinitialiser le mot de passe de l'utilisateur : token invalide.",
    "USER_RESET_PASSWORD_FAIL_INVALID_PASSWORD": "Impossible de réinitialiser le mot de passe de l'utilisateur : format du mot de passe invalide.",
    "USER_RESET_PASSWORD_SUCCESS": "Mot de passe réinitialisé avec succès.",
    "USER_RESET_PASSWORD_ASK_SUCCESS": "Si vous possédez un compte, un message contenant les instructions de réinitialisation sera envoyé vers votre addresse email.",
    "USER_UPDATE_INVALID_LASTNAME": "Nom invalide.",
    "USER_UPDATE_INVALID_PHONE_NUMBER": "Numéro invalide.",
    "USER_UPDATE_INVALID_MOBILE_NUMBER": "Numéro invalide.",
    "USER_UPDATE_SUCCESS": "Utilisateur mis à jour avec succès.",
    // API KEY
    "USER_ADD_API_KEY_SUCCESS": "Clé API créée avec succès",
    // SSH key
    "USER_DELETE_SSH_KEY_SUCCESS": "Clé SSH supprimée avec succès.",
    "USER_DELETE_SSH_KEY_FAIL_MISSING_NAME": "Le nom de la clé SSH est manquant.",
    "USER_DELETE_SSH_KEY_FAIL_NOT_FOUND": "Clé SSH introuvable.",
    "USER_ADD_SSH_KEY_FAIL_EMPTY": "La clé SSH est vide.",
    "USER_ADD_SSH_KEY_FAIL_KEY_INVALID": "La clé SSH est invalide.",
    "USER_ADD_SSH_KEY_SUCCESS": "Clé SSH ajoutée avec succès.",
    "USER_ADD_SSH_KEY_FAIL_ALREADY_NAME_EXIST": 'Le nom est déjà utilisé.',
    // Password
    "USER_UPDATE_PASSWORD_FAIL_WRONG_CURRENT_PASSWORD": "Le mot de passe actuel est incorrect.",
    "USER_UPDATE_PASSWORD_FAIL_NEW_PASSWORD_INVALID": "Le nouveau mot de passe est invalide.",
    "USER_UPDATE_PASSWORD_SUCCESS": "Mot de passe mis à jour avec succès.",
    // Registration
    "USER_CREATE_FAIL_DEFAULT_PREFERENCES": "Impossible de créer les préférences utilisateur.",
    "USER_CREATE_FAIL_COMPANY": "Impossible de créer la société de l'utilisateur.",
    "USER_UPDATE_FAIL_COMPANIES": "Impossible de rattacher l'utilisateur à la société.",
    "VALIDATE_REGISTRATION_FIRST": "Veuillez d'abord valider votre inscription.",
    "VALIDATE_USER_SUCCESS": "Votre inscription est validée.",

    // Company
    "COMPANY_DISABLED": "Votre compagnie est désactivée, veuillez contacter le support pour plus d'informations.",
    // Create
    "COMPANY_CREATE_FAIL_USER_ASSIGNMENT": "Impossible d'assigner l'utilisateur à la nouvelle compagnie créée.",
    "COMPANY_CREATE_FAIL_ALREADY_EXISTS": "Création impossible, la société existe déjà",
    // Delete
    "COMPANY_DELETE_FAIL": "Impossible de supprimer tous les utilisateurs de la société.",
    "COMPANY_DELETE_SUCCESS": "Société supprimée avec succès.",
    // (Re)Validation
    "VALIDATE_USER_FAIL_CREATE_CONTACT_ERROR": "Impossible de créer le nouveau contact.",
    "VALIDATE_USER_FAIL_UPDATE_COMPANY_ERROR": "Impossible d'assigner le numéro de comptable à la société.",
    "REVALIDATE_USER_FAIL_ALREADY_VALIDATED": "L'utilisateur est déjà validé.",
    "REVALIDATE_USER_FAIL_ASSIGN_NEW_COMPANY": "Impossible d'assigner la nouvelle société au nouvel utilisateur.",
    "REVALIDATE_USER_SUCCESS": "Email de validation envoyé avec succès.",
    // Update
    "INVALID_EMAIL": "L'adresse email est invalide",
    "COMPANY_CREATE_SUCCESS": "Société crée avec succès.",
    "COMPANY_UPDATE_SUCCESS": "Société mise à jour avec succès.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_TYPE": "Impossible de mettre à jour la société : mauvais type d'adresse.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_NAME": "Impossible de mettre à jour la société : nom de l'addresse invalide.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET": "Impossible de mettre à jour la société : adresse invalide.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET_2": "Impossible de mettre à jour la société : rue invalide.",
    "COMPANY_UPDATE_FAIL_INVALID_POSTAL_CODE": "Impossible de mettre à jour la société : code postal invalide.",
    "COMPANY_UPDATE_FAIL_INVALID_CITY": "Impossible de mettre à jour la société : ville invalide.",
    "COMPANY_UPDATE_FAIL_INVALID_COUNTRY": "Impossible de mettre à jour la société : pays invalide.",
    "COMPANY_UPDATE_FAIL_INVALID_PHONE": "Impossible de mettre à jour la société : numéro de téléphone invalide.",
    // Register user with company
    "REGISTER_COMPANY_FAIL_RECAPTCHA_ERROR": "Recaptcha invalide.",
    "REGISTER_COMPANY_FAIL_COMPANY_NAME_ALREADY_USED": "Impossible d'enregistrer le nouveau compte : le nom de société est déjà utilisé.",
    "REGISTER_COMPANY_FAIL_USER_EMAIL_ALREADY_USED": "Impossible d'enregistrer le nouveau compte : email déjà utilisé.",
    "REGISTER_COMPANY_FAIL_COMPANY_EMAIL_ALREADY_USED": "Impossible d'enregistrer le nouveau compte : email de société déjà utilisé.",
    "REGISTER_COMPANY_FAIL_ASSIGN_USER_ERROR": "Impossbile d'assigner la nouvelle affichage des facture société au nouvel utilisateur.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_EMAIL": "Impossible d'enregistrer le nouveau compte : adresse email de l'utilisateur invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_EMAIL": "Impossible d'enregistrer le nouveau compte : addresse email de la société invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_NAME": "Impossible d'enregistrer le nouveau compte : nom de société invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_CIVILITY": "Impossible d'enregistrer le nouveau compte : civilité invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_COUNTRY": "Impossible d'enregistrer le nouveau compte : pays invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET": "Impossible d'enregistrer le nouveau compte : rue invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET_2": "Impossible d'enregistrer le nouveau compte : complément de rue invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_POSTAL_CODE": "Impossible d'enregistrer le nouveau compte : code postal de la société invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_CITY": "Impossible d'enregistrer le nouveau compte : ville de la société invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_PREFERRED_LANGUAGE": "Impossible d'enregistrer le nouveau compte : language préféré invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_FIRST_NAME": "Impossible d'enregistrer le nouveau compte : prénom invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_LAST_NAME": "Impossible d'enregistrer le nouveau compte : nom de famille invalide",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PASSWORD": "Impossible d'enregistrer le nouveau compte : mot de passe invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PHONE": "Impossible d'enregistrer le nouveau compte : numéro de téléphone de l'utilisateur invalide",
    "REGISTER_COMPANY_FAIL_INVALID_USER_MOBILE": "Impossible d'enregistrer le nouveau compte : numéro de téléphone mobile invalide",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_PHONE": "Impossible d'enregistrer le nouveau compte : numéro de téléphone de la société invalide.",
    "REGISTER_COMPANY_FAIL_INVALID_AFFILIATE_CODE": "Impossible d'enregistrer le nouveau compte : code d'affiliation invalide.",
    "REGISTER_COMPANY_SUCCESS": "Nouvelle société enregistrée avec succès.",
    "REGISTER_COMPANY_FAIL_INVALID_VAT_NUMBER": "Numéro de TVA invalide.",
    "INVALID_VAT_NUMBER": "Numéro de TVA invalide.",
    "REGISTER_COMPANY_FAIL_CHECK_VAT_NUMBER": "Erreur lors du contrôle.",
    'VAT_NUMBER_EMPTY': "Veuillez saisir le N° de TVA.",
    // Register user to company
    "REGISTER_USER_FAIL_RECAPTCHA_ERROR": "Recaptcha invalide.",
    "REGISTER_USER_FAIL_INVALID_FIRSTNAME": "Impossible d'ajouter l'utilisateur à la société : prénom invalide.",
    "REGISTER_USER_FAIL_INVALID_LASTNAME": "Impossible d'ajouter l'utilisateur à la société : nom de famille invalide.",
    "REGISTER_USER_FAIL_INVALID_PASSWORD": "Impossible d'ajouter l'utilisateur à la société : mot de passe invalide.",
    "REGISTER_USER_FAIL_INVALID_PHONE": "Impossible d'ajouter l'utilisateur à la société : numéro de téléphone invalide.",
    "REGISTER_USER_FAIL_INVALID_MOBILE": "Impossible d'ajouter l'utilisateur à la société : numéro de mobile invalide.",
    "REGISTER_USER_FAIL_INVALID_CIVILITY": "Impossible d'ajouter l'utilisateur à la société : civilité invalide.",
    "REGISTER_USER_FAIL_INVALID_PREFERRED_LANGUAGE": "Impossible d'ajouter l'utilisateur à la société : language préféré invalide.",
    "REGISTER_USER_FAIL_INVALID_EMAIL": "Impossible d'ajouter l'utilisateur à la société : adresse email invalide.",
    "REGISTER_USER_FAIL_EMAIL_ALREADY_EXIST": "Impossible d'ajouter l'utilisateur à la société : l'adresse email saisie est déjà enregistrée.",
    "REGISTER_USER_FAIL_CGV_NOT_FOUND": "Impossible d'ajouter l'utilisateur à la société : CGV introuvables.",
    "REGISTER_USER_FAIL_COMPANY_NOT_FOUND_OR_INVALID_TOKEN": "Compagnie ou token d'invitation introuvable.",
    "REGISTER_USER_FAIL_EMAIL_REJECTED": "Impossible d'ajouter l'utilisateur à la société : adresse email rejetée.",
    "REGISTER_USER_FAIL_CLEAR_INVITATION": "Impossible de décliner l'invitation.",
    "REGISTER_USER_FAIL_INVALID_OR_EXPIRED_LINK": "Le lien d'enregistrement est invalide ou a expiré.",
    "REGISTER_USER_SUCCESS": "Nouvel utilisateur enregistré avec succès.",
    // Invite user to company
    "INVITE_USER_FAIL_INVALID_EMAIL": "Impossible d'inviter l'utilisateur : format d'email invalide.",
    "INVITE_USER_FAIL_BAD_PERMISSION": "Vous n'avez pas la permission d'inviter un nouvel utilisateur.",
    "INVITE_USER_FAIL_ALREADY_INVITED": "L'utilisateur a déjà un rôle dans la société.",
    "INVITE_USER_FAIL_ALREADY_ADMIN_OR_ACCOUNTANT": "L'utilisateur est déjà administrateur ou comptable d'une autre société.",
    "INVITE_USER_FAIL_INVALID_POSITION": "Impossible d'inviter l'utilisateur : position invalide.",
    // Join
    "JOIN_COMPANY_FAIL_INVALID_COMPANY_OR_TOKEN": "Société ou token d'invitation introuvable.",
    "JOIN_COMPANY_FAIL_EMAIL_REJECTED": "Votre adresse email a été rejetée.",
    "JOIN_COMPANY_FAIL_UNABLE_TO_UPDATE_USER": "Impossible d'assigner l'utilisateur a la société.",
    "JOIN_COMPANY_FAIL_INVITATION_NOT_FOUND": "Impossible de trouver l'invitation.",
    // Revoke
    "REVOKE_USER_FAIL_BAD_PERMISSION": "Seuls les administateurs peuvent révoquer des utilisateurs.",
    "REVOKE_USER_FAIL_SELF_REVOCATION": "Vous ne pouvez pas vous révoquer vous-même d'une société.",
    "REVOKE_USER_FAIL_EMAIL_NOT_FOUND": "Impossible de trouver l'adresse email de l'utilisateur à révoquer",
    "REVOKE_USER_FAIL_IS_ADMIN": "Impossible de révoquer le créateur de la société.",
    "REVOKE_USER_FAIL_WRONG_ROLE": "Impossible de trouver l'utilisateur avec le rôle indiqué.",
    "REVOKE_USER_FAIL_ACCOUNTING_ERROR": "Impossible de supprimer les informations comptable de l'utilisateur.",
    "REVOKE_USER_SUCCESS": "Utilisateur révoqué avec succès.",

    // Role
    "ROLE_NOT_FOUND": "Rôle introuvable.",
    "ROLE_CREATE_FAIL_BAD_PERMISSION": "Seuls les administrateurs d'une société peuvent créer de nouveaux rôles.",
    "ROLE_DELETE_FAIL_BAD_PERMISSION": "Seuls les administrateurs d'une société peuvent supprimer des rôles.",
    "ROLE_GET_FAIL_BAD_PERMISSION": "Seuls les administrateurs d'une société peuvent récupérer la liste des rôles.",
    "ROLE_UPDATE_FAIL_BAD_PERMISSION": "Seuls les administrateurs d'une société peuvent mettre à jour les rôles.",
    "ROLE_DELETE_FAIL_NOT_FOUND": "Impossible de supprimer le rôle : rôle non trouvé au sein de la société.",
    "ROLE_UPDATE_FAIL_NOT_FOUND": "Impossible de mettre à jour le rôle : rôle non trouvé au sein de la société.",
    "ROLE_UPDATE_FAIL_ALREADY_EXIST": "Impossible de mettre à jour le rôle : le nom du rôle existe déjà.",
    "ROLE_CREATE_FAIL_ALREADY_EXIST": "Impossible de créer le rôle : le nom du rôle existe déjà.",
    "ROLE_DELETE_FAIL_IN_USE": "Impossible de supprimer le rôle : le rôle est assigné à un ou plusieurs utilisateur(s).",
    "ROLE_CREATE_SUCCESS": "Rôle créé avec succès.",
    "ROLE_UPDATE_SUCCESS": "Rôle mis à jour avec succès.",
    "ROLE_DELETE_SUCCESS": "Rôle supprimé avec succès.",

    // CGV
    "CGV_NOT_FOUND": "Impossible de trouver les dernières CGV.",
    "CGV_VALIDATE_FAIL_UPDATE_ERROR": "Erreur lors de la signature des CVG pour l'utilisateur.",
    "CGV_VALIDATE_FAIL_INVALID_VERSION": "Vous n'avez pas signé la dernière version des CGV.",
    "CGV_VALIDATE_FAIL": "Veuillez noter que vous devez signer les CGV pour pouvoir utiliser nos services.",
    "CGV_VALIDATE_SUCCESS_ALREADY_SIGNED": "Vous avez déjà signé les dernières CGV.",
    "CGV_VALIDATE_SUCCESS": "Merci d'avoir signé nos CGV.",

    // Token
    "TOKEN_NOT_FOUND": "Token introuvable.",
    "TOKEN_CREATE_FAIL_UPDATE_USER": "Impossible de générer de token pour l'utilisateur actuel.",
    "TOKEN_CREATE_FAIL_INVALID_DATE": "Impossible de générer le token : date d'expiration invalide.",
    "TOKEN_CREATE_FAIL_NAME_ALREADY_USED": "Impossible de générer le token : le nom du token existe déjà.",
    "TOKEN_GET_FAIL_EMPTY_NAME": "Le nom du token est invalide.",
    "TOKEN_REVOKE_SUCCESS": "Token révoqué avec succès.",

    // Plan
    "PLAN_LIST_FAIL": "Impossible de récupérer la liste des plans.",

    // IPs
    "IP_NOT_FOUND": "IP non trouvée.",
    "IP_BLACKLISTED": "IP blacklistée.",
    "IP_DELETE_FAIL_ALREADY_ON_TERMINATION": "La suppression de cette adresse IP est déjà planifiée.",
    "IP_DELETE_FAIL_ACTION_NOT_SCHEDULED": "La suppression de cette adresse IP n'est pas planifiée.",
    "IP_DELETE_FAIL_IPV4_DELETION_SCHEDULED": "La suppression de l'adresse IP v4 correspondante est planifiée.",
    "IP_V6_UNAVAILABLE": "IPV6 non disponible.",
    "IP_V4_V6_COUPLE_NOT_FOUND": "IP_V4_V6_COUPLE_NOT_FOUND",
    // Attach/detach
    "DETACH_IP_SUCCESS": "IP détachée avec succès.",
    "ATTACH_IP_SUCCESS": "IP attachée avec succès.",
    "ATTACH_IP_FAIL_NO_MORE_AVAILABLE_IP": "Impossible d'attacher l'IP : plus d'adresse IP disponible.",
    "ATTACH_IP_FAIL_IP_IS_NOT_IN_YOUR_COMPANY_POOL": "Impossible d'attacher l'IP : l'addresse IP n'est pas dans le pool de la société.",
    "ATTACH_IP_FAIL_SERVER_MANAGED": "Impossible d'attacher l'IP : l'IP n'est pas compatible avec le(s) serveur(s), veuillez utiliser la règle PNAT.",
    "DETACH_IP_FAIL_BAD_SERVER_IP": "Impossible de détacher l'IP : l'adresse IP n'est pas attachée au serveur.",
    "DETACH_IP_FAIL_IP_NOT_FOUND": "Impossible de détacher l'IP : adresse IP introuvable.",
    "DETACH_IP_FAIL_SERVER_MANAGED": "Impossible de détacher l'IP : le détachement de l'IP n'est pas compatible avec les serveurs.",
    "DETACH_IP_FAIL_PRIMARY_ADDRESS": "Impossible de détacher l'IP : adresse IP primaire.",
    // PNAT rules add/del
    "ADD_IP_PNAT_RULE_SUCCESS": "Règle PNAT ajoutée avec succès.",
    "DEL_IP_PNAT_RULE_SUCCESS": "Règle PNAT supprimée avec succès.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "Impossible d'ajouter la règle PNAT : source du port invalide.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "Impossible d'ajouter la règle PNAT : destination du port invalide.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "Impossible d'ajouter la règle PNAT : protocole invalide.",
    "ADD_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Impossible d'ajouter la règle PNAT : le serveur n'est pas managé.",
    "ADD_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "Impossible d'ajouter la règle PNAT : adresse IP n'est pas trouvée.",
    "ADD_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "Impossible d'ajouter la règle PNAT : interface privée non trouvée.",
    "ADD_IP_PNAT_RULE_FAIL_TRANSPARENT_CONFLICT_PNAT": "Impossible d'ajouter la règle PNAT : vous devez retirer toutes les règles PNAT existantes de cette IP avant de pouvoir authoriser le mode transparent.",
    "ADD_IP_PNAT_RULE_FAIL_PNAT_CONFLICT_TRANSPARENT": "Impossible d'ajouter la règle PNAT : vous devez désactiver les redirections transparents de cette IP avant de pouvoir ajouter les règles PNAT.",
    "ADD_IP_PNAT_RULE_FAIL_SRC_PORT_CONFLICT": "Impossible d'ajouter la règle PNAT : une règle PNAT a déjà été définie pour cette IP.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "Impossible de supprimer la règle PNAT : port source invalide.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "Impossible de supprimer la règle PNAT : destination du port invalide.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "Impossible de supprimer la règle PNAT : protocol invalide.",
    "DEL_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Impossible de supprimer la règle PNAT : le serveur n'est pas managé.",
    "DEL_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "Impossible de supprimer la règle PNAT : adresse IP non trouvée.",
    "DEL_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "Impossible de supprimer la règle PNAT : interface privée non trouvée.",
    "DEL_IP_PNAT_RULE_FAIL_NOT_FOUND": "Impossible de supprimer la règle PNAT : règle PNAT non trouvée.",

    // Demo
    "DEMO_SEND_CREATE_SERVER_SUCCESS": "Requête de création du serveur en mode démo envoyée avec succès.",

    // Managed services
    "MANAGED_SERVICES_ENABLED": "Les services managés sont déjà activés pour cette société.",
    "MANAGED_SERVICES_SUCCESS": "Requête d'activation des services managés envoyée avec succès.",

    // Promotional code
    "PROMOTION_CODE_NOT_FOUND": "Code promotionnel invalide.",
    "PROMOTION_CODE_ALREADY_EXISTS": "Le code promotionnel existe déjà.",
    "PROMOTION_CODE_INVALID": "Code promotionnel invalide, la longueur doit être comprise entre 4 et 60 caractères.",

    // Items
    "ITEM_INVALID_QUANTITY": "Quantité d'article invalide.",
    "ITEM_NOT_FOUND": "Article non trouvé",

    // Generate affiliation Links
    "GENERATE_AFF_LINKS_SUCCESS": "Le lien d'affiliation a été généré avec succès. Il est enregistré dans votre presse papier.",
    "UPDATE_AFF_LINKS_SUCCESS": "Le lien d'affiliation a été mis à jours avec succès.",

    // Email validation
    "VALIDATE_EMAIL": "Un e-mail vous a été envoyé pour activer votre compte (merci de vérifier également dans vos spams).",
    "VALIDATE_EMAIL_SUCCESS": "Votre email a bien été validé.",
    "VALIDATE_EMAIL_SUCCESS_ALREADY_VALIDATED": "Votre email a déjà été validé.",
    "VALIDATE_EMAIL_INVITE_SUCCESS": "Vous avez bien accepté l'invitation.",
    "VALIDATE_EMAIL_FAIL": "Impossible de valider le compte utilisateur, veuillez contacter le support.",
    "VALIDATE_EMAIL_FAIL_TOKEN_INVALID": "Lien invalide, veuillez refaire une demande de validation de votre email.",
    
    // Parameters
    "PARAM_SINGLE_NOT_FOUND": "Paramètre de type SINGLE non trouvé.",
    "PARAM_ALREADY_EXISTS": "Le paramètre existe déjà",
    
    // Transfer
    "TRANSFER_SUCCESS": "Transfert réussi",
    "ERR_TRANSFER_NO_TYPE": "Type de transfert non indiqué",
    "ERR_TRANSFER_SAME_COMPANY": "Transfert impossible sur la même société.",
    "ERR_TRANSFER_DEMO_MODE_SERVER": "Transfert impossible, serveur en mode DEMO.",
    "ERR_TRANSFER_GETTING_SUBSCRIPTIONS": "Erreur lors de la lecture des abonnements",
    "ERR_TRANSFER_STATE_INVALID": "Transfert impossible, serveur supprimé ou statut invalide",
    "ERR_TRANSFER_SAME_SUBSCRIPTION": "Transfert de serveur impossible sur le même abonnement.",
    // Stripe
    "stripe": {
      "account_closed": "Compte bancaire fermé",
      "account_country_invalid_address": "Pays du compte bancaire invlide",
      "account_error_country_change_requires_additional_steps": "Changer de pays nécessite des étapes supplémentaires",
      "account_information_mismatch": "Certaines informations de compte ne correspondent pas les unes aux autres",
      "account_invalid": "L'ID de compte fourni comme valeur pour l'en-tête Stripe-Account n'est pas valide",
      "account_number_invalid": "Le numéro de compte bancaire fourni n'est pas valide",
      "acss_debit_session_incomplete": "La session de débit du SACR n'est pas encore prête",
      "alipay_upgrade_required": "Cette méthode de création de paiements Alipay n'est plus prise en charge",
      "amount_too_large": "Le montant spécifié est supérieur au montant maximum autorisé",
      "amount_too_small": "Le montant spécifié est inférieur au montant minimum autorisé",
      "api_key_expired": "La clé API fournie a expiré",
      "application_fees_not_allowed": "L'application de taxes n'est pas autorisée",
      "authentication_required": "Authentification requise",
      "balance_insufficient": "Solde insuffisant",
      "balance_invalid_parameter": "Paramètre fourni non valide pour la méthode solde",
      "bank_account_bad_routing_numbers": "Le compte bancaire ne prend pas en charge cette devise",
      "bank_account_declined": "Paiement non autorisé sur ce compte bancaire",
      "bank_account_exists": "Le compte bancaire existe déjà",
      "bank_account_restricted": "Le compte bancaire n'accepte pas ce moyen de paiement",
      "bank_account_unusable": "Compte bancaire non utilisable",
      "bank_account_unverified": "Compte bancaire non vérifié",
      "bank_account_verification_failed": "LA vérification du compte bancaire a échoué",
      "billing_invalid_mandate": "Mandat de paiement invalide",
      "bitcoin_upgrade_required": "Le paiement par Bitcoin n'est plus supporté",
      "capture_charge_authorization_expired": "Les fonds ne peuvent pas être retirés car l'autorisation a expiré",
      "capture_unauthorized_payment": "Vous n'êtes pas autorisé à retirer les fonds",
      "card_error": "Moyen de paiement non valide",
      "card_decline_rate_limit_exceeded": "Nombre de refus dépassé",
      "card_declined": "Carte refusée",
      "cardholder_phone_number_required": "Numéro de téléphone requis pour la création de cartes européennes",
      "charge_already_captured": "Fonds déjà retirés",
      "charge_already_refunded": "Fonds déjà remboursés",
      "charge_disputed": "Fonds déjà refacturés",
      "charge_exceeds_source_limit": "Dépassement de la limite sur la période",
      "charge_expired_for_capture": "Expiration du délai pour retirer les fonds",
      "charge_invalid_parameter": "Paramètre invalide pour retirer les fonds",
      "charge_not_refundable": "Fonds non remboursables",
      "clearing_code_unsupported": "Le code de compensation fourni n'est pas pris en charge",
      "country_code_invalid": "Code pays invalide",
      "country_unsupported": "Pays non pris en charge",
      "coupon_expired": "Coupon expiré",
      "customer_max_payment_methods": "Nombre maximum de moyens de paiement atteint",
      "customer_max_subscriptions": "Nombre maximum d'abonnements atteint",
      "debit_not_authorized": "Paiement non autorisé",
      "email_invalid": "Adresse email non valide",
      "expired_card": "Carte expirée",
      "insufficient_funds": "Fonds insuffisants",
      "intent_invalid_state": "Intention de paiement dans un état invalide",
      "invalid_card_type": "Type de carte non supportée",
      "invalid_charge_amount": "Montant de dépenses invalide",
      "payment_intent_mandate_invalid": "Mandat de paiment invalide",
      "payment_method_bank_account_blocked": "Compte bancaire bloqué",
      "payouts_limit_exceeded": "Dépassement de la limite journalières",
      "payouts_not_allowed": "Paiement non autorisé",
      "incorrect_number": "Numéro de carte incorrect",
      "generic_decline": "Carte refusée",
    },
    // Scheduled Actions
    "ERR_SCHEDULED_ACTION_INVALID_DATE": "La date programmée est invalide.",
    "ERR_SCHEDULED_ACTION_ALREADY_EXISTS": "Cette action est déjà planifiée.",
    // Disputes
    "ERR_PAYMENT_NOT_BELONGS_TO_COMPANY": "Ce moyen de paiement ne provient pas de cette société",
    "ERR_DISPUTE_NOT_FOUND": "Litige non trouvé",
    "ERR_DISPUTE_PAID": "Litige déjà réglé",
    "ERR_PAYMENT_BY_CARD_REQUIRED": "Paiement par CB obligatoire",
    "ERR_NO_INVOICES": "Aucune facuture à payer",
    "ERR_INCORRECT_NUMBER_OF_REFERENCES": "Nombre de références incorrect",
    "ERR_SOME_INVOICE_ARE_IN_DISPUTE": "Au moins une facture est en litige.",
  },
  "tools": {
    "coming_soon": "Fonctionnalité à venir.",
    "bad_level": "Niveau de service insuffisant",
  },
}
